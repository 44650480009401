import React, { Fragment, useState } from "react";
import {
  Button,
  Carousel,
  Checkbox,
  Input,
  Modal,
  Popconfirm,
  Spin,
} from "antd";
import {
  BsArchiveFill,
  BsPlusCircleFill,
  BsFillBookmarkStarFill,
  BsFillBookmarkDashFill,
  BsFillTrashFill,
  BsFillChatDotsFill,
} from "react-icons/bs";
import { MdOutlineDragIndicator } from "react-icons/md";
import useUpdateTags from "../Feed new/useUpdateTags";
import useUpdatePostStatus from "./useUpdatePostStatus";
import {
  changeAdSequence,
  getAdsFeedLength,
  orderAdSequence,
  reduceAdSequence,
  removePostasAd,
  removePostasFeature,
  updateShopLink,
} from "../../../Utils/SupabaseServices/ContentDatabase";
import TagsBox from "../../../components/TagsBox/TagsBox";
import useDeletePosts from "./useDeletePosts";
import "./feedTab.css";
import {
  fetchComments,
  updateCommentsData,
} from "../../../Utils/SupabaseServices/CommentsDatabase";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useUpdatePostAdIndex from "./useUpdatePostIndex";
import { TiPin } from "react-icons/ti";
import { FaLock } from "react-icons/fa";
import { supabase } from "../../../config/supabaseClient";
import { ContentSkeleton } from "../../../components/skeleton/DashboardView/feedSkeleton";
import FeedContentPost from "../Feed new/SubComponents/FeedContentPost";
import { StaticCompnents } from "../../../Utils/StaticComponent";

const BrokenLinkContent = ({
  username,
  activeKey,
  data,
  user_id,
  filter,
  setLimitError,
  refetchData,
  setIsContentLoading,
  subscription_type,
  brokenDataLoading,
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [checkedList, setCheckedList] = useState<any>([]);
  const [selectedPostId, setSelectedPostId] = useState<any>();

  const handleCancel = () => {
    setSelectedPostId("");
    setIsModalOpen(false);
  };
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const { mutate: updateTags } = useUpdateTags();
  const { mutate: updatePostAdIndex } = useUpdatePostAdIndex();
  const { mutate: updatePostStatus } = useUpdatePostStatus();

  const { mutate: deletePostPermanently } = useDeletePosts();

  function removeTag(
    tagArr: any,
    tag: any,
    post_id: any,
    pageIndex: number,
    index: number
  ) {
    const idx = tagArr.indexOf(tag);
    tagArr.splice(idx, 1);

    updateTags({
      activeKey,
      tagString: tagArr.toString(),
      post_id,
      pageIndex,
      index,
      filter,
      username,
      tag,
      action: "remove",
    });
  }
  function submitTag(
    e: any,
    tagArray: any,
    tag: any,
    post_id: any,
    pageIndex: number,
    index: number
  ) {
    e.preventDefault();
    if (e.keyCode === 13) {
      let tagsToProcess = tag.split(",");
      const modifiedArray = tagsToProcess.map(
        (word: any) => `|${word.trim()}|`
      );

      const resultString = modifiedArray.toString();
      let arrString = tagArray
        ? [`${resultString}`, ...tagArray.split(",")].toString()
        : `${resultString}`;

      updateTags({
        activeKey,
        tagString: arrString,
        post_id,
        pageIndex,
        index,
        filter,
        username,
        tag,
        action: "submit",
      });
      // });

      e.target.value = "";
    }
  }

  function deletePost(post_id: string, pageIndex: number, index: number) {
    deletePostPermanently({
      postId: post_id,
      activeKey,
      pageIndex,
      postIndex: index,
      filter,
    });
  }

  const handleOk = async () => {
    if (checkedList && checkedList.length) {
      checkedList.map(async (list: any) => {
        await updateCommentsData(list);
      });
    }
    setSelectedPostId("");
    setIsModalOpen(false);
  };
  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    const newData = [...data?.pages?.[0]];
    let sourceIndex = result.source.index;
    let post_id = result.draggableId;
    let targetIndex = result.destination.index;

    const [removed] = newData.splice(result.source.index, 1);

    newData.splice(result.destination.index, 0, removed);
    data.pages[0] = [...newData];

    updatePostAdIndex({
      user_id,
      sourceIndex,
      targetIndex,
      activeKey,
      post_id,
      username,
      newData,
    });
  };

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [postIdToUpdateLink, setPostIdToUpdateLink] = useState(null); // State to store the post ID for link update
  const [newLink, setNewLink] = useState(""); // State to store the new link value

  const showModalToUpdateLink = (postId: any) => {
    setPostIdToUpdateLink(postId);
    setIsUpdateModalOpen(true);
  };

  const handleUpdateCancel = () => {
    setPostIdToUpdateLink(null);
    setIsUpdateModalOpen(false);
    setNewLink(""); // Reset new link value when modal is closed
  };

  const updateLink = async () => {
    // Logic to update link here
    // You can use the postIdToUpdateLink and newLink states to perform the update
    // After updating the link, you can close the modal
    await updateShopLink(user_id, username, postIdToUpdateLink, newLink).then(
      () => {
        refetchData();
      }
    );
    setNewLink("");
    setIsUpdateModalOpen(false);
  };
  function archivePost(post_id: string, pageIndex: number, postIndex: number) {
    updatePostStatus({
      newPostStatus: "archived",
      postId: post_id,
      activeKey,
      pageIndex,
      postIndex,
      filter,
      username,
    });
  }
  async function activePost(
    post_id: string,
    pageIndex: number,
    postIndex: number
  ) {
    await supabase
      .from(`${username}`)
      .update({ shoplink_broken: false })
      .eq("post_id", post_id)
      .eq("user_id", user_id);

    updatePostStatus({
      newPostStatus: "active",
      postId: post_id,
      activeKey,
      pageIndex,
      postIndex,
      filter,
      username,
    });
    refetchData();
  }

  const ActionButtons = ({
    postId,
    pageIndex,
    index,
    postIndex,
    platform,
    shop_link,
  }: any) => {
    function testLink() {
      if (shop_link && shop_link !== "") {
        window.open(shop_link, "_blank");
      }
    }
  
    return (
      <>
        {activeKey === "broken_links" ? (
          <>
            <p style={{ wordWrap: "break-word", fontSize: "0.8rem" }}>
              {shop_link}
            </p>
            <Button onClick={testLink} disabled={!shop_link}>
              Test Link
            </Button>
            <Button onClick={() => showModalToUpdateLink(postId)}>
              Update Link
            </Button>{" "}
          </>
        ) : (
          ""
        )}
        {activeKey === "active" ? (
          <Button
            type="default"
            icon={
              <BsArchiveFill
                style={{ fontSize: "16px", margin: "0 7px 1px 0" }}
              />
            }
            onClick={() => {
              archivePost(postId, pageIndex, index);
            }}
          >
            Archive
          </Button>
        ) : activeKey === "archived" || activeKey === "broken_links" ? (
          <Button
            type="default"
            icon={
              <BsPlusCircleFill
                style={{ fontSize: "16px", margin: "0 7px 1px 0" }}
              />
            }
            onClick={() => {
              activePost(postId, pageIndex, index);
            }}
          >
            Active
          </Button>
        ) : (
          ""
        )}

        {activeKey !== "active,ad" && activeKey !== "active,featured" ? (
          <Popconfirm
            title="Are you sure you want to permanently delete this post?"
            onConfirm={() => deletePost(postId, pageIndex, index)}
            okText="Yes"
            cancelText="No"
            overlayStyle={{ maxWidth: "240px" }}
          >
            <Button
              type="primary"
              danger
              icon={<BsFillTrashFill className="iconSize" />}
            >
              Delete Post
            </Button>
          </Popconfirm>
        ) : (
          ""
        )}
      </>
    );
  };
  const platformIcons: any = {
    ShopMy: StaticCompnents().ShopMyIcon,
    Amazon: StaticCompnents().AmazonIcon,
    LTK: StaticCompnents().LTKIcon,
    instagram: StaticCompnents().InstagramIcon,
    pinterest:StaticCompnents().PinterestIcon,
    twitter:StaticCompnents().TwitterIcon,
    tiktok:StaticCompnents().TiktokIcon,
    newsletter:StaticCompnents().NewsletterIcon,
    podcast:StaticCompnents().PodcastIcon,
    // Add other platforms here
  };
  const renderPlatformIcon = (platform: any) => {
    const IconComponent = platformIcons[platform];

    if (IconComponent) {
      return <IconComponent />;
    }
    return null; // Return null if platform doesn't exist or is invalid
  };
  return (
    <>
      {brokenDataLoading ? (
        <ContentSkeleton />
      ) : (
        <DragDropContext
          onDragEnd={(result: any) => {
            onDragEnd(result);
          }}
        >
          <Droppable droppableId="ad">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}

                // Additional styling if needed
              >
                {data?.pages && data?.pages[0].length ? (
                  <div className="feed-tab-posts-table">
                    {data?.pages.map((group: any, pageIndex: number) => (
                      <Fragment key={pageIndex}>
                        {group.map((image: any, index: number) => (
                          <Draggable
                            key={image.post_id}
                            draggableId={image.post_id}
                            index={index}
                            isDragDisabled={
                              activeKey === "active,ad" ? false : true
                            }
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`feed-tab-post-row ${
                                  activeKey === "active,ad"
                                    ? "feed-tab-post-row-grab"
                                    : ""
                                } ${
                                  draggedIndex === index
                                    ? "feed-tab-post-row-dragging"
                                    : ""
                                }`}
                              >
                                {activeKey === "active,ad" && (
                                  <div className="feed-tab-drag-btn">
                                    <MdOutlineDragIndicator />
                                  </div>
                                )}

                                <div className="feed-tab-row-media">
                                  <div
                                    className="feed-tab-row-media-wrapper"
                                    style={
                                      image.platform === "newsletter"
                                        ? {
                                            backgroundColor: "#efeded",
                                            textAlign: "center",
                                          }
                                        : {}
                                    }
                                  >
                                    {image.platform !== "newsletter" ? (
                                      <>
                                        {image.media_url ? (
                                          <>
                                            {image.media_url.split(",").length >
                                              1 &&
                                            image.media_url
                                              .split(",")
                                              .every(
                                                (url: any) => url.trim() !== ""
                                              ) ? (
                                              <Carousel
                                                dotPosition="bottom"
                                                className="feed-tab-media-carousel"
                                              >
                                                {image.media_url
                                                  .split(",")
                                                  .map(
                                                    (
                                                      media_url: any,
                                                      index: any
                                                    ) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        {media_url.includes(
                                                          "video"
                                                        ) ? (
                                                          <video
                                                            style={{
                                                              maxHeight:
                                                                "350px",
                                                            }}
                                                            className="feed-tab-media"
                                                            autoPlay
                                                            playsInline
                                                            muted
                                                            loop
                                                            poster={
                                                              image.thumbnail &&
                                                              image.thumbnail.split(
                                                                ","
                                                              ).length &&
                                                              image.thumbnail.split(
                                                                ","
                                                              )[index]
                                                                ? image.thumbnail.split(
                                                                    ","
                                                                  )[index]
                                                                : "/assets/dummy_video.png"
                                                            }
                                                          >
                                                            <source
                                                              src={media_url}
                                                            />
                                                          </video>
                                                        ) : (
                                                          <img
                                                            style={
                                                              {
                                                                maxHeight: "350px",
                                                              }
                                                            }
                                                            className="feed-tab-media"
                                                            src={media_url}
                                                            alt="post"
                                                          />
                                                        )}
                                                      </React.Fragment>
                                                    )
                                                  )}
                                              </Carousel>
                                            ) : (
                                              <>
                                                {image.media_url.includes(
                                                  "video"
                                                ) ? (
                                                  <video
                                                    style={{
                                                      maxHeight: "350px",
                                                    }}
                                                    className="feed-tab-media"
                                                    autoPlay
                                                    playsInline
                                                    muted
                                                    loop
                                                    poster={
                                                      image.thumbnail
                                                        ? image.thumbnail
                                                        : "/assets/dummy_video.png"
                                                    }
                                                  >
                                                    <source
                                                      src={image.media_url}
                                                    />
                                                  </video>
                                                ) : (
                                                  <img
                                                    style={
                                                      {
                                                        maxHeight: "350px",
                                                      }
                                                    }
                                                    className="feed-tab-media"
                                                    src={image.media_url}
                                                    alt="post"
                                                  />
                                                )}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {image.media_url.includes(
                                              "video"
                                            ) ? (
                                              <video
                                                style={{
                                                  maxHeight: "350px",
                                                }}
                                                className="feed-tab-media"
                                                autoPlay
                                                playsInline
                                                muted
                                                loop
                                                poster={
                                                  image.thumbnail
                                                    ? image.thumbnail
                                                    : "/assets/dummy_video.png"
                                                }
                                              >
                                                <source src={image.media_url} />
                                              </video>
                                            ) : (
                                              <img
                                                style={
                                                  {
                                                    maxHeight: "350px",
                                                  }
                                                }
                                                className="feed-tab-media"
                                                src={image.media_url}
                                                alt="post"
                                              />
                                            )}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <div>
                                        <h4 style={{ marginTop: "7.5%" }}>
                                          {new Intl.DateTimeFormat("en-US", {
                                            year: "numeric",
                                            day: "numeric",
                                            month: "long",
                                          }).format(new Date(image.post_date))}
                                        </h4>
                                        <h4> Newsletter</h4>
                                      </div>
                                    )}
                                    <p className="creator-feed-content">
                                      {new Date(image.post_date).getFullYear() >
                                        2000 && (
                                        <span className="creator-feed-content-date">
                                          {new Intl.DateTimeFormat("en-US", {
                                            year: "numeric",
                                            day: "numeric",
                                            month: "long",
                                          }).format(new Date(image.post_date))}
                                        </span>
                                      )}
                                      {image.platform && (
                                        <span className="creator-feed-content-icon">
                                          {renderPlatformIcon(image.platform)}
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                  <div className="feed-tab-row-actions-mobile">
                                    <ActionButtons
                                      postIndex={image.index}
                                      postId={image.post_id}
                                      pageIndex={pageIndex}
                                      index={index}
                                      shop_link={image.shop_link}
                                    />
                                  </div>
                                </div>

                                {/* <FeedContentPost image={image}/> */}

                                <div className="feed-tab-row-tags">
                                  <div className="search-container-tagInput">
                                    <input
                                      type="text"
                                      id="tagInput"
                                      enterKeyHint="enter"
                                      placeholder="Enter tags here"
                                      className="tagInput"
                                      onKeyUp={(e: any) => {
                                        submitTag(
                                          e,
                                          image.tagg,
                                          e.target.value,
                                          image.post_id,
                                          1,
                                          index
                                        );
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: "10px",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    {image.tagg ? (
                                      <TagsBox
                                        tagsArray={
                                          image.tagg
                                            ? image.tagg.split(",")
                                            : [""]
                                        }
                                        removeTag={(tag: string) => {
                                          removeTag(
                                            image.tagg
                                              ? image.tagg.split(",")
                                              : [""],
                                            tag,
                                            image.post_id,
                                            1,
                                            index
                                          );
                                        }}
                                        page="content"
                                      />
                                    ) : (
                                      <p className="noTags">No Tags</p>
                                    )}
                                  </div>
                                </div>

                                <div className="feed-tab-actions">
                                  <ActionButtons
                                    postIndex={image.index}
                                    postId={image.post_id}
                                    pageIndex={pageIndex}
                                    index={index}
                                    platform={image.platform}
                                    shop_link={image.shop_link}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </Fragment>
                    ))}
                  </div>
                ) : (
                  <p className="">
                    {filter && filter !== "" ? (
                      <>
                        {activeKey === "archived" ? (
                          <i>
                            No archived posts found matching your search for{" "}
                            <b>{filter}</b>.
                          </i>
                        ) : activeKey === "active,ad" ? (
                          <i>
                            No ads found matching your search for{" "}
                            <b>{filter}</b>.
                          </i>
                        ) : activeKey === "active,featured" ? (
                          <i>
                            No featured posts found matching your search for{" "}
                            <b>{filter}</b>.
                          </i>
                        ) : activeKey === "broken_links" ? (
                          <i>
                            No broken links found matching your search for{" "}
                            <b>{filter}</b>.
                          </i>
                        ) : (
                          <i>No search results found.</i>
                        )}
                      </>
                    ) : (
                      <>
                        {activeKey === "archived" ? (
                          <i>No posts have been archived yet.</i>
                        ) : activeKey === "active,ad" ? (
                          <i>No posts have been marked as an ad yet.</i>
                        ) : activeKey === "active,featured" ? (
                          <i>No posts have been marked as featured yet.</i>
                        ) : activeKey === "broken_links" ? (
                          <i>No broken links 🥳</i>
                        ) : (
                          <i>
                            Your data has not been processed yet. Come back
                            soon!
                          </i>
                        )}
                      </>
                    )}
                  </p>
                )}
                {/* Your Modal component */}
                <Modal
                  title="Show Relevant Comments"
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  bodyStyle={{
                    overflowY: "auto",
                    maxHeight: "calc(100vh - 300px)",
                  }}
                  footer={[
                    <Button key="back" onClick={handleCancel}>
                      Cancel
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      // loading={loading}
                      onClick={handleOk}
                    >
                      Save
                    </Button>,
                  ]}
                >
                  {/* Your Modal content */}
                </Modal>
                <Modal
                  title="Update Link"
                  open={isUpdateModalOpen}
                  onOk={updateLink}
                  onCancel={handleUpdateCancel}
                >
                  <Input
                    type="text"
                    value={newLink}
                    onChange={(e: any) => setNewLink(e.target.value)}
                    placeholder="Enter new link"
                  />
                </Modal>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default BrokenLinkContent;
