import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import useGraphicalAnalytics from "../Overview/useGraphicalAnalytics";
import { Spin } from "antd";

const Charts = ({
  user_id,
  startDate,
  endDate,
  isYesterday,
  daysNumber,
}: any) => {
  const { loadingPageAnalytics, pageAnalytics } = useGraphicalAnalytics(
    user_id,
    startDate,
    endDate,
    isYesterday,
    daysNumber
  );
  const renderCustomTooltip = (props: any) => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
        >
          <p style={{ margin: "2%", padding: "3px" }}>{data.formatted_date}</p>
          <p style={{ color: "#8685EF", padding: "3px" }}>
            Users: {data.users}
          </p>
          <p style={{ color: "#404040", padding: "3px" }}>
            Searches: {data.searches}
          </p>
          <p style={{ color: "#00C6CF", padding: "3px" }}>
            Shoppable link clicks: {data["links"]}
          </p>
        </div>
      );
    }

    return null;
  };

  const CustomLegend = () => (
    <div className="clicks">
        <div style={{ color: "#404040" }}>
          <ul>
            <li> Searches</li>
          </ul>
        </div>
        <div style={{ color: "#8685EF" }}>
          <ul>
            <li> Users</li>
          </ul>
        </div>
      <div>
        <div style={{ color: "#00C6CF" }}>
          <ul>
            <li>Shoppable links clicks</li>
          </ul>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <h5>Page analytics</h5>
      <div
className="loadingPageAnalytics"
      >
        <div style={{ width: "100%", height: "100%" }}>
          {loadingPageAnalytics ? (
            <div
              className="spin"
            >
              <Spin />
            </div>
          ) : (
            <ResponsiveContainer width="100%" height={550}>
              <LineChart
                data={pageAnalytics}
                margin={{
                  top: 15,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="formatted_date" stroke="grey" width={75} />
                <YAxis stroke="grey" />
                <Tooltip content={renderCustomTooltip} />
                <Legend content={CustomLegend} />
                <Line
                  type="monotone"
                  dataKey="users"
                  stroke="#8685EF"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="searches" stroke="#404040" />
                <Line type="monotone" dataKey="links" stroke="#00C6CF" />
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </>
  );
};

export default Charts;
