import React, { Fragment, useState } from "react";
import {
  Button,
  Carousel,
  Checkbox,
  Input,
  Modal,
  Popconfirm,
  Spin,
} from "antd";

import { MdOutlineDragIndicator, MdOutlineFileDownload } from "react-icons/md";

import {
  changeAdSequence,
  getAdsFeedLength,
  orderAdSequence,
  reduceAdSequence,
  removePostasAd,
  removePostasFeature,
} from "../../../Utils/SupabaseServices/ContentDatabase";
import TagsBox from "../../../components/TagsBox/TagsBox";
import "../Feed/feedTab.css";
import {
  fetchComments,
  updateCommentsData,
} from "../../../Utils/SupabaseServices/CommentsDatabase";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { downloadContent } from "../../../Utils/SupabaseServices/StorageBuckets";
import useUpdateTags from "./useUpdateTags";
import useUpdatePostStatus from "../Feed/useUpdatePostStatus";
import useUpdatePostAdIndex from "../Feed/useUpdatePostIndex";
import useDeletePosts from "../Feed/useDeletePosts";
import ManageLinksModal from "./ManageLinksModal";
import ManageCaptionModal from "./ManageCaptionModal";
import { StaticCompnents } from "../../../Utils/StaticComponent";
import { FaAmazon } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import FeedActionButtons from "./SubComponents/FeedActionButtons";
import FeedContentPost from "./SubComponents/FeedContentPost";
const ContentSearchTable = ({
  username,
  activeKey,
  data,
  user_id,
  filter,
  setLimitError,
  refetchData,
  setIsContentLoading,
  subscription_type,
  brokenLinksContent,
  isLoading,
  loadingRef,
  totalSize,
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [commentsData, setCommentsData] = useState<any>([]);
  const [noCommentsMsg, setNoCommentsMsg] = useState("");
  const [loadingComments, setLoadingComments] = useState(true);
  const [checkedList, setCheckedList] = useState<any>([]);
  const [selectedPostId, setSelectedPostId] = useState<any>();
  const showModal = async (
    post_id: string,
    pageIndex: number,
    postIndex: number
  ) => {
    setLoadingComments(true);
    setSelectedPostId(post_id);

    setIsModalOpen(true);
    const comments = await fetchComments(post_id, user_id);
    if (comments) {
      setCommentsData(comments);
      setNoCommentsMsg("");
      setLoadingComments(false);
    } else {
      setCommentsData([]);
      setNoCommentsMsg("There are no Comments for this post");
      setLoadingComments(false);
    }
  };
  const handleCancel = () => {
    setSelectedPostId("");
    setIsModalOpen(false);
  };
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const { mutate: updateTags } = useUpdateTags();
  const { mutate: updatePostStatus } = useUpdatePostStatus();
  const { mutate: updatePostAdIndex } = useUpdatePostAdIndex();

  const { mutate: deletePostPermanently } = useDeletePosts();

  function removeTag(
    tagArr: any,
    tag: any,
    post_id: any,
    pageIndex: number,
    index: number
  ) {
    const idx = tagArr.indexOf(tag);
    tagArr.splice(idx, 1);

    updateTags({
      activeKey,
      tagString: tagArr.toString(),
      post_id,
      pageIndex,
      index,
      filter,
      username,
      tag,
      action: "remove",
    });
  }
  function submitTag(
    e: any,
    tagArray: any,
    tag: any,
    post_id: any,
    pageIndex: number,
    index: number
  ) {
    e.preventDefault();
    if (e.keyCode === 13) {
      let tagsToProcess = tag.split(",");
      const modifiedArray = tagsToProcess.map(
        (word: any) => `|${word.trim()}|`
      );

      const resultString = modifiedArray.toString();
      let arrString = tagArray
        ? [`${resultString}`, ...tagArray.split(",")].toString()
        : `${resultString}`;

      updateTags({
        activeKey,
        tagString: arrString,
        post_id,
        pageIndex,
        index,
        filter,
        username,
        tag,
        action: "submit",
      });
      // });

      e.target.value = "";
    }
  }

  function adPost(post_id: string, pageIndex: number, postIndex: number) {
    getAdsFeedLength(user_id, username).then((adCount: any) => {
      if (adCount + 1 <= 2) {
        updatePostStatus({
          newPostStatus: "active,ad",
          postId: post_id,
          activeKey,
          pageIndex,
          postIndex,
          filter,
          username,
        });
        changeAdSequence(post_id, adCount, username, user_id);
        setLimitError(false);
      } else {
        setLimitError(true);
      }
    });
  }
  function featurePost(post_id: string, pageIndex: number, postIndex: number) {
    updatePostStatus({
      newPostStatus: "active,featured",
      postId: post_id,
      activeKey,
      pageIndex,
      postIndex,
      filter,
      username,
    });
  }

  async function removeFromAds(post_id: any) {
    removePostasAd(post_id, username, user_id).then(() => {
      refetchData();
      getAdsFeedLength(user_id, username).then((adCount: any) => {
        reduceAdSequence(user_id, username);
        if (adCount + 1 <= 2) {
          setLimitError(false);
        }
      });
    });
  }
  async function removeFromFeatured(post_id: any) {
    removePostasFeature(post_id, username, user_id).then(() => {
      refetchData();
    });
  }

  function deletePost(post_id: string, pageIndex: number, index: number) {
    deletePostPermanently({
      postId: post_id,
      activeKey,
      pageIndex,
      postIndex: index,
      filter,
    });
  }
  const handleCheckboxChange = (e: any, item: any) => {
    const checked = e.target.checked;
    item.value = checked;
    //
    const updatedList = [...checkedList, item];

    setCheckedList(commentsData);
  };
  const handleOk = async () => {
    if (checkedList && checkedList.length) {
      checkedList.map(async (list: any) => {
        await updateCommentsData(list);
      });
    }
    setSelectedPostId("");
    setIsModalOpen(false);
  };
  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    const newData = [...data?.pages?.[0]];
    let sourceIndex = result.source.index;
    let post_id = result.draggableId;
    let targetIndex = result.destination.index;

    const [removed] = newData.splice(result.source.index, 1);

    newData.splice(result.destination.index, 0, removed);
    data.pages[0] = [...newData];

    updatePostAdIndex({
      user_id,
      sourceIndex,
      targetIndex,
      activeKey,
      post_id,
      username,
      newData,
    });
  };

  async function downloadPost(
    post_id: any,
    platform: any,
    type: any,
    url: any
  ) {
    await downloadContent(post_id, type, url);
  }

  const [linksModal, setLinksModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState<any>();
  const [captionModal, setCaptionModal] = useState(false);

  async function handleManageLinks(post: any) {
    setSelectedPost(post);

    setLinksModal(true);
  }
  async function handleManageCaption(post: any) {
    setSelectedPost(post);
    setCaptionModal(true);
  }

  
  return (
    <>
      <DragDropContext
        onDragEnd={(result: any) => {
          onDragEnd(result);
        }}
      >
        <Droppable droppableId="ad">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <div className="feed-tab-posts-table">
                {loadingRef === false ? (
                  <>
                    {data && data.length >= 0 ? (
                      <Fragment key={1}>
                        {data.map((image: any, index: number) => (
                          <Draggable
                            key={image.post_id}
                            draggableId={image.post_id}
                            index={index}
                            isDragDisabled={
                              activeKey === "active,ad" ? false : true
                            }
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`feed-tab-post-row ${
                                  activeKey === "active,ad"
                                    ? "feed-tab-post-row-grab"
                                    : ""
                                } ${
                                  draggedIndex === index
                                    ? "feed-tab-post-row-dragging"
                                    : ""
                                }`}
                              >
                                {activeKey === "active,ad" && (
                                  <div className="feed-tab-drag-btn">
                                    <MdOutlineDragIndicator />
                                  </div>
                                )}

                                <div className="feed-tab-row-media">
                                  <FeedContentPost image={image}/>
                                  {/* <div
                                    className="feed-tab-row-media-wrapper"
                                    style={
                                      image.platform === "newsletter"
                                        ? {
                                            backgroundColor: "#efeded",
                                            textAlign: "center",
                                          }
                                        : {}
                                    }
                                  >
                                    {image.platform !== "newsletter" ? (
                                      <>
                                        {image.media_url ? (
                                          <>
                                            {image.media_url.split(",").length >
                                              1 &&
                                            image.media_url
                                              .split(",")
                                              .every(
                                                (url: any) => url.trim() !== ""
                                              ) ? (
                                              <Carousel
                                                dotPosition="bottom"
                                                className="feed-tab-media-carousel"
                                              >
                                                {image.media_url
                                                  .split(",")
                                                  .map(
                                                    (
                                                      media_url: any,
                                                      index: any
                                                    ) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        {media_url.includes(
                                                          "video"
                                                        ) ? (
                                                          <video
                                                            style={{
                                                              maxHeight:
                                                                "350px",
                                                            }}
                                                            className="feed-tab-media"
                                                            autoPlay
                                                            playsInline
                                                            muted
                                                            loop
                                                            poster={
                                                              image.thumbnail &&
                                                              image.thumbnail.split(
                                                                ","
                                                              ).length &&
                                                              image.thumbnail.split(
                                                                ","
                                                              )[index]
                                                                ? image.thumbnail.split(
                                                                    ","
                                                                  )[index]
                                                                : "/assets/dummy_video.png"
                                                            }
                                                          >
                                                            <source
                                                              src={media_url}
                                                            />
                                                          </video>
                                                        ) : (
                                                          <img
                                                            style={{
                                                              maxHeight:
                                                                "350px",
                                                            }}
                                                            className="feed-tab-media"
                                                            src={media_url}
                                                            alt="post"
                                                          />
                                                        )}
                                                      </React.Fragment>
                                                    )
                                                  )}
                                              </Carousel>
                                            ) : (
                                              <>
                                                {image.media_url.includes(
                                                  "video"
                                                ) ? (
                                                  <video
                                                    style={{
                                                      maxHeight: "350px",
                                                    }}
                                                    className="feed-tab-media"
                                                    autoPlay
                                                    playsInline
                                                    muted
                                                    loop
                                                    poster={
                                                      image.thumbnail
                                                        ? image.thumbnail
                                                        : "/assets/dummy_video.png"
                                                    }
                                                  >
                                                    <source
                                                      src={image.media_url}
                                                    />
                                                  </video>
                                                ) : (
                                                  <img
                                                    style={{
                                                      maxHeight: "350px",
                                                    }}
                                                    className="feed-tab-media"
                                                    src={image.media_url}
                                                    alt="post"
                                                  />
                                                )}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <img
                                            className="feed-tab-media"
                                            src={
                                              image.thumbnail
                                                ? image.thumbnail
                                                : "/assets/dummy_video.png"
                                            }
                                            alt="post"
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <div>
                                        <h4 style={{ marginTop: "7.5%" }}>
                                          {new Intl.DateTimeFormat("en-US", {
                                            year: "numeric",
                                            day: "numeric",
                                            month: "long",
                                          }).format(new Date(image.post_date))}
                                        </h4>
                                        <h4> Newsletter</h4>
                                      </div>
                                    )}
                                    <p className="creator-feed-content">
                                      {new Date(image.post_date).getFullYear() >
                                        2000 && (
                                        <span className="creator-feed-content-date">
                                          {new Intl.DateTimeFormat("en-US", {
                                            year: "numeric",
                                            day: "numeric",
                                            month: "long",
                                          }).format(new Date(image.post_date))}
                                        </span>
                                      )}
                                      {image.platform && (
                                        <span className="creator-feed-content-icon">
                                          {renderPlatformIcon(image.platform)}
                                        </span>
                                      )}
                                    </p>
                                  </div> */}

                                  <div className="feed-tab-row-actions-mobile">
                                    <FeedActionButtons
                                      activeKey={activeKey}
                                      post={image}
                                      postIndex={image.index}
                                      postId={image.post_id}
                                      pageIndex={image.pageIndex}
                                      index={index}
                                      type={image.type}
                                      url={image.media_url}
                                      filter={filter}
                                      username={username}
                                      subscription_type={subscription_type}
                                      user_id={user_id}
                                      setLimitError={setLimitError}
                                      refetchData={refetchData}
                                      setLoadingComments={setLoadingComments}
                                      setSelectedPostId={setSelectedPostId}
                                      setIsModalOpen={setIsModalOpen}
                                      setCommentsData={setCommentsData}
                                      setNoCommentsMsg={setNoCommentsMsg}
                                    />
                                  </div>
                                </div>

                                <div className="feed-tab-row-tags">
                                  <div className="search-container-tagInput">
                                    <input
                                      type="text"
                                      id="tagInput"
                                      enterKeyHint="enter"
                                      placeholder="Enter tags here"
                                      className="tagInput"
                                      onKeyUp={(e: any) => {
                                        submitTag(
                                          e,
                                          image.tagg,
                                          e.target.value,
                                          image.post_id,
                                          1,
                                          1
                                        );
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: "10px",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    {image.tagg || image.table_tags? (
                                      <TagsBox
                                        tagsArray={
                                          image.tagg
                                            ? image.tagg.split(","):image.table_tags
                                            ? image.table_tags.split(",")
                                            : [""]
                                        }
                                        removeTag={(tag: string) => {
                                          removeTag(
                                            image.tagg
                                              ? image.tagg.split(","):image.table_tags
                                              ? image.table_tags.split(",")
                                              : [""],
                                            tag,
                                            image.post_id,
                                            1,
                                            index
                                          );
                                        }}
                                        page="content"
                                      />
                                    ) : (
                                      <p className="noTags">No Tags</p>
                                    )}
                                  </div>
                                </div>

                                <div className="feed-tab-actions">
                                  <FeedActionButtons
                                    activeKey={activeKey}
                                    post={image}
                                    postIndex={image.index}
                                    postId={image.post_id}
                                    pageIndex={image.pageIndex}
                                    index={index}
                                    type={image.type}
                                    url={image.media_url}
                                    filter={filter}
                                    username={username}
                                    subscription_type={subscription_type}
                                    user_id={user_id}
                                    setLimitError={setLimitError}
                                    refetchData={refetchData}
                                    setLoadingComments={setLoadingComments}
                                    setSelectedPostId={setSelectedPostId}
                                    setIsModalOpen={setIsModalOpen}
                                    setCommentsData={setCommentsData}
                                    setNoCommentsMsg={setNoCommentsMsg}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                       
                        ))}
                      </Fragment>
                    ) : (
                      <p className="">
                        {filter && filter !== "" ? (
                          <>
                            {activeKey === "archived" ? (
                              <i>
                                No archived posts found matching your search for{" "}
                                <b>{filter}</b>.
                              </i>
                            ) : activeKey === "active,ad" ? (
                              <i>
                                No ads found matching your search for{" "}
                                <b>{filter}</b>.
                              </i>
                            ) : activeKey === "active,featured" ? (
                              <i>
                                No featured posts found matching your search for{" "}
                                <b>{filter}</b>.
                              </i>
                            ) : (
                              <i>No search results found.</i>
                            )}
                          </>
                        ) : (
                          <>
                            {activeKey === "archived" ? (
                              <i>No posts have been archived yet.</i>
                            ) : activeKey === "active,ad" ? (
                              <i>No posts have been marked as an ad yet.</i>
                            ) : activeKey === "active,featured" ? (
                              <i>No posts have been marked as featured yet.</i>
                            ) : (
                              <i>
                                Your data has not been processed yet. Come back
                                soon!
                              </i>
                            )}
                          </>
                        )}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <p className="">
                      {filter && filter !== "" ? (
                        <>
                          {activeKey === "archived" ? (
                            <i>
                              No archived posts found matching your search for{" "}
                              <b>{filter}</b>.
                            </i>
                          ) : activeKey === "active,ad" ? (
                            <i>
                              No ads found matching your search for{" "}
                              <b>{filter}</b>.
                            </i>
                          ) : activeKey === "active,featured" ? (
                            <i>
                              No featured posts found matching your search for{" "}
                              <b>{filter}</b>.
                            </i>
                          ) : (
                            <i>No search results found.</i>
                          )}
                        </>
                      ) : (
                        <>
                          {activeKey === "archived" ? (
                            <i>No posts have been archived yet.</i>
                          ) : activeKey === "active,ad" ? (
                            <i>No posts have been marked as an ad yet.</i>
                          ) : activeKey === "active,featured" ? (
                            <i>No posts have been marked as featured yet.</i>
                          ) : (
                            <i>
                              Your data has not been processed yet. Come back
                              soon!
                            </i>
                          )}
                        </>
                      )}
                    </p>
                  </>
                )}
              </div>

              <Modal
                title="Show Relevant Comments"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                bodyStyle={{
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 300px)",
                }}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={handleOk}
                  >
                    Save
                  </Button>,
                ]}
              ></Modal>

           
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default ContentSearchTable;
