import { supabase } from "../../config/supabaseClient";

export async function addToProductBank(
  name: any,
  url: any,
  image: any,
  user_id: any,
  query: any
) {
  function generateRandom12DigitNumber() {
    // Generate a random number between 100000000000 and 999999999999
    return (
      Math.floor(Math.random() * (9999999999999999 - 1000000000000000 + 1)) +
      1000000000000000
    );
  }

  // Example usage:
  let randomNumber = generateRandom12DigitNumber();

  const { data, error } = await supabase.from("product_bank").insert([
    {
      name,
      url,
      image,
      query: `|${query}|`,
      user_id,
      product_id: randomNumber,
    },
  ]);

  if (error !== null) {
    let randomNumber = generateRandom12DigitNumber();

    const { data, error } = await supabase.from("product_bank").insert([
      {
        name,
        url,
        image,
        query: `|${query}|`,
        user_id,
        product_id: randomNumber,
      },
    ]);
  }
}

export async function updateProductBankQuery(
  ids: any,
  user_id: any,
  query: any
) {
  for (let i of ids) {
    if (!i.table_query?.replace(/\|/g, "")?.split(",").includes(query)) {
      i.table_query =
        i.table_query !== ""
          ? i.table_query + "," + "|" + query + "|"
          : "|" + query + "|";
    }
    const { data, error } = await supabase
      .from("product_bank")
      .update({ query: i.table_query })
      .eq("user_id", user_id)
      .eq("product_id", i.table_id)
      .eq("name", i.table_name);
  }
}

export async function removeProductBankQuery(
  id: any,
  user_id: any,
  postData: any,
  query: any
) {
  let updatedData = postData.table_query
    ?.replace(/\|/g, "") // Remove all pipes
    .split(",") // Split by comma
    .filter((item: any) => {
      return item !== query;
    })
    .map((item: any) => `|${item}|`)
    .join(",");

  const { data, error } = await supabase
    .from("product_bank")
    .update({ query: updatedData })
    .eq("user_id", user_id)
    .eq("product_id", id);
}

export async function removeProductBank(
  id: any,
  user_id: any,
  postData: any,
  query: any
) {
  const { data, error } = await supabase
    .from("product_bank")
    .delete()
    .eq("user_id", user_id)
    // .eq("id", id)
    .eq("product_id", postData.table_id);
}

export async function removeProductBankName(
  id: any,
  user_id: any,
  postData: any,
  query: any
) {
  const { data, error } = await supabase
    .from("product_bank")
    .delete()
    .eq("user_id", user_id)
    // .eq("id", id)
    .eq("product_id", postData.table_product_id);
}
