import { useEffect } from "react";
import CreatorQuote from "../../../components/CreatorQuote/creatorQuote";
import { TabTitle } from "../../../Utils/GeneralFunctions";
import AuthPagesHeader from "../../../components/Header/AuthPagesHeader";
import AuthPageSwitch from "../../../components/AuthPageSwitch/authPageSwitch";
import "../Auth.css";
import PhoneLogin from "../LoginProviders/PhoneLogin";
import AuthTerm from "../../../components/AuthTerm/authTerm";
import { Link } from "react-router-dom";
import EmailOTPLogin from "../LoginProviders/EmailOTPLogin";

const Login = () => {
  TabTitle("Login • Bubble");
  
  return (
    <div className="auth-container">
      <div
        style={{
          width: "100%",
          display: "flex",
          minHeight: "100%",
        }}
      >
        <div className="auth-phone-signup-container-flex">
          {/* <AuthPagesHeader /> */}

          <div className='navbar_container'>
					<a href="/" className='navbar_logo-link w-nav-brand' aria-label='home'>
						<div className='logo-text'>
							<img
								className='homepage-icon'
								src='/assets/output-onlinepngtools-edited.png'
								alt='thesearchbubble logo'
							/>
						</div>
					</a>
						<nav className='navbar_menu w-nav-menu'>
							<div className='navbar_menu-link-wrapper'>
								{/* <a href="/pricing" className="navbar_link w-nav-link pricing">pricing</a> */}
								{/* <a href="/about" className="navbar_link w-nav-link">about</a> */}
								{/* <a href="/how-it-works" className="navbar_link w-nav-link">how it works</a> */}
								{/* <a href="/login" className="navbar_link w-nav-link login">login</a> */}
								<a href="/subscribe" className="navbar_link w-nav-link sign-up">sign up for free</a>
							</div>
						</nav>
					</div>

          <div className="auth-form-wrapper">
            <div className="auth-title-div">
              <p className="auth-title-div-p">Welcome back</p>
            </div>

            {/* <PhoneLogin /> */}

          <EmailOTPLogin/>
            {/* <AuthPageSwitch loginPage /> */}
          </div>
          <AuthTerm />
        </div>
        <CreatorQuote />
      </div>
    </div>
  );
};

export default Login;
  {/* <h6 className="auth-small-text" style={{fontSize:'0.9rem'}}>
              Login by Email? 
              <Link to="/login-email"> Click here!</Link>{" "}
            </h6> */}