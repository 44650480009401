import React, { useState } from "react";
import { Button, List, Modal, TabsProps } from "antd";
import { GrNext } from "react-icons/gr";
import { useLoadStayUpToDateComponents } from "../../../Utils/customHooks/UpToDateContent/useLoadStayUpToDateComponents";
import "./StayUpToDateControls.css";
import { IoAddCircle } from "react-icons/io5";
import { StaticVariables } from "../../../Utils/StaticVariables";
import StayUpToDateComponentsWrapper from "./StayUpToDateComponents/StayUpToDateComponentsWrapper";
import ShopLinksComponent from "./StayUpToDateControlsComponents/ShopLinksComponents/ShopLinksComponent";
import LinksComponent from "./StayUpToDateControlsComponents/LinksComponent/LinksComponent";
import HighlightComponent from "./StayUpToDateControlsComponents/HighlightComponent/HighlightComponent";
import ContentComponent from "./StayUpToDateControlsComponents/ContentComponent/ContentComponent";
import CustomBannerComponent from "./StayUpToDateControlsComponents/CustomBannerComponent/CustomBannerComponent";
import CustomCarouselComponent from "./StayUpToDateControlsComponents/CustomCarouselComponent/CustomCarouselComponent";
import ArchivedComponentsWrapper from "./StayUpToDateComponents/ArchivedComponentsWrapper";
import { NewsletterComponent } from "./StayUpToDateControlsComponents/NewsletterComponent/NewsletterComponent";

const StayUpToDateControls = (props: any) => {
  const { username, user_id, brand_color } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCarouselModalOpen, setIsCarouselModalOpen] = useState(false);
  const [isShoplinksModalOpen, setIsShoplinksModalOpen] = useState(false);
  const [isContentModalOpen, setIsContentModalOpen] = useState(false);
  const [isLinksModalOpen, setIsLinksModalOpen] = useState(false);
  const [isHighlightModalOpen, setIsHighlightModalOpen] = useState(false);
  const [isCustomBannerModalOpen, setIsCustomBannerModalOpen] = useState(false);
  const [isNewsletterModalOpen, setIsNewsletterModalOpen] = useState(false);

  const [isCustomCarouselModalOpen, setIsCustomCarouselModalOpen] =
    useState(false);
  const [status, setStatus] = useState("");
  const {
    isLoading: StayUpToDateComponentsLoading,
    data: StayUpToDateComponents,
    refetch,
  }: any = useLoadStayUpToDateComponents(username, user_id, status);
  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };
  const data = [
    {
      title: "My Links",
      component: handleLinks,
      disabled: StayUpToDateComponents?.data.some(
        (obj: any) => obj.display_name === "My Links"
      ),
    },
    {
      title: "Collect Contacts",
      component: handleNewsletter,
      disabled: StayUpToDateComponents?.data.some(
        (obj: any) => obj.display_name === "Newsletter"
      ),
    },
    {
      title: "Highlight",
      component: handleHighlights,
    },
    {
      title: "Banner",
      component: handleCustomBanners,
    },
  
  
    {
      title: "Product Bank Carousel",
      task: true,
      component: handleCustomCarousel,
    },
    {
      title: "Shoppable Carousels",
      task: true,
      component: handleShoplinks,
    },
    {
      title: "Content Carousels",
      task: true,
      component: handleContent,
    },
  ];

  function handleLinks() {
    setIsLinksModalOpen(true);
    setIsShoplinksModalOpen(false);
    setIsCarouselModalOpen(false);
    setIsModalOpen(false);
    setIsContentModalOpen(false);
  }
  function handleShoplinks() {
    setIsShoplinksModalOpen(true);
    setIsCarouselModalOpen(false);
    setIsModalOpen(false);
    setIsContentModalOpen(false);
    setIsLinksModalOpen(false);
  }
  function handleContent() {
    setIsContentModalOpen(true);
    setIsCarouselModalOpen(false);
    setIsModalOpen(false);
    setIsShoplinksModalOpen(false);
    setIsLinksModalOpen(false);
  }
  function handleHighlights() {
    setIsHighlightModalOpen(true);
    setIsModalOpen(false);
  }
  function handleCustomBanners() {
    setIsCustomBannerModalOpen(true);
    setIsModalOpen(false);
  }

  function handleCustomCarousel() {
    setIsCustomCarouselModalOpen(true);
    setIsModalOpen(false);
  }
  function handleNewsletter() {
    setIsNewsletterModalOpen(true);
    setIsModalOpen(false);
  }
  function handleTask(title: any) {
    if (title === "Carousels") {
      setIsCarouselModalOpen(true);
      setIsModalOpen(false);
      setIsShoplinksModalOpen(false);
      setIsContentModalOpen(false);
      setIsLinksModalOpen(false);
    } else if (title === "Shop Links Carousel") {
      setIsShoplinksModalOpen(true);
      setIsCarouselModalOpen(false);
      setIsModalOpen(false);
      setIsContentModalOpen(false);
      setIsLinksModalOpen(false);
    } else if (title === "Content Carousel") {
      setIsContentModalOpen(true);
      setIsCarouselModalOpen(false);
      setIsModalOpen(false);
      setIsShoplinksModalOpen(false);
      setIsLinksModalOpen(false);
    }
  }

  return (
    <>
      <div className="details">
        <h1 className="dashboard-mobile-hide">
          {StaticVariables().MANAGE_STAYUPTODATE_TITLE}
        </h1>
        <Modal
          // title="Basic Modal"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <h3 className="title">Add to your page</h3>
          <br />
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item>
                <Button
                  className="list-item-btn"
                  type="text"
                  onClick={item.component}
                  disabled={item.disabled}
                >
                  <div
                    className="list-btn-text"
                  >
                    <p>{item.title}</p>
                    {item.task && (
                      <button
                        onClick={() => handleTask(item.title)}
                      >
                        <GrNext />
                      </button>
                    )}
                  </div>
                </Button>
              </List.Item>
            )}
          />
        </Modal>

        <ShopLinksComponent
          isShoplinksModalOpen={isShoplinksModalOpen}
          setIsShoplinksModalOpen={setIsShoplinksModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetch={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
        />
        <LinksComponent
          isLinksModalOpen={isLinksModalOpen}
          setIsLinksModalOpen={setIsLinksModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetch={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
        />

        <HighlightComponent
          isHighlightModalOpen={isHighlightModalOpen}
          setIsHighlightModalOpen={setIsHighlightModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetch={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
        />
        <CustomCarouselComponent
          isCustomCarouselModalOpen={isCustomCarouselModalOpen}
          setIsCustomCarouselModalOpen={setIsCustomCarouselModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetch={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
        />
        <ContentComponent
          isContentModalOpen={isContentModalOpen}
          setIsContentModalOpen={setIsContentModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetch={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
        />

        <CustomBannerComponent
          isCustomBannerModalOpen={isCustomBannerModalOpen}
          setIsCustomBannerModalOpen={setIsCustomBannerModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetch={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
        />
        <NewsletterComponent
          isNewsletterModalOpen={isNewsletterModalOpen}
          setIsNewsletterModalOpen={setIsNewsletterModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetch={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
        />
     

        <IoAddCircle className="button-icon" onClick={showModal} />

        <StayUpToDateComponentsWrapper
          username={username}
          user_id={user_id}
          StayUpToDateComponents={StayUpToDateComponents?.showingComponents}
          refetch={refetch}
          StayUpToDateComponentsLoading={StayUpToDateComponentsLoading}
          brand_color={brand_color}
          // setStatus={setStatus}
        />
        <>
          <div className="stay-up-to-date-wrapper">
            <div onClick={toggleCollapse} className="archive-toggle-section">
              <div>Archived Components</div>
              <div className="archive-toggle-section-button">
                {isOpen ? "Hide" : "Show"}
              </div>
            </div>
          </div>
          {isOpen && (
            <ArchivedComponentsWrapper
              username={username}
              user_id={user_id}
              StayUpToDateComponents={StayUpToDateComponents?.archiveComponents}
              refetch={refetch}
              StayUpToDateComponentsLoading={StayUpToDateComponentsLoading}
              brand_color={brand_color}
              // setStatus={setStatus}
            />
          )}
        </>
      </div>
    </>
  );
};

export default StayUpToDateControls;
