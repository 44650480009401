import { Button } from 'antd'
import React from 'react'
import AccountsIntegration from '../../../../components/AccountsIntegration/AccountsIntegration'

const MyLinksComponent = (props:any) => {
const {username,isLinksModalOpen}=props
  return (
    <div style={{marginTop:'4%'}}>
    <div className="account-values-profiles">
   
    <AccountsIntegration
      isLinksModalOpen={isLinksModalOpen}
      setIsLinksModalOpen={props.setIsLinksModalOpen}
      username={username}
    />
  </div>
  </div>
  )
}

export default MyLinksComponent