import { Modal, Tabs } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import CreateNewProduct from "./CreateNewProduct";
import AddProductBank from "./AddProductBank";
import { addComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import { updateProductBankQuery } from "../../../../../Utils/SupabaseServices/ProductBankDatabase";

const CustomCarouselComponent = (props: any) => {
  const { username, user_id, isCustomCarouselModalOpen } = props;
  const [activeKey, setActiveKey] = useState("create");
  const [dataCompleted, setDataCompleted] = useState(false); // State to track if all data is present
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [cta, setCTA] = useState("Check it out!");
  const [posts, setPosts] = useState([]);
  const [type, setType] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [image, setImage] = useState("");
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [defaultAllPosts, setDefaultAllPosts] = useState([]);
  const [defaultQuery, setDefaultQuery] = useState("");

  const [reset, setReset] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const navTabs = [
    {
      key: "create",
      label: "Add Products",
      disabled: false,
    },
    {
      key: "add",
      label: "Create Carousel",
      disabled: false,
    },
  ];
  const handlePrevious = () => {
    props.setIsModalOpen(true);
    props.setIsCustomCarouselModalOpen(false);
    setName("");
    setLink("");
    setImage("");
    setType("");
    setErrorMsg("");
    setCTA("Check it out!");
    setActiveKey("create");

    setDefaultFileList([]);
    setDefaultAllPosts([]);
    setDefaultQuery("")
    setReset(true);
    setDataCompleted(false);
  };
  async function handleData(componentHeading: any, type: any, posts: any) {
    if (componentHeading) {
      setName(componentHeading);
      setType(type);
      setPosts(posts);
      setDataCompleted(true);
    } else {
      setDataCompleted(false);
    }
  }
  const handleCancel = () => {
    props.setIsCustomCarouselModalOpen(false);
    props.setIsModalOpen(false);
    setName("");
    setLink("");
    setImage("");
    setType("");
    setCTA("Check it out!");
    setErrorMsg("");
    setActiveKey("create");
    setDefaultAllPosts([]);
    setReset(true);
    setDefaultQuery("")

    setDefaultFileList([]);
    setDataCompleted(false);
  };
  const handleOk = async () => {
    setConfirmLoading(true);

    const res = await addComponent(
      name,
      "",
      username,
      user_id,
      "false",
      cta,
      "",
      name,
      "product-bank"
    );
    if (type === "product-bank") {
      await updateProductBankQuery(posts, user_id, name);
    }
    if (res === "true") {
      setErrorMsg("");
      props.setIsCustomCarouselModalOpen(false);

      props.setIsModalOpen(false);
      props.refetch();

      setActiveKey("create");
      setName("");
      setLink("");
      setImage("");
      setType("");
      setCTA("Check it out!");
      setDefaultFileList([]);
      setDefaultAllPosts([]);
      setDefaultQuery("")

      setReset(true);
      setConfirmLoading(false);

      setDataCompleted(false);
    } else {
      setErrorMsg("Carousel with the same name already exists.");
      setConfirmLoading(false);

    }

  };
  return (
    <Modal
      title={
        <div className="modal-title">
          <button
            onClick={handlePrevious}
            className="newbutton"
          >
            <FaArrowLeft />
          </button>
        </div>
      }
      open={isCustomCarouselModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Add"
      confirmLoading={confirmLoading}
      okButtonProps={{ disabled: isButtonDisabled}}
      footer={activeKey === "add" ? undefined : null}
      >
      <h5 className="title mb-20">
        Create a Product Bank Carousel
      </h5>
      <TabOptions
        navTabs={navTabs}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        setName={setName}
        setLink={setLink}
        setImage={setImage}
        setType={setType}
        setDefaultFileList={setDefaultFileList}
        setDefaultAllPosts={setDefaultAllPosts}
        setErrorMsg={setErrorMsg}
        setCTA={setCTA}
      />
      {errorMsg && <p className="errorMsg">{errorMsg}</p>}
      {activeKey === "create" ? (
        <CreateNewProduct
          username={username}
          user_id={user_id}
          handleData={handleData}
          defaultName={name}
          setComponentName={setName}
          link={link}
          image={image}
          defaultFileList={defaultFileList}
          defaultAllPosts={defaultAllPosts}
          setErrorMsg={setErrorMsg}
          cta={cta}
          setCTA={setCTA}
          reset={reset}
          setReset={setReset}
        />
      ) : activeKey === "add" ? (
        <AddProductBank
          username={username}
          user_id={user_id}
          handleData={handleData}
          defaultName={name}
          link={link}
          image={image}
          defaultFileList={defaultFileList}
          setErrorMsg={setErrorMsg}
          cta={cta}
          setCTA={setCTA}
          setDefaultQuery={setDefaultQuery}
          defaultQuery={defaultQuery}
          setIsButtonDisabled={setIsButtonDisabled}

        />
      ) : (
        ""
      )}{" "}
    </Modal>
  );
};
const TabOptions = (props: any) => {
  const { navTabs, activeKey } = props;

  const onChange = (key: string) => {
    props.setActiveKey(key);
    props.setName("");
    props.setLink("");
    props.setImage("");
    props.setType("");
    props.setDefaultFileList([]);
    props.setDefaultAllPosts([]);
    props.setErrorMsg("");
    props.setCTA("");
  };
  return (
    <div>
      <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChange}>
        {navTabs.map((tab: any) => (
          <Tabs.TabPane
            key={tab.key}
            tab={
              <div className="modal-title">
                <span style={{ color: tab.isLocked ? "grey" : "" }}>
                  {tab.label}
                </span>
              </div>
            }
          ></Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};
export default CustomCarouselComponent;
