import { Button, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import "../../../StayUpToDateControls.css";
import { addComponent } from "../../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import CheckboxComponent from "../../../../../../components/CheckboxComponent/DaysCheckboxComponent";

const DayWiseLinksComponents = (props: any) => {
  const {
    username,
    user_id,
    isDayWiseLinksComponentOpen,
    StayUpToDateComponents,
  } = props;
  const [daysNameArray, setDaysNameArray] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const handleOk = async () => {
    setConfirmLoading(true);
    

    const res = await addComponent(
      `${daysNameArray.join(" & ")} Shoppable Content`,
      "",
      username,
      user_id,
      "true",
      "Check it out!",
      "",
      daysNameArray.toString(),
      "daywise"
    );
    if (res === "true") {
      props.refetch();
      props.setIsShoplinksModalOpen(false);
      props.setIsDayWiseLinksComponentOpen(false);
      setDaysNameArray([]);
      setErrorMsg("");
      setConfirmLoading(false);

    } else {
      setErrorMsg("Carousel with this combination of day(s) already exists.");
      setConfirmLoading(false);

    }

  };

  const handleCancel = () => {
    props.setIsShoplinksModalOpen(false);
    props.setIsDayWiseLinksComponentOpen(false);
    setDaysNameArray([]);
    setErrorMsg("");
  };
  const handlePrevious = () => {
    props.setIsDayWiseLinksComponentOpen(false);
    props.setIsShoplinksModalOpen(true);
    setDaysNameArray([]);
    setErrorMsg("");
  };
  const handleDays = (daysName: any) => {
    setDaysNameArray(daysName);
  };
  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <button
              onClick={handlePrevious}
              className="newbutton"
            >
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isDayWiseLinksComponentOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
      >
        <h5 className="title mb-15">
          Shoppable Content by the Day
        </h5>
        <p  className="text-center">
          All of your shoppable content posted on the day(s) selected below will always be shown in this carousel. Content will automatically be updated!
        </p>
        <p  className="text-center">
          This is a great way to highlight content for a specific day! For example, if you post popular content from the week every Friday, selecting Friday below will automatically show Friday's content every week in this carousel.
        </p>
        <CheckboxComponent
          StayUpToDateComponents={StayUpToDateComponents}
          handleDays={handleDays}
          daysNameArray={daysNameArray}
          setErrorMsg={setErrorMsg}
          className="daywise"
        />

      </Modal>
    </>
  );
};


export default DayWiseLinksComponents;
