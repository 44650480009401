import React, { useEffect, useState } from "react";
import "./NewsletterData.css";
import { Button } from "antd";
import { BannerSkeleton } from "../../BannerSkeleton";
const NewsletterData = (props: any) => {
  const [loading, setLoading] = useState(true);
//   useEffect(() => {
//     setTimeout(() => {
//       setLoading(false);
//     }, 1000);
//   }, []);
  const { title, component, handleRedirect, brand_color } = props;
  useEffect(() => {
    // Flodesk Script Initialization
    (function (
      w: any,
      d: Document,
      t: string,
      h: string,
      s: string,
      n: string
    ) {
      w.FlodeskObject = n;
      const fn = function (...args: any[]) {
        (w[n].q = w[n].q || []).push(args);
      };
      w[n] = w[n] || fn;
      const f: any = d.getElementsByTagName(t)[0];
      const v = "?v=" + Math.floor(new Date().getTime() / (120 * 1000)) * 60;

      // Module script
      const sm: any = d.createElement(t);
      sm.async = true;
      sm.type = "module";
      sm.src = h + s + ".mjs" + v;
      f.parentNode.insertBefore(sm, f);

      // NoModule script
      const sn: any = d.createElement(t);
      sn.async = true;
      sn.noModule = true;
      sn.src = h + s + ".js" + v;
      f.parentNode.insertBefore(sn, f);
    })(
      window,
      document,
      "script",
      "https://assets.flodesk.com",
      "/universal",
      "fd"
    );

    // Flodesk form initialization
    if (window.fd) {
      window.fd("form", {
        formId: "66b3e4cce43bc94911e83b05",
        containerEl: "#fd-form-66b3e4cce43bc94911e83b05",
        // formId: "66b547f8876ad814d05bcfc0",
        // containerEl: "#fd-form-66b547f8876ad814d05bcfc0",
      });
    }
    setLoading(false)
  }, []);
  return (
    <>
      {loading ? (
        <BannerSkeleton />
      ) : (
        <>
          {/* <div className="mainDiv"> */}
          <div className="mainDiv">
          <p className="component-name">{component.name}</p>
          </div>
          {/* <div
          className="outter-section"
          style={{
            marginTop: "15px",
            backgroundColor: brand_color && brand_color,
          }}
        > */}
          <div className="outter-section-banner-subcomponent"
          style={{
            marginTop: "0px",
            backgroundColor: brand_color,
            width: "100%"
          }}
          >
            <div id="fd-form-66b3e4cce43bc94911e83b05" ></div>
            {/* <div id="fd-form-66b547f8876ad814d05bcfc0"></div> */}
          {/* </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default NewsletterData;
