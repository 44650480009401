import React, { useEffect, useState } from "react";
import { useLoadSearchBarTags } from "../../Utils/customHooks/useLoadSearchBarTags";
import { useParams } from "react-router-dom";
import TagsBox from "../TagsBox/TagsBox";
import useUpdateSearchBarTags from "../../Utils/customHooks/Update/useUpdateSearchBartags";
import { Button, Modal } from "antd";
import { BsSearch } from "react-icons/bs";
import "./SearchBarTags.css";

const SearchBarTags = (props: any) => {
  const { userData, searchBarText } = props;

  const { username } = useParams();
  const { isLoading, data } = useLoadSearchBarTags(username);
  const { mutate: updateCategory } = useUpdateSearchBarTags();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allPlaceholders, setAllPlaceholders] = useState<string[]>([]);
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    if (data) {
      const tags = data?.split(",");
      const placeholders = [
        searchBarText,
        ...tags.map((tag: string) => `Search "${tag}"`),
      ];
      setAllPlaceholders(placeholders);
    } else {
      setAllPlaceholders([searchBarText]);
    }
  }, [data, searchBarText]);
  const showModal = () => {
    setPlaceholderIndex(0);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setPlaceholderIndex(0);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setPlaceholderIndex(0);
  };

  async function removeTag(tagArr: any, tag: any) {
    const updatedTags = tagArr.filter((t: any) => t !== tag);
    if (updatedTags.filter((tag: any) => tag.trim() !== "").length < 7) {
      setErrorMsg("");
    }
    updateCategory({
      tag,
      tagString: updatedTags.toString(),
      user_id: userData.user_id, // Ensure user_id is defined somewhere
      action: "remove",
      pageIndex: 1,
      index: 1,
    });
    // Call the updateCategory function here if needed
  }

  async function submitTag(e: any, tagArray: any, tag: any) {
    e.preventDefault();
    tag = tag.trim();
    if (tag) {
      if (e.key === "Enter") {
        // if(tagArray){
          if (
            tagArray?.split(",").filter((tag: any) => tag.trim() !== "").length < 7
           ) {
             setErrorMsg("");
   
             let arrString = tagArray
               ? [...tagArray.split(","), tag].toString()
               : tag;
   
             updateCategory({
               tag,
               tagString: arrString,
               user_id: userData.user_id, // Ensure user_id is defined somewhere
               action: "submit",
               pageIndex: 1,
               index: 1,
             });
   
             e.target.value = "";
           } else {
             setErrorMsg("Cannot add more than 7 suggestions.");
             e.target.value = "";
           }
        // }
       
      }
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex(
        (prevIndex) => (prevIndex + 1) % allPlaceholders.length
      );
    }, 3000); // Change interval time as needed

    return () => clearInterval(interval);
  }, [allPlaceholders]);
  return (
    <>
      <div>
        <div className="account-social-logins-heading">
          <h5>Search Bar Suggestions </h5>
        </div>
        <div className="account-social-content-connect">
          <React.Fragment>
            <div className="account-social-link-connect-row">
              <div
                className="account-social-link-connect-row-cont social-icon-width"
              >
                <div className="custom-category-queryInput">
                  <input
                    type="text"
                    id="tagInput"
                    enterKeyHint="enter"
                    placeholder="Enter searchbar suggestions"
                    className="tagInput query-input"
                    onKeyUp={(e: any) => {
                      submitTag(e, data, e.target.value);
                    }}
                  />
                </div>
                <div className="max-tags-text">
                  <p className="max-tag">Max 7</p>
                  {errorMsg && (
                    <p className="errorMsg">{errorMsg}</p>
                  )}
                </div>

                <div
                  className="tag-box"
                >
                  {data ? (
                    <TagsBox
                      tagsArray={data.split(",")}
                      removeTag={(tag: string) => {
                        removeTag(data.split(","), tag);
                      }}
                      page="account"
                    />
                  ) : (
                    <p className="noTags font-size-1">
                      No search tags added yet.
                    </p>
                  )}
                </div>
                <div></div>
              </div>
            </div>
          </React.Fragment>
        </div>
        <Button
          className="preview-search"
          onClick={() => {
            setPlaceholderIndex(0);
            showModal();
          }}
        >
          Preview Search Bar
        </Button>
        <Modal
          title="Searchbar Preview"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="search-container">
            <input
              type="text"
              id="searchInput"
              placeholder={allPlaceholders[placeholderIndex]}
              className="search-wrapper-input slidingVertical" // Add slidingVertical class
              autoComplete="off"
            />

            <button className="search-wrapper-btn">
              <BsSearch />
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default SearchBarTags;
