import { Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import ProductBankCheckboxComponent from "../../../../../components/CheckboxComponent/ProductBankCheckboxComponent";
import { updateProductBankQuery } from "../../../../../Utils/SupabaseServices/ProductBankDatabase";

const AddProductBank = (props: any) => {
  const {
    username,
    user_id,
    isCustomCarouselComponentOpen,
    defaultQuery,
    setDefaultQuery,
    setIsButtonDisabled
  } = props;

  const [name, setName] = useState("");
  // const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    props.handleData(name, "product-bank", postIds);
  }, [name]);
  const handleInputChange = (value: string, setter: Function) => {
    setter(value);
    setIsButtonDisabled(!value);
  };

  async function handlePostIds(post_ids: any) {
    await updateProductBankQuery(post_ids, user_id, name);

    setPostIds(post_ids);
  }
  return (
    <>
      <label
      className="bold-label"
      >Carousel Title</label>
      <Input
        placeholder="Enter Title"
        value={name}
        onChange={(e: any) => {
          handleInputChange(e.target.value, setName);
          setErrorMsg("");
        }}
        className="mb-20"
      />
      {errorMsg && <p className="errorMsg">{errorMsg}</p>}

      <ProductBankCheckboxComponent
        username={username}
        user_id={user_id}
        handlePostIds={handlePostIds}
        className="ant-checkbox-wrapper"
        componentName={name}
        // post_ids={postIds}
        defaultQuery={defaultQuery}
        setDefaultQuery={setDefaultQuery}
      />
    </>
  );
};

export default AddProductBank;
