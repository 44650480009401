import { Button, Input, List, Modal } from "antd";
import React, { useEffect, useState } from "react";
import "../../StayUpToDateControls.css";
import { updateComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import EditFeedCheckboxComponent from "./EditFeedCheckboxComponent";

const EditCustomLinks = (props: any) => {
  const {
    username,
    user_id,
    isLinksEditModalOpen,
    setIsLinksEditModalOpen,
    type,
    component,
    refetch,
  } = props;
  const [name, setName] = useState(component.name || ""); // const [details, setDetails] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage button disable state
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  useEffect(() => {
    setPostIds(component?.post_ids);
    setName(component?.name);
    setDefaultQuery(component?.query);
  }, [component]);
  const handleInputChange = (value: string, setter: Function) => {
    setter(value); // Set the input value
    setIsButtonDisabled(!value); // Disable button if either name or details is empty
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await updateComponent(
      name,
      component,
      username,
      user_id,
      defaultQuery,
      "links",
      
      postIds.toString(),
      "",
    );
   

    if (res === "true") {
      props.refetch();
      setName("");
      setPostIds("");
      setErrorMsg("");
      setDefaultQuery("");
      props.setSelectedComponent("");
      props.setIsLinksEditModalOpen(false);

      setConfirmLoading(false);
    } else {
      setErrorMsg("Category with this name already exists");
      setConfirmLoading(false);

    }

    // props.setIsCustomLinksComponentOpen(false);
  };

  const handleCancel = () => {
    props.setIsLinksEditModalOpen(false);
    props.setSelectedComponent("");

    // props.setIsCustomLinksComponentOpen(false);
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
  };

  function handlePostIds(post_ids: any) {
    setPostIds(post_ids);
  }
  return (
    <>
      <Modal
        open={isLinksEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !name }}
      >
        <div>
          <h5>
            Editing {name}
          </h5>
          <label
             className="bold-label"
          >
            Carousel Title
          </label>
          <Input
            placeholder="Enter Title"
            value={name}
            onChange={(e: any) => {
              handleInputChange(e.target.value, setName);
              setErrorMsg("");
            }}
            className="mb-15"
          />
          {errorMsg && <p className="errorMsg">{errorMsg}</p>}
          <EditFeedCheckboxComponent
            isShopLinks={true}
            username={username}
            user_id={user_id}
            handlePostIds={handlePostIds}
            post_ids={component?.post_ids}
            componentName={name}
            isSearchbar={
              component?.display_name?.split(",").includes("Monday") ||
              component?.display_name?.split(",").includes("Tuesday") ||
              component?.display_name?.split(",").includes("Wednesday") ||
              component?.display_name?.split(",").includes("Thursday") ||
              component?.display_name?.split(",").includes("Friday") ||
              component?.display_name?.split(",").includes("Saturday") ||
              component?.display_name?.split(",").includes("Sunday") ||
              component?.display_name?.split(",").includes("This Weekend") ||
              component?.display_name?.split(",").includes("Yesterday") ||
              component?.display_name?.split(",").includes("This Week")
                ? false
                : true
            }
            defaultQuery={defaultQuery}
            setDefaultQuery={setDefaultQuery}
            showTag={component?.type === "query-links" ? true : false}
            refetchDashboard={refetch}
          />
        </div>
      </Modal>
    </>
  );
};

const CardGridItem = ({ link, username }: any) => (
  <div
    className="card-grid-item"
  >
    <img
      alt="example"
      src="/assets/image-bg.jpeg"
    />
    <div>
      <h3>{username}</h3>
      {/* You can use 'link' here if it's supposed to display something */}
      {/* <p style={{ color: '#666', fontSize: 14 }}>{data?.description}</p> */}
    </div>
    <Button className="template-shop-btn">Shop</Button>
  </div>
);

// export default CardGridItem;
const CardDummyImg = ({ username }: any) => (
  <div className="creator-card-dummy-img h-5r">
    <span>{username}</span>
  </div>
);
export default EditCustomLinks;
