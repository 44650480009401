import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import "react-toastify/dist/ReactToastify.css";
import { CiLock } from "react-icons/ci";
import { useEffect, useState } from "react";
import { StaticVariables } from "../../../Utils/StaticVariables";
import { Modal } from "antd";
import { FaLock } from "react-icons/fa";

const RouteList = (props: any) => {
  const {option,tab}=props
  const [selectedIndex, setSelectedIndex] = useState<any>(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [isTabsLocked, setIsTabsLocked] = useState<any>();
  const listItems = StaticVariables().ROUTELIST_ITEMS;

useEffect(()=>{
  const index =listItems.indexOf(option);
  setSelectedIndex(index)
},[option])
  const handleOk = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const isEmailTabsLocked =
        localStorage.getItem("email_verify") === "false";
      setIsTabsLocked(isEmailTabsLocked);
    };

    window.addEventListener("storage", handleStorageChange);

    handleStorageChange();

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [window, localStorage]);
  useEffect(() => {
    if (props.tab) {
      setSelectedIndex(props.tab);
    }
  }, [props.tab]);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };
  function handleClick(text: any) {
    props.handleClick(text);
  }
  const showModal = () => {
    setIsModalOpen(true);
  };

  function handleSubmit() {
    if (!props.isEmail) {
      setModalTitle("Enter Email!");
      showModal();
    } else if (!props.isEmailVerified) {
      setModalTitle("Verify Email!");
      showModal();
    } else if (!props.isName) {
      setModalTitle("Enter Name!");
      showModal();
    }
  }
  const drawer = (
    <div className="drawer-menu">
      {props.isEmail &&
      props.isName &&
      props.isEmailVerified &&
      (props.email_verify || isTabsLocked === false) ? (
        <List>
          {listItems.map((text, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={(e: any) => {
                  handleClick(text);
                  e.target.focus();
                  handleListItemClick(e, index);
                }}
                id="listButton"
                className={`listButton${index}`}
                selected={selectedIndex === index}
              >
                {text}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      ) : (
        <List>
          {listItems.map((text, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                id="listButton"
                onClick={(e: any) => {
                  handleSubmit();

                  handleListItemClick(e, 0);
                }}
                selected={index === 0}
              >
                {index === 0 ? (
                  text
                ) : (
                  <>
                    {text}
                    &nbsp;&nbsp;
                    <FaLock style={{ color: "grey", fontSize: "1rem" }} />
                  </>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        className="account-modal"
      >
      </Modal>
    </div>
  );
  return <div className="drawer">{drawer}</div>;
};

export default RouteList;
