import React, { useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { HiCheck, HiPencil } from "react-icons/hi";
import { updateName } from "../../../../Utils/SupabaseServices/UserDatabase";
import { Button } from "antd";

const Name = (props: any) => {
  const { username, userData, setIsName } = props;
  const [name, setName] = useState(userData.name || "");
  const [editName, setEditName] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [nameError, setNameError] = useState("");
  const [modalContent, setModalContent] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  function handleUpdateValue(e: any, option: any) {
    if (option === "name") {
      if (e.target.value === "") {
        setName(e.target.value);

        setNameError("Name cannot be empty");
      } else {
        setName(e.target.value);
        setNameError("");
      }
    }
  }
  //
  const doneEdit = async (option: any) => {
    if (option === "name") {
      if (name === "" || name === "null") {
        setModalTitle("Enter Name!");
        showModal();
        await updateName(username, name);
        setIsName(false);
      } else {
        await updateName(username, name);
        setIsName(true);
      }
    }
  };
  return (
    <div className="account-grid-item">
      <div>
        <h3 className="account-grid-item-key">
          Name{" "}
          <BsInfoCircle
            className="info-tooltip"
            data-tooltip-id="displayName"
          />
        </h3>

        <h3 className="account-grid-item-value" id="name">
          {!editName ? (
            name ? (
              name
            ) : (
              " "
            )
          ) : (
            <input
              type="text"
              className="account-value-input"
              onChange={(e: any) => handleUpdateValue(e, "name")}
              value={name}
            />
          )}
          {!editName ? (
            // <button
            //   className="account-edit-btn-name"
            //   id="name"
            //   onClick={() => {
            //     setEditName(true);
            //   }}
            // >
            <Button
            className="changeButton"
              id="name"
              onClick={() => {
                setEditName(true);
              }}
            >
              Change
            </Button>
          ) : (
            // {/* <HiPencil /> */}
            // </button>
            <>
              {/* <Button
              style={{ background: "black", color: "white", marginTop: "10px", display: "block" }}
                onClick={() => {
                setEditName(false);
                doneEdit("name");
              }}
            >
              Save
            </Button>
             <Button
             style={{ background: "black", color: "white", marginTop: "10px", display: "block"}}
             onClick={() => {
               setEditName(false);
             }}
           >
            Cancel

           </Button> */}
              <div 
              className="saveButtonDiv"
              >
                <Button
                className="saveButton"
                  onClick={() => {
                    setEditName(false);
                    doneEdit("name");
                  }}
                >
                  Save
                </Button>
                <Button
                  className="saveButton"
                  onClick={() => {
                    setEditName(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
            // <button
            // disabled={nameError?true:false}
            //   className="account-done-btn-name"
            //   onClick={() => {
            //     setEditName(false);
            //     doneEdit("name");
            //   }}
            // >
            //   {/* <HiCheck /> */}
            // </button>
          )}
        </h3>
        {nameError ? (
          <p className="nameError">{nameError}</p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Name;
