import { Button, Switch } from "antd";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import SearchBarTags from "../../../../../components/SearchBarText/SearchBarTags";
import { useLoadUser } from "../../../../../Utils/customHooks/useLoadUser";
import SearchBarText from "../../../../../components/SearchBarText/SearchBarText";
import { useNavigate } from "react-router-dom";

const SearchbarControls = (props: any) => {
  const { username } = props;
  const {
    isLoading: userLoading,
    data: userData,
    refetch,
  }: any = useLoadUser(username);
  const navigate=useNavigate()

  const PreviewBtn = () => {
    return (
      <button
        className="push-btn"
        style={{marginTop:'-2%'}}
        onClick={() =>
          navigate(`/follower/${username}`, { state: { from: "dashboard" } })
        }
      >
        Preview Live Page
      </button>
    );
  };
  return (
    <div className="controls-container">
      {/* <SearchBarTags
        searchBarText={
          userData?.search_bar_text
            ? userData?.search_bar_text
            : `Search ${userData?.name} Content`
        }
        username={userData?.username}
        isLoading={userLoading}
        userData={userData}
        refetch={refetch}
      /> */}
      <div className="row">
        <div className="controls" style={{ padding:'4%'}}>

          <div className="account-values-profiles">
            <SearchBarText
              isLoading={userLoading}
              userData={userData}
              refetch={refetch}
            />
          </div>
          <div className="account-values-profiles">
            <SearchBarTags
              searchBarText={
                userData?.search_bar_text
                  ? userData?.search_bar_text
                  : `Search ${userData?.name} Content`
              }
              username={userData?.username}
              isLoading={userLoading}
              userData={userData}
              refetch={refetch}
            />
          </div>
          <div className="push-btn-div">
              <PreviewBtn />
            </div>
        </div>
      </div>
    </div>
  );
};

export default SearchbarControls;
