import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header/header";
import CopyToClipboard from "../../../components/CopyToClipboard/CopyToClipboard";
import ImageSidebar from "../../../components/Header/ImageSidebar";
import DashboardSkeleton from "../../../components/skeleton/DashboardView/dashboardSkeleton";

const DashboardRedirect = () => {
  const navigate = useNavigate();
  const username = "";
  useEffect(() => {
    const user = localStorage.getItem("loggedIn");
    navigate(`/dashboard/${user}`);
  }, []);
  return (
    <div className="dashboard">
      <Header
        extraComponent={
          <div
            style={{
              display: "flex",
              gap: "25px",
              alignItems: "center",
              justifyContent: "center",
              color: "black",
            }}
          >
            <CopyToClipboard
              textToCopy={`https://www.searchbubble.com/${username}`}
            />
            <ImageSidebar username={username as string} isDashboard={true} />
          </div>
        }
      />
      <div className="content-container">
        <div className="row">
          <h1 className="dashboard-mobile-show">Dashboard</h1>
          <DashboardSkeleton />
        </div>
      </div>
    </div>
  );
};

export default DashboardRedirect;
