import React from "react";
import "./StayUpToDate.css";
import UpToDateLinks from "./UpToDateComponents/UpToDateLinks/UpToDateLinks";
import YesterdayLinks from "./UpToDateComponents/YesterdayLinks/YesterdayLinks";
import TiktokData from "./UpToDateComponents/TikTokData/TiktokData";
import ReelsData from "./UpToDateComponents/ReelsData/ReelsData";
import { useLoadStayUpToDateComponents } from "../../Utils/customHooks/UpToDateContent/useLoadStayUpToDateComponents";
import dayjs from "dayjs";
import InstagramStoryData from "./UpToDateComponents/InstagramStoryData/InstagramStoryData";
import PodcastData from "./UpToDateComponents/PodcastData/PodcastData";
import YoutubeData from "./UpToDateComponents/YoutubeData/YoutubeData";
import CustomContentData from "./UpToDateComponents/CustomContentData/CustomContentData";
import CustomLinkData from "./UpToDateComponents/CustomLinkData/CustomLinkData";
import HighlightDataSecton from "./UpToDateComponents/HighlightDataSection/HighlightDataSecton";
import BannerData from "./UpToDateComponents/BannerData/BannerData";
import track, { TRACKING_TYPES } from "../../Utils/SupabaseServices/Track";
import { updateRefClick } from "../../Utils/SupabaseServices/ContentDatabase";
import { useLocation } from "react-router-dom";
import StayUpToDateSkeleton from "../../Screens/DashboardView/StayUpToDate/StayUpToDateComponents/StayUpToDateSkeleton";
import ProductBankData from "./UpToDateComponents/ProductBankData/ProductBankData";
import QueryLinkData from "./UpToDateComponents/QueryLinkData/QueryLinkData";
import QueryContentData from "./UpToDateComponents/QueryContentData/QueryContentData";
import { StaticVariables } from "../../Utils/StaticVariables";
import TrendingProductsData from "./UpToDateComponents/TrendingProductsData/TrendingProductsData";
import NewsletterWrapper from "../../Screens/DashboardView/StayUpToDate/StayUpToDateComponents/TemplateWrapper/NewsletterWrapper";
import NewsletterData from "./UpToDateComponents/NewsletterData/NewsletterData";

const StayUpToDate = (props: any) => {
  const location = useLocation();
  const { user_id, username, brand_color } = props;
  const {
    isLoading: StayUpToDateComponentsLoading,
    data: StayUpToDateComponents,
  }: any = useLoadStayUpToDateComponents(username, user_id);

  const daysSinceMultipleDays = (targetDaysOfWeek: number[]) => {
    const currentDayOfWeek = dayjs().day();
    const daysSinceTargetDays = targetDaysOfWeek.map(
      (targetDayOfWeek) => (currentDayOfWeek - targetDayOfWeek + 7) % 7
    );

    return daysSinceTargetDays;
  };
  const dayMappings: { [key: string]: number[] } =
    StaticVariables().dayMappings;

  function handleRedirect(
    postId: string,
    media_url: string,
    title: any,
    name: any
  ) {
    track("Post Click", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: {
        post_id: postId,
        media_url: media_url,
        title: title,
        name: name,
      },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    updateRefClick(postId, user_id);
  }

  function handleShop(
    postId: string,
    media_url: string,
    title: any,
    name: any
  ) {
    track("Post Click", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: {
        post_id: postId,
        media_url: media_url,
        title: title,
        name: name,
      },
      user_id: user_id,
      title: "Stay Up To Date",
    });
  }
  const renderComponent = (component: any) => {
    const {
      display_name: displayName,
      name,
      type,
      post_ids: postIds,
    } = component;
    if (dayMappings[displayName]) {
      const numberOfDays = daysSinceMultipleDays(dayMappings[displayName]);
      return (
        <YesterdayLinks
          key={component.id}
          user_id={user_id}
          username={username}
          numberOfDays={numberOfDays}
          title={name}
          isLinks={true}
          handleShop={handleShop}
          postIds={component?.post_ids}
          brand_color={brand_color}
          removedPostIds={component?.removed_postids}
        />
      );
    }
    switch (displayName) {
      case "Yesterday":
        return (
          <YesterdayLinks
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={[1]}
            title={name}
            isLinks={true}
            handleShop={handleShop}
            postIds={component?.post_ids}
            brand_color={brand_color}
            removedPostIds={component?.removed_postids}
          />
        );
      case "This Week":
        return (
          <YesterdayLinks
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={[7]}
            title={name}
            isLinks={true}
            handleShop={handleShop}
            postIds={component?.post_ids}
            brand_color={brand_color}
            removedPostIds={component?.removed_postids}
          />
        );

      case "My Links":
        return (
          <UpToDateLinks
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={name}
            handleShop={handleShop}
            brand_color={brand_color}
          />
        );
        case "Newsletter":
          return (
            <NewsletterData
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={name}
            postIds={postIds}
            isLinks={true}
            component={component}
            handleRedirect={handleRedirect}
            brand_color={brand_color}
          />
          );
      case "reels":
        return (
          <ReelsData
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={name}
            handleRedirect={handleRedirect}
            brand_color={brand_color}
          />
        );
      case "tiktok":
        return (
          <TiktokData
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={name}
            handleRedirect={handleRedirect}
            brand_color={brand_color}
          />
        );
      case "instagram story":
        return (
          <InstagramStoryData
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={15}
            title={name}
            handleShop={handleShop}
            brand_color={brand_color}
          />
        );
      case "podcast":
        return (
          <PodcastData
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={name}
            handleRedirect={handleRedirect}
            brand_color={brand_color}
          />
        );
      case "youtube":
        return (
          <YoutubeData
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={name}
            handleRedirect={handleRedirect}
            brand_color={brand_color}
          />
        );
      default:
        if (type === "content") {
          return (
            <CustomContentData
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={name}
              postIds={postIds}
              handleRedirect={handleRedirect}
              brand_color={brand_color}
            />
          );
        } else if (type === "query-content") {
          return (
            <QueryContentData
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={name}
              postIds={postIds}
              handleRedirect={handleRedirect}
              brand_color={brand_color}
              query={component?.query}
              removedPostIds={component?.removed_postids}
            />
          );
        } else if (type === "links") {
          return (
            <CustomLinkData
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={name}
              postIds={postIds}
              isLinks={true}
              handleShop={handleShop}
              brand_color={brand_color}
            />
          );
        } else if (type === "query-links") {
          return (
            <QueryLinkData
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={name}
              postIds={postIds}
              isLinks={true}
              handleShop={handleShop}
              brand_color={brand_color}
              query={component?.query}
              removedPostIds={component?.removed_postids}
            />
          );
        } else if (type === "product-bank") {
          return (
            <ProductBankData
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={name}
              postIds={postIds}
              isLinks={true}
              handleShop={handleShop}
              brand_color={brand_color}
            />
          );
        } else if (type === "banner") {
          return (
            <BannerData
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={name}
              postIds={postIds}
              isLinks={true}
              component={component}
              handleRedirect={handleRedirect}
              brand_color={brand_color}
            />
          );
        } else if (type === "highlight") {
          return (
            <HighlightDataSecton
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={name}
              postIds={postIds}
              data={component}
              handleRedirect={handleRedirect}
              handleShop={handleShop}
              brand_color={brand_color}
            />
          );
        }else if (type === "trending products") {
          return (
            <TrendingProductsData
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={name}
              postIds={postIds}
              isLinks={true}
              handleShop={handleShop}
              brand_color={brand_color}
              details={component?.details}
              removedPostIds={component?.removed_postids}

            />
          );
        }
        return null;
    }
  };

  return (
    <div className="uptodate-wrapper" style={{}}>
      {StayUpToDateComponentsLoading ? (
        <div className="loading-skeleton">
          <StayUpToDateSkeleton />
        </div>
      ) : (
        <>
         
          {StayUpToDateComponents &&
            StayUpToDateComponents.data.map((component: any) => {
              if (component?.status === "active") {
                return renderComponent(component);
              } else {
                return null; 
              }
            })}
        </>
      )}
    </div>
  );
};

export default StayUpToDate;
