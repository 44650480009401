import {
  Alert,
  Button,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  Spin,
} from "antd";
import React, { useState } from "react";

import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { storeTwitterUser } from "../../../Utils/SupabaseServices/ContentDatabase";

const TwitterIntegrationModal = (props: any) => {
  const { user } = useAuth();
  const { username } = useParams();
  const { showTwitterModal, user_id, data } = props;
  const [twitterUser, setTwitterUser] = useState(data?.twitter_user);
  const [errorTwitter, setErrorTwitter] = useState("");
  const [loadingTwitterConnect, setLoadingTwitterConnect] = useState(false);
  const [integrateTwitterMsg, setIntegrateTwitterMsg] = useState("");

  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  // const onChange = async (e: RadioChangeEvent) => {
  //   setValue(e.target.value);
  // };

  const handleOk = async () => {
    setShowIntegrationModal(false);
  };

  const handleCancel = () => {
    setShowIntegrationModal(false);
  };
  return (
    <>
      <Modal
        title="Connect to X"
        open={showTwitterModal}
        onOk={async () => {
          setLoadingTwitterConnect(true);

          const res = await storeTwitterUser(user?.id as string, twitterUser,username);
          if (!res) {
            setIntegrateTwitterMsg("");
            setErrorTwitter("error while connecting to this X user");
          } else {
            setIntegrateTwitterMsg(
              "Your X data will be integrated in your feed within 24 hours!"
            );
              props.setShowTwitterModal(false);
              setShowIntegrationModal(true)
          }

          setLoadingTwitterConnect(false);
        }}
        onCancel={() => {
          setErrorTwitter("");
          props.setShowTwitterModal(false);

        }}
        okText="Connect"
        okButtonProps={{ loading: loadingTwitterConnect }}
        cancelButtonProps={{ disabled: loadingTwitterConnect }}
      >
        <p>
          By connecting your X username, all your X data will be
          integrated and shown as posts in the feed of your main page. In the
          content tab you will be able to hide or delete the tweets you don't
          want to show.{" "}
        </p>
        {loadingTwitterConnect ? (
          <div
            className="spin-integration"
          >
            <Spin />
          </div>
        ) : (
          <>
            {errorTwitter && (
              <Alert
                type="error"
                message={`Error: ${errorTwitter}`}
                showIcon
                className="alert-margin"
              />
            )}
            {data?.twitter_user ? (
              <>
                <p>
                  <b>Already connected X username: {data?.twitter_user}</b>
                </p>
                <p>New name will connection will replace this one.</p>
              </>
            ) : (
              ""
            )}
            <p>To connect just enter your X username :</p>

            <Input
              placeholder="username"
              // value={TwitterId}
              onChange={(e) => {
                e.target.value.length
                  ? setTwitterUser(e.target.value)
                  : setErrorTwitter("Enter valid name");
              }}
            />
          </>
        )}
        {/* <Button type="primary" onClick={showModal}>
          Open Modal
        </Button> */}
      </Modal>
      <Modal
        title={twitterUser ? `${twitterUser}'s Tweets` : ""}
        open={showIntegrationModal}
        onOk={handleOk}
        okText="Ok"
        onCancel={handleCancel}
      >
        <>
          <p>{integrateTwitterMsg} </p>
        </>
      </Modal>
    </>
  );
};

export default TwitterIntegrationModal;
