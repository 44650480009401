export function StaticVariables() {
  const TITLE = "Bubble.";
  const BACK_BTN = "&#10140;";
  const MIN_RANGE = 5;
  const AUTH_LOGIN_TEXT = "Don't have a Bubble account?";
  const AUTH_LOGIN_LINK = "Create one!";
  const AUTH_SIGNUP_TEXT = "Already have a Bubble account?";
  const AUTH_SIGNUP_LINK = "Log in!";
  const AUTH_TERMS_TEXT =
    "By continuing, you agree to Bubbles's Terms of Service and Privacy Policy, and to receive periodic emails with updates.";
  const AUTH_TERMS_LINK = "Read the terms";
  const AUTH_LOGIN_HEADING = "Welcome back";
  const AUTH_LOGIN_MSG = "Sign in to your account";
  const AUTH_SIGNUP_HEADING = "Get started";
  const AUTH_SIGNUP_MSG = "Create a new account";
  const ACCOUNT_TITLE = "Account";
  const BILLING_TITLE = "Billing";
  const CONTENT_TITLE = "Content";
  const ANALYTICS_TITLE = "Analytics";
  const MANAGE_ALL_LINKS_TITLE = "All Links";
  const MANAGE_STAYUPTODATE_TITLE = "Stay Up To Date";
  const PRODUCT_BANK = "Product Bank";

  const OVERVIEW_HEADING = "Overview";
  const SEARCH_ORIGINATING_HEADING = "Searches Originating From";
  const CONTROLS_TITLE = "Controls";
  const STAGING_CONTROLS_TITLE = "Staging View";
  const ACTIVE_FEED_MSG =
    "Active content, if relevant, will be shown in search results while Archived content will never be shown in search results.";
  const ARCHIVE_FEED_MSG =
    "There are no Archived posts. All pieces of content are currently marked as Active which means they will show up in search results, if relevant.";
  const HOMEPAGE_IMAGES = [
    "https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/profile-picture/338441889_767732654637846_1626502643083092314_n.jpeg",
    "https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/homepage-images/Marvin-Jacob.jpg?t=2023-05-04T06%3A43%3A55.474Z",
    "https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/homepage-images/Lindsey-Huffman.jpg?t=2023-05-04T06%3A47%3A31.143Z",
    "https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/homepage-images/jayalekshman-sj-EbSUug_7kMI-unsplash.jpg?t=2023-05-04T06%3A51%3A58.929Z",
    "https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/profile-picture/354413476_962947541602179_8692387845536302535_n.jpeg",
    "https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/homepage-images/Johnny-Kyunghwo.jpg?t=2023-05-04T06%3A52%3A35.530Z",
    "https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/profile-picture/thebluewallstudio.jpeg",
  ];
  const SIGNING_UP_TEXTS = [
    "Setting up your account!",
    "Getting your Bubble account ready!",
    "Fetching data from Instagram!",
    "Finishing up!",
  ];
  const ROUTELIST_ITEMS = ["Account", "Analytics", "Controls", "Content","All Links","Stay Up To Date","Product Bank"];

  const SEARCH_EXCEPTIONS = ["rings","earrings","dress","dresses"];
  const instagram_CONTROLS = [
    "Show Top Hashtags",
    "Show Instagram Highlights",
    "Number of Top Hashtags",
  ];
  const TYPES=[
    "FEED",
    "REELS",
    "STORY",
    "HIGHLIGHTS",
    "PIN",
    "VIDEO",
    "PODCAST",
    "TIKTOK","MAIL","TWEET","PRODUCT"
    // "COMMENT"
  ];
  const PLATFORMS=[
    "instagram",
    "youtube",
    "tiktok",
    "podcast",
    "pinterest",
    "newsletter","twitter","LTK","Amazon","ShopMy",
    // "instaComment"
  ];
  const youtube_CONTROLS: any = [];
  const newsletter_CONTROLS: any = [];
  const podcast_CONTROLS: any = [];
  const tiktok_CONTROLS: any = [];
  const pinterest_CONTROLS: any = [];

  const instagram_ANALYTICS = ["Instagram Highlights","Top Hashtags"];
  const youtube_ANALYTICS: any = [];
  const newsletter_ANALYTICS: any = [];
  const podcast_ANALYTICS: any = [];
  const tiktok_ANALYTICS: any = [];
  const pinterest_ANALYTICS: any = [];
  const connectingWords = [
    "and",
    "or",
    "but",
    "if",
    "because",
    "although",
    "since",
    "while",
    "when",
    "after",
    "before",
    "as",
    "that",
    "which",
    "who",
    "whom",
    "whose",
    "where",
    "what",
    "how",
    "the",
    "an",
    "a",
    "in",
    "on",
    "at",
    "with",
    "by",
    "for",
    "of",
    "to",
    "from",
    "through",
    "into",
    "during",
    "within",
    "against",
    "between",
    "among",
    "under",
    "above",
    "below",
    "over",
    "throughout",
    "around",
    "beside",
    "beneath",
    "up",
    "down",
    "off",
    "on",
    "out",
    "over",
    "through",
    "together",
    "apart",
    "forward",
    "backward",
    "away",
    "back",
    "with",
    "without",
    "about",
    "for",
    "against",
    "like",
    "unlike",
    "as",
    "with",
    "without",
    "are",
    "is",
    "do",
    "you",
    "am",
    "I",
  ];
  const dayMappings: { [key: string]: number[] } = {
    Monday: [1],
    Tuesday: [2],
    Wednesday: [3],
    Thursday: [4],
    Friday: [5],
    Saturday: [6],
    Sunday: [0],
    "This Weekend": [6, 0],
    "Monday,Tuesday": [1, 2],
    "Monday,Wednesday": [1, 3],
    "Monday,Thursday": [1, 4],
    "Monday,Friday": [1, 5],
    "Monday,Saturday": [1, 6],
    "Monday,Sunday": [1, 0],
    "Monday,This Weekend": [1, 6, 0],
    "Tuesday,Wednesday": [2, 3],
    "Tuesday,Thursday": [2, 4],
    "Tuesday,Friday": [2, 5],
    "Tuesday,Saturday": [2, 6],
    "Tuesday,Sunday": [2, 0],
    "Tuesday,This Weekend": [2, 6, 0],
    "Wednesday,Thursday": [3, 4],
    "Wednesday,Friday": [3, 5],
    "Wednesday,Saturday": [3, 6],
    "Wednesday,Sunday": [3, 0],
    "Wednesday,This Weekend": [3, 6, 0],
    "Thursday,Friday": [4, 5],
    "Thursday,Saturday": [4, 6],
    "Thursday,Sunday": [4, 0],
    "Thursday,This Weekend": [4, 6, 0],
    "Friday,Saturday": [5, 6],
    "Friday,Sunday": [5, 0],
    "Friday,This Weekend": [5, 6, 0],
    "Saturday,Sunday": [6, 0],
    "Sunday,This Weekend":[6.0],
    "Saturday, This Weekend":[6,0],
    "Saturday,Sunday, This Weekend": [6, 0],

    "Monday,Tuesday,Wednesday": [1, 2, 3],
    "Monday,Tuesday,Thursday": [1, 2, 4],
    "Monday,Tuesday,Friday": [1, 2, 5],
    "Monday,Tuesday,Saturday": [1, 2, 6],
    "Monday,Tuesday,Sunday": [1, 2, 0],
    "Monday,Tuesday,This Weekend": [1, 2, 6, 0],
    "Monday,Wednesday,Thursday": [1, 3, 4],
    "Monday,Wednesday,Friday": [1, 3, 5],
    "Monday,Wednesday,Saturday": [1, 3, 6],
    "Monday,Wednesday,Sunday": [1, 3, 0],
    "Monday,Wednesday,This Weekend": [1, 3, 6, 0],
    "Monday,Thursday,Friday": [1, 4, 5],
    "Monday,Thursday,Saturday": [1, 4, 6],
    "Monday,Thursday,Sunday": [1, 4, 0],
    "Monday,Thursday,This Weekend": [1, 4, 6, 0],
    "Monday,Friday,Saturday": [1, 5, 6],
    "Monday,Friday,Sunday": [1, 5, 0],
    "Monday,Friday,This Weekend": [1, 5, 6, 0],
    "Monday,Saturday,Sunday": [1, 6, 0],
    "Tuesday,Wednesday,Thursday": [2, 3, 4],
    "Tuesday,Wednesday,Friday": [2, 3, 5],
    "Tuesday,Wednesday,Saturday": [2, 3, 6],
    "Tuesday,Wednesday,Sunday": [2, 3, 0],
    "Tuesday,Wednesday,This Weekend": [2, 3, 6, 0],
    "Tuesday,Thursday,Friday": [2, 4, 5],
    "Tuesday,Thursday,Saturday": [2, 4, 6],
    "Tuesday,Thursday,Sunday": [2, 4, 0],
    "Tuesday,Thursday,This Weekend": [2, 4, 6, 0],
    "Tuesday,Friday,Saturday": [2, 5, 6],
    "Tuesday,Friday,Sunday": [2, 5, 0],
    "Tuesday,Friday,This Weekend": [2, 5, 6, 0],
    "Tuesday,Saturday,Sunday": [2, 6, 0],
    "Wednesday,Thursday,Friday": [3, 4, 5],
    "Wednesday,Thursday,Saturday": [3, 4, 6],
    "Wednesday,Thursday,Sunday": [3, 4, 0],
    "Wednesday,Thursday,This Weekend": [3, 4, 6, 0],
    "Wednesday,Friday,Saturday": [3, 5, 6],
    "Wednesday,Friday,Sunday": [3, 5, 0],
    "Wednesday,Friday,This Weekend": [3, 5, 6, 0],
    "Wednesday,Saturday,Sunday": [3, 6, 0],
    "Thursday,Friday,Saturday": [4, 5, 6],
    "Thursday,Friday,Sunday": [4, 5, 0],
    "Thursday,Friday,This Weekend": [4, 5, 6, 0],
    "Thursday,Saturday,Sunday": [4, 6, 0],
    "Friday,Saturday,Sunday": [5, 6, 0],
  };

  return {
    TITLE,
    BACK_BTN,
    MIN_RANGE,
    AUTH_LOGIN_TEXT,
    AUTH_LOGIN_LINK,
    AUTH_SIGNUP_TEXT,
    AUTH_SIGNUP_LINK,
    AUTH_TERMS_TEXT,
    AUTH_TERMS_LINK,
    AUTH_LOGIN_HEADING,
    AUTH_LOGIN_MSG,
    AUTH_SIGNUP_HEADING,
    AUTH_SIGNUP_MSG,
    ACCOUNT_TITLE,
    PRODUCT_BANK,
    BILLING_TITLE,
    CONTENT_TITLE,
    ANALYTICS_TITLE,
    OVERVIEW_HEADING,
    SEARCH_ORIGINATING_HEADING,
    CONTROLS_TITLE,
    STAGING_CONTROLS_TITLE,
    HOMEPAGE_IMAGES,
    ARCHIVE_FEED_MSG,
    ACTIVE_FEED_MSG,
    SIGNING_UP_TEXTS,
    ROUTELIST_ITEMS,
    SEARCH_EXCEPTIONS,
    instagram_CONTROLS,
    youtube_CONTROLS,
    podcast_CONTROLS,
    tiktok_CONTROLS,
    pinterest_CONTROLS,
    newsletter_CONTROLS,
    instagram_ANALYTICS,
    youtube_ANALYTICS,
    podcast_ANALYTICS,
    tiktok_ANALYTICS,
    pinterest_ANALYTICS,
    newsletter_ANALYTICS,
    MANAGE_ALL_LINKS_TITLE,
    MANAGE_STAYUPTODATE_TITLE,
    TYPES,
    PLATFORMS,
    dayMappings,
    connectingWords
  };
}
