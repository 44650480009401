import { Button, Input, Modal, Upload, UploadProps } from "antd";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { addComponent } from "../../../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import { getMaxTrendingCount } from "../../../../../../../Utils/SupabaseServices/AnalyticsDatabse";
import dayjs from "dayjs";
import CustomDateRange from "../../../../../../../components/CustomDateRange/CustomDateRange";

const TrendingProductsComponent = (props: any) => {
  const {
    isTrendingProductsModalOpen,
    user_id,
    username,
    setIsShoplinksModalOpen,
  } = props;

  const name = "Trending Products";
  const cta = "Check it out!";
  const [clickNumber, setClickNumber] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [fileList, setFileList] = useState<any>([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<any>("");
  const [maxCount, setMaxCount] = useState<any>();
  const [isCustomDateModalOpen, setIsCustomDateModalOpen] = useState(false); // State for the custom date modal
  const [customStartDate, setCustomStartDate] = useState<any>("");
  const [customEndDate, setCustomEndDate] = useState<any>("");
  const [selectedDates, setSelectedDates] = useState<
    [dayjs.Dayjs, dayjs.Dayjs] | null
  >(null);

  useEffect(() => {
    fetchMaxCount();
  }, [clickNumber]);

  async function fetchMaxCount() {
    const res = await getMaxTrendingCount(username, user_id);
    setMaxCount(res);
  }

  const [activeTab, setActiveTab] = useState(0);
  const handleOk = async () => {
    let startDate: any;
    let endDate: any;

    if (activeTab !== 1 && activeTab !== 0) {
      endDate = dayjs().add(1, "day").format("YYYY-MM-DD");
      startDate = dayjs().subtract(activeTab, "day").format("YYYY-MM-DD");
    } else {
      if (activeTab === 0) {
        endDate = dayjs().add(1, "day").format("YYYY-MM-DD");
        startDate = dayjs().subtract(100000, "day").format("YYYY-MM-DD");
      } else {
        startDate = customStartDate.format("YYYY-MM-DD");
        endDate = customEndDate.add(1, "day").format("YYYY-MM-DD");
      }
    }

    setConfirmLoading(true);
    const res = await addComponent(
      name,
      `${clickNumber},${startDate},${endDate},${activeTab}`,
      username,
      user_id,
      "false",
      cta,
      "",
      name,
      "trending products"
    );
    if (res === "true") {
      props.refetch();
      props.setIsTrendingProductsModalOpen(false);
      setIsShoplinksModalOpen(false);

      setImageUrl("");
      setFileList([]);
      setErrorMsg("");
      setClickNumber("");
      setActiveTab(0);
      setCustomStartDate("");
      setCustomEndDate("");
      setSelectedDates(null);
      setConfirmLoading(false);
    } else {
      setErrorMsg("Banner with this name already exists.");
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    props.setIsTrendingProductsModalOpen(false);
    setIsShoplinksModalOpen(false);
    setClickNumber("");
    setImageUrl("");
    setFileList([]);
    setErrorMsg("");
  };

  const handlePrevious = () => {
    setIsShoplinksModalOpen(true);
    props.setIsTrendingProductsModalOpen(false);
    setClickNumber("");
    setImageUrl("");
    setFileList([]);
    setErrorMsg("");
  };

  const handleInputChange = (value: string, setter: Function) => {
    const numericValue = parseInt(value, 10);

    if (numericValue > maxCount) {
      setErrorMsg(`Number should not exceed ${maxCount}`);
    } else {
      setErrorMsg("");
      setIsButtonDisabled(!value);
    }
    setter(value);
  };

  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <button onClick={handlePrevious} className="newbutton">
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isTrendingProductsModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
        okButtonProps={{ disabled: !clickNumber || clickNumber > maxCount }}
      >
        <div>
          <h5 className="title mb-20">Create a Trending Product Carousel</h5>

          <label className="bold-label">Number of Clicks</label>

          <Input
            placeholder="Enter Number of Clicks"
            value={clickNumber}
            type="number"
            onChange={(e: any) => {
              handleInputChange(e.target.value, setClickNumber);
            }}
            className="mb-20"
          />

          {errorMsg && <p className="errorMsg">{errorMsg}</p>}
          <CustomDateRange
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setIsCustomDateModalOpen={setIsCustomDateModalOpen}
            isCustomDateModalOpen={isCustomDateModalOpen}
            setSelectedDates={setSelectedDates}
            setCustomStartDate={setCustomStartDate}
            setCustomEndDate={setCustomEndDate}
            selectedDates={selectedDates}
          />
        </div>
      </Modal>
    </>
  );
};

export default TrendingProductsComponent;
