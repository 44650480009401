import { Avatar, Card } from "antd";
import "./UpToDateLinks.css"; // Import CSS file for styling
import Meta from "antd/es/card/Meta";
import "./UpToDateLinks.css";
import { useLoadLinks } from "../../../../Utils/customHooks/useLoadLinks";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import track, {
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";
import MyLinksSkeleton from "../../MyLinksSkeleton";
const UpToDateLinks = (props: any) => {
  const location = useLocation();
  const { user_id, username, title, brand_color } = props;
  let {
    isLoading,

    data,

    refetch,
  } = useLoadLinks(user_id);
  function linkAnalytics(link: any, url: any) {
    track(`my links link click`, TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      user_id: user_id,
      details: { link: link, url: url },
      title: "Stay Up To Date",
    });
  }

  return (
    <>
      {isLoading ? (
        <div className="creator-card-grid-wrp mt-8">
          <MyLinksSkeleton />
        </div>
      ) : (
        <>
          <div
            className="mainDiv"
          >
            <p
              className="component-name"
            >
              {title}
            </p>
          </div>
          <div className="creator-card-grid-wrp">
            {data &&
              data.length > 0 &&
              data.split(",").map((link: any, index: number) => (
                <React.Fragment key={index}>
                  <CardGridItem
                    link={link}
                    username={username}
                    linkAnalytics={linkAnalytics}
                    brand_color={brand_color}
                  />
                </React.Fragment>
              ))}
          </div>
        </>
      )}
    </>
  );
};

const CardGridItem = ({ link, username, linkAnalytics, brand_color }: any) => (
  <a
    href={
      link?.split(":-")[1]?.startsWith("http")
        ? link?.split(":-")[1]
        : `http://${link?.split(":-")[1]}`
    }
    target="_blank"
    onClick={() => linkAnalytics(link?.split(":-")[0], link?.split(":-")[1])}
    className="card-link"
  >
    {link?.split(":-")[2] ? (
      <>
        <div
          className="creator-card"
          style={{ backgroundColor: brand_color && brand_color }}
        >
          <div className="creator-card-cover">
            <img
              className="creator-card-img"
              alt="example"
              src={link?.split(":-")[2]}
            />

            <div className="creator-card-meta">{link?.split(":-")[0]}</div>
          </div>
        </div>
      </>
    ) : (
      <div
        className="creator-card"
        style={{ backgroundColor: brand_color && brand_color, height: "100%" }}
      >
        {" "}
        <div className="creator-card-meta h-100">
          {link?.split(":-")[0]}
        </div>
      </div>
    )}
  </a>
);

export default UpToDateLinks;
