import Skeleton from "react-loading-skeleton";
import "./StatBox.css";

type Props = {
	name: string;
	icon: any;
	isLoading: boolean;
	number?: any;
	component?: any;
	classname?:any,
	brand_color?:any
};
function StatBox({ name, number, icon, isLoading, component,classname,brand_color }: Props) {
	return (
		<div className={classname?classname:'stat-box-wrapper'} style={{boxShadow:brand_color && `0 2.5px .25rem 0 ${brand_color}`}}>
			<div
				className="stat-box-div"
			>
				<p className='stat-box-title'>{name}</p>
				{icon}
			</div>
			{isLoading ? (
				<Skeleton />
			) : typeof number === "number" ? (
				<p className='stat-box-number'>{number}</p>
			) : (
				component
			)}
		</div>
	);
}

export default StatBox;
