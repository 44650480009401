import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomPagination from "../../CustomPagination";
import PuffLoader from "react-spinners/PuffLoader";
import "./PodcastData.css";
import { useLoadTypeContentData } from "../../../../Utils/customHooks/UpToDateContent/useLoadTypeContent";
import { BsHourglassSplit } from "react-icons/bs";
import Card from "react-bootstrap/esm/Card";
import DataSkeleton from "../DataSkeleton";
import { debounce } from "lodash";

const PodcastData = (props: any) => {
  let platform = "podcast";
  let type = "PODCAST";
  const itemsPerPage = 3;

  const { user_id, username, title, handleRedirect, brand_color } = props;
  const { isLoading: PodcastDataLoading, data: PodcastData }: any =
    useLoadTypeContentData(username, user_id, platform, type);

  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<any>(null);
  const isButtonClicked = useRef(false);

  // const handleScroll = () => {
  //   if (!isButtonClicked.current) {
  //     if (containerRef.current) {
  //       const scrollLeft = containerRef.current.scrollLeft;
  //       const itemWidth = containerRef.current.scrollWidth / PodcastData.length;
  //       const newCurrentIndex = Math.round(scrollLeft / itemWidth);
  //       setCurrentIndex(newCurrentIndex);
  //     }
  //   }
  // };
  const handleScroll = useCallback(
    debounce(() => {
      if (!isButtonClicked.current) {
        if (containerRef.current) {
          const scrollLeft = containerRef.current.scrollLeft;
          const itemWidth = containerRef.current.scrollWidth / PodcastData.length;
          const newCurrentIndex = Math.round(scrollLeft / itemWidth);
          setCurrentIndex(newCurrentIndex);
        }
      }
    }, 100),
    [PodcastData]
  );
  useEffect(() => {
    if (containerRef.current) {
      const itemWidth = containerRef.current.scrollWidth / PodcastData.length;
      const scrollPosition = itemWidth * currentIndex;
      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex, PodcastData]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [PodcastData,handleScroll]);

  const handlePrevPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNextPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, PodcastData.length - itemsPerPage)
    );
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };
  const ImagePostTag = React.memo((props: any) => {
    const { data } = props;
    const { thumbnail, media_url, permalink, caption, post_id } = data;

    return (
      <a
        href={permalink}
        target="_blank"
        rel="noopener noreferrer"
        className="podcast-link-tag"
        onClick={(e) => {
          e.preventDefault();
          handleRedirect(
            post_id,
            media_url.includes("video") ? thumbnail : media_url,
            "Podcast Content",
            caption
          );
          if (permalink && permalink !== "") {
            window.open(permalink, "_blank");
          }
        }}
      >
        <div className="creator-feed-card-container">
          {!media_url ? (
            <div className="creator-feed-card-podcast not-available">
              <BsHourglassSplit
                className="hourglassSplit" 
             />
              <p>
                Work in progress: This content will be available in a few
                minutes.
              </p>
            </div>
          ) : (
            <div
              className="creator-feed-image-wrapper-pod"
              style={{
                border: brand_color && `1px solid ${brand_color}`,
                backgroundColor: brand_color && brand_color,
              }}
            >
              <div className="creator-feed-image-wrapper-podcast">
                <Card.Img
                  variant="top"
                  src={media_url.includes("video") ? thumbnail : media_url}
                  className="creator-feed-card-podcast-image"
                  loading="eager"
                />
                <h4 className="caption">{caption}</h4>
              </div>
            </div>
          )}
        </div>
      </a>
    );
  });

  return (
    <div className="reels-wrapper">
      {PodcastDataLoading ? (
        <DataSkeleton />
      ) : (
        <>
          {PodcastData && PodcastData.length ? (
            <>
              <div
              className="mainDiv"
              >
                <p
                  className="component-name"
                >
                  {title}
                </p>
                <CustomPagination
                  totalItems={PodcastData?.length}
                  currentIndex={currentIndex}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
                />
              </div>
              <div
                className="outer-container-reels"
                ref={containerRef}
              >
                <div
                  className="links-grid-container-reels"
                >
                  {PodcastData &&
                    PodcastData.map((link: any, index: number) => (
                      <div
                        key={link.id}
                        className="link-url"
                      >
                        <ImagePostTag data={link} />
                      </div>
                    ))}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default PodcastData;
