import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ContentSkeletonTemplateWrapper = () => {
    const SkeletonStatBox = () => {
        return (
            
            <div style={{ marginBottom: '4%', marginRight: '2%', width: '150px', height: '250px' }}>
                <Skeleton
                    className='skeletonStyle'
                />
            </div>
        );
    }

    return (
       <div style={{ display: 'flex', flexDirection: 'row' }}>
            {[...Array(5)].map((_, index) => (
                <SkeletonStatBox key={index} />
            ))}
       </div>
    );
}

export default ContentSkeletonTemplateWrapper;
