import React, { useEffect, useRef, useState, useCallback } from "react";
import CustomPagination from "../../CustomPagination";
import PuffLoader from "react-spinners/PuffLoader";
import "./TiktokData.css";
import { useLoadTypeContentData } from "../../../../Utils/customHooks/UpToDateContent/useLoadTypeContent";
import { BsHourglassSplit } from "react-icons/bs";
import Card from "react-bootstrap/esm/Card";
import InfiniteScroll from "react-infinite-scroll-component";
import DataSkeleton from "../DataSkeleton";
import ImagePostCarouselTag from "../../../GeneralPostTag/ImagePostCarouselTag";
import VideoPostTag from "../../../GeneralPostTag/VideoPostTag";
import ImagePostTag from "../../../GeneralPostTag/ImagePostTag";
import debounce from 'lodash/debounce';

const TiktokData = (props: any) => {
  let platform = "tiktok";
  let type = "TIKTOK";
  const itemsPerPage = 3;

  const { user_id, username, handleRedirect, brand_color, title } = props;
  const { isLoading: TiktokDataLoading, data: TiktokData }: any =
    useLoadTypeContentData(username, user_id, platform, type);
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<any>(null);
  const isButtonClicked = useRef(false);

  const handleScroll = useCallback(
    debounce(() => {
      if (!isButtonClicked.current) {
        if (containerRef.current) {
          const scrollLeft = containerRef.current.scrollLeft;
          const itemWidth = containerRef.current.scrollWidth / TiktokData.length;
          const newCurrentIndex = Math.round(scrollLeft / itemWidth);
          setCurrentIndex(newCurrentIndex);
        }
      }
    }, 100),
    [TiktokData]
  );

  useEffect(() => {
    if (containerRef.current) {
      const itemWidth = containerRef.current.scrollWidth / TiktokData.length;
      const scrollPosition = itemWidth * currentIndex;
      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex, TiktokData]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [TiktokData, handleScroll]);

  const handlePrevPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNextPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, TiktokData.length - itemsPerPage)
    );
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  return (
    <>
      {TiktokDataLoading ? (
        <DataSkeleton />
      ) : (
        <>
          {TiktokData && TiktokData.length ? (
            <div>
              <div
                className="mainDiv"
              >
                <p
                  className="component-name"
                >
                  TikTok
                </p>
                <CustomPagination
                  totalItems={TiktokData?.length}
                  itemsPerPage={itemsPerPage}
                  currentIndex={currentIndex}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
                />
              </div>
              <div
                className="outer-container-reels"
                ref={containerRef}
              >
                <div
                  className="links-grid-container-reels"
                >
                  {TiktokData &&
                    TiktokData.map((link: any, index: number) => (
                      <div
                        key={link.id}
                        className="link-url"
                      >
                        {link.media_url ? (
                          link.media_url.split(",").length > 1 &&
                          link.media_url
                            .split(",")
                            .every((url: any) => url.trim() !== "") ? (
                            <>
                              <ImagePostCarouselTag
                                post={link}
                                handleRedirect={handleRedirect}
                                brand_color={brand_color}
                                isLinks={link?.shop_link ? true : false}
                                isDashboard={false}
                              />
                            </>
                          ) : (
                            <>
                             {link.product_image &&
                                link.product_image !== "" ? (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={handleRedirect}
                                      // title={title}
                                      brand_color={brand_color}
                                      isLinks={link?.shop_link?true:false}
                                      isDashboard={false}
                                    />
                                  </>
                                ) : (
                                  <>
                                    {link.media_url.includes("video") ? (
                                      <>
                                        <VideoPostTag
                                          post={link}
                                          handleRedirect={handleRedirect}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={link?.shop_link?true:false}
                                          isDashboard={false}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <ImagePostTag
                                          data={link}
                                          handleRedirect={handleRedirect}
                                          // title={title}
                                          brand_color={brand_color}
                                          isLinks={link?.shop_link?true:false}
                                          isDashboard={false}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                            </>
                          )
                        ) : (
                          <>
                            <ImagePostTag
                              data={link}
                              handleRedirect={handleRedirect}
                              brand_color={brand_color}
                              isLinks={link?.shop_link ? true : false}
                              isDashboard={false}
                            />
                          </>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default TiktokData;

