import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import HoverVideoPlayer from "react-hover-video-player";
import { BsFillPlayFill, BsHourglassSplit } from "react-icons/bs";
import "../StayUpToDateComponent/StayUpToDate.css"

const VideoPostTag = (props: any) => {
  const { post, title, handleRedirect, isLinks, brand_color, isDashboard } =
    props;
  const [showClickablePlayBtn, setShowClickablePlayBtn] = useState(false);
  useEffect(() => {
    if (
      navigator.userAgent.search("Safari") >= 0 &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      setShowClickablePlayBtn(true);
    }
  }, []);
  const OverlayStatus = ({
    hover,
    showClickablePlayBtn,
  }: {
    hover: boolean;
    showClickablePlayBtn: boolean;
  }) => {
    return (
      <>
        {!hover && <BsFillPlayFill className={"creator-feed-card-play-btn"} />}
      </>
    );
  };

  const VideoTag = (props: any) => {
    const videoRef: any = useRef();
    const [hover, setHover] = useState(false);
    const [customPause, setCustomPause] = useState(false);
    useEffect(() => {
      videoRef.current.playsInline = true;
    }, []);

    const {
      media_url,

      post,

      thumbnail,
      title,
    } = props;
    return (
      <>
        <a
          href={
            post?.shop_link && post?.shop_link !== ""
              ? post?.shop_link
              : post?.permalink? post?.permalink :post?.table_url
          }
          target="_blank"
          onClick={(e) => {
            e.preventDefault();
            props.handleRedirect(
              post?.post_id,
              post?.media_url.includes("video")
                ? post?.product_image
                  ? post?.product_image
                  : post?.thumbnail
                : post?.product_image
                ? post?.product_image
                : post?.media_url,
              title,
              post?.product_name
            );
            if (
              (post?.shop_link && post?.shop_link !== "") ||
              (post?.permalink && post?.permalink !== "") ||   (post?.table_url && post?.table_url !== "")
            ) {
              window.open(
                post?.shop_link && post?.shop_link !== ""
                  ? post?.shop_link
                  : post.permalink? post?.permalink :post?.table_url,
                "_blank"
              );
            }
          }}
        >
          {!Boolean(media_url) ? (
            <div>
              <BsHourglassSplit
                className="hourglassSplit"
              />
              <p>
                Work in progress: This content will be available in a few
                minutes.
              </p>
            </div>
          ) : (
            <HoverVideoPlayer
              videoRef={videoRef}
              videoSrc={media_url}
              muted
              className={"creator-feed-card-links-image hover-play"}
              style={{
                backgroundColor: brand_color,
                display: "block",
                marginBottom: "2%",
              }}
              preload={"metapost" || "auto"}
              contextMenu="return false;"
              hoverOverlay={
                <div
                  className="video-hover"
                ></div>
              }
              pausedOverlay={
                <div
                  style={{ position: "relative" }}
                  className={"creator-feed-card-links-image"}
                >
                  <img
                    src={thumbnail ? thumbnail : "/assets/dummy_video.png"}
                    alt=""
                    className={"creator-feed-card-links-image"}
                    loading="eager"
                  />

                  <OverlayStatus
                    hover={hover}
                    showClickablePlayBtn={showClickablePlayBtn}
                  />
                </div>
              }
              loadingOverlay={
                <img
                  src={thumbnail ? thumbnail : "/assets/dummy_video.png"}
                  alt=""
                  className={"creator-feed-card-links-image"}
                  loading="eager"
                />
              }
              unloadVideoOnPaused
              onHoverStart={() => {
                setHover(true);
              }}
              onHoverEnd={() => {
                setHover(false);
                setCustomPause(false);
              }}
              disableDefaultEventHandling={customPause}
            />
          )}
          {post?.product_name && (
            <p
              className="product-name"
            >
              {post?.product_name}
            </p>
          )}
          {isLinks && (
            <Button className={"template-shop-btn-links"}>Shop</Button>
          )}
        </a>
      </>
    );
  };

  return (
    <>
      <div className={"creator-feed-image-wrapper-links"}>
        <VideoTag
          media_url={post?.media_url}
          post={post}
          thumbnail={
            post.thumbnail ? post.thumbnail : "/assets/dummy_video.png"
          }
          handleRedirect={handleRedirect}
          title={title}
        />
      </div>
    </>
  );
};

export default VideoPostTag;
