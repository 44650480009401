import { Button, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { supabase } from "../../../config/supabaseClient";
import TextArea from "antd/es/input/TextArea";

const ManageCaptionModal = (props: any) => {
  const { selectedPost, user_id, username, captionModal } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [newCaption, setNewCaption] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  useEffect(() => {
    setNewCaption(selectedPost?.caption);
  }, [selectedPost]);
  async function handleCaptionOk() {
    setConfirmLoading(true);
    const updatedPost = { ...selectedPost, caption: newCaption };

    await Promise.all([
      supabase
        .from("content_new")
        .update({ caption: newCaption })
        .eq("post_id", selectedPost.post_id)
        .eq("user_id", user_id),

      supabase
        .from(username)
        .update({ caption: newCaption })
        .eq("post_id", selectedPost.post_id)
        .eq("user_id", user_id),
    ]);
    
    const {data}=await supabase.from("content_new").select('*') .eq("post_id", selectedPost.post_id)
    .eq("user_id", user_id)
    if(data && data.length){
      let combined =
      (data[0].caption ? data[0].caption : "") +
      " " +
      (data[0].ocr ? data[0].ocr : "") +
      " " +
      (data[0].content_category ? data[0].content_category : "")+
      " " +
      (data[0].tags ? data[0].tags : "")+
      " " +
      (data[0].tagged_link ? "instagram.com/"+""+data[0].tagged_link : "");

    }
  
    props.setSelectedPost(updatedPost);
    props.setCaptionModal(false);
    props.refetchData();

    setConfirmLoading(false);
    setNewCaption("");
    setIsEdit(false);
  }

  function handleCaptionCancel() {
    props.setCaptionModal(false);
    setNewCaption("");
    setIsEdit(false);
  }

  return (
    <Modal
      // title="Manage Caption"
      visible={captionModal}
      onOk={handleCaptionOk}
      onCancel={handleCaptionCancel}
      confirmLoading={confirmLoading}
      footer={[
        // <Button key="back" onClick={handleCaptionCancel}>
        //   Cancel
        // </Button>,
        <Button key="submit" type="primary" onClick={handleCaptionOk}>
          Save
        </Button>,
      ]}
    >
      {selectedPost && (
        <>
          <h5 className="text-center mb-4">
            Manage Caption
          </h5>
            <label className="bold-label">
              Caption: 
            </label>
                <TextArea
                  value={newCaption}
                  onChange={(e: any) => {
                    setNewCaption(e.target.value);
                  }}
                  placeholder="enter caption here"
                  autoSize={{
                    minRows: 3,
                    maxRows: 5,
                  }}
                  // className="text-area"
                />
        </>
      )}
    </Modal>
  );
};

export default ManageCaptionModal;
