import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomPagination from "../../CustomPagination";
import PuffLoader from "react-spinners/PuffLoader";
import "./CustomLinkData.css";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import { useLoadCustomContent } from "../../../../Utils/customHooks/UpToDateContent/useLoadCustomContent";
import { BsHourglassSplit } from "react-icons/bs";
import Card from "react-bootstrap/esm/Card";
import { Button } from "antd";
import DataSkeleton from "../DataSkeleton";
import ImagePostCarouselTag from "../../../GeneralPostTag/ImagePostCarouselTag";
import VideoPostTag from "../../../GeneralPostTag/VideoPostTag";
import ImagePostTag from "../../../GeneralPostTag/ImagePostTag";
import { debounce } from "lodash";

const CustomLinkData = (props: any) => {

  const itemsPerPage = 3;

  const {
    user_id,
    username,
    postIds,
    title,
    isLinks,
    handleShop,
    brand_color,
  } = props;
  const { isLoading: CustomLinkDataLoading, data: CustomLinkData }: any =
    useLoadCustomContent(username, user_id, postIds,title);
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<any>(null);
  const isButtonClicked = useRef(false);

  const handleScroll = useCallback(
    debounce(() => {
      if (!isButtonClicked.current) {
        if (containerRef.current) {
          const scrollLeft = containerRef.current.scrollLeft;
          const itemWidth = containerRef.current.scrollWidth / CustomLinkData.length;
          const newCurrentIndex = Math.round(scrollLeft / itemWidth);
          setCurrentIndex(newCurrentIndex);
        }
      }
    }, 100),
    [CustomLinkData]
  );

  useEffect(() => {
    if (containerRef.current) {
      const itemWidth =
        containerRef.current.scrollWidth / CustomLinkData.length;
      const scrollPosition = itemWidth * currentIndex;
      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex, CustomLinkData]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [CustomLinkData,handleScroll]);

  const handlePrevPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNextPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, CustomLinkData.length - itemsPerPage)
    );
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  return (
    <div>
      {CustomLinkDataLoading ? (
        <DataSkeleton />
      ) : (
        <>
          {CustomLinkData && CustomLinkData.length ? (
            <div>
              <div className="mainDiv">
                <p className="component-name">
                  {title}
                </p>
                <CustomPagination
                  totalItems={CustomLinkData?.length}
                  currentIndex={currentIndex}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
                />
              </div>
              <div
                className="outer-container-reels"
                ref={containerRef}
              >
                <div
                  className="links-grid-container-reels"
                >
                  {CustomLinkData &&
                    CustomLinkData.map((link: any, index: number) => (
                      <div
                        key={link.id}
                        className="link-url"
                      >
                        <>
                          {link.media_url ? (
                            link.media_url.split(",").length > 1 &&
                            link.media_url
                              .split(",")
                              .every((url: any) => url.trim() !== "") ? (
                              <>
                                <ImagePostCarouselTag
                                  post={link}
                                  handleRedirect={handleShop}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={isLinks}
                                  isDashboard={false}
                                />
                              </>
                            ) : (
                              <>
                                {link.product_image &&
                                link.product_image !== "" ? (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={handleShop}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={isLinks}
                                      isDashboard={false}
                                    />
                                  </>
                                ) : (
                                  <>
                                    {link.media_url.includes("video") ? (
                                      <>
                                        <VideoPostTag
                                          post={link}
                                          handleRedirect={handleShop}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={isLinks}
                                          isDashboard={false}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <ImagePostTag
                                          data={link}
                                          handleRedirect={handleShop}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={isLinks}
                                          isDashboard={false}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                                {/* {link.media_url.includes("video") ? (
                                  <>
                                    <VideoPostTag
                                      post={link}
                                      handleRedirect={handleShop}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={isLinks}
                                      isDashboard={false}

                                    />
                                  </>
                                ) : (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={handleShop}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={isLinks}
                                      isDashboard={false}

                                    />
                                  </>
                                )} */}
                              </>
                            )
                          ) : (
                            <>
                              <ImagePostTag
                                data={link}
                                handleRedirect={handleShop}
                                title={title}
                                brand_color={brand_color}
                                isLinks={isLinks}
                                isDashboard={false}
                              />
                            </>
                          )}
                        </>
                        {/* <ImagePostTag data={link} /> */}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default CustomLinkData;
