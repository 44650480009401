import { Pagination, Tabs } from "antd";
import React, { useState } from "react";
import SearchInput from "../../../components/SearchInput/SearchInput";
import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { useLoadProductBankPages } from "../../../Utils/customHooks/UpToDateContent/useLoadProductBankCount";
import { useLoadProductBankContent } from "../../../Utils/customHooks/UpToDateContent/useLoadProductBankConent";
import { ContentSkeleton } from "../../../components/skeleton/DashboardView/feedSkeleton";
import ProductBankSearchTable from "./ProductBankSearchTable";
import { StaticVariables } from "../../../Utils/StaticVariables";

const POSTS_PER_PAGE = 10;

const ProductBank = (props: any) => {
  const { user } = useAuth();
  const { subscription_type } = props;

  const { username } = useParams();
  const user_id = user?.id || "";
  const [activeKey, setActiveKey] = useState<any>("Product Bank");
  const [isLimitError, setLimitError] = useState(false);
  const [query, setQuery] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const { isLoading: pagesLoading, data: pages, refetch: refetchCount } = useLoadProductBankPages(
    user_id || "",

    POSTS_PER_PAGE,
    query,
    username || ""
  );
  const { data, isLoading, isError, error, refetch } =
    useLoadProductBankContent(
      user_id || "",
      username || "",
      currentPage,
      POSTS_PER_PAGE,
      pages?.totalPages,
      query
    );
  const handlePageDataChange = (page: any) => {
    setCurrentPage(page);
  };

  return (

  <div className="d-side">
    <div className="details">
      <h1 className="dashboard-mobile-hide" style={{ marginBottom: "20px" }}>
        {StaticVariables().PRODUCT_BANK}
      </h1>
      <div style={{ margin: "0 0 40px 0" }}>

        <SearchInput
          placeholder={`Search all product bank content`}
          id="search-feed"
          handleKeyUp={(e: any) => {
            e.preventDefault();
            if (e.keyCode === 13) {
              const elem: any = document.getElementById("search-feed");
              const searchVal = e.target.value;
              if (searchVal.length === 0) {
                elem.blur();
                setCurrentPage(1);

                setQuery(null);
                return;
              }
              elem.blur();
              setQuery(searchVal);
            }
          }}
          isActiveFilter={Boolean(query)}
          onSearchClick={() => {
            const elem: any = document.getElementById("search-feed");
            const searchVal = elem.value;
            if (searchVal.length === 0) {
              return;
            }
            elem.blur();
            setQuery(searchVal);
            setCurrentPage(1);

          }}
          onClearClick={() => {
            const elem: any = document.getElementById("search-feed");
            elem.value = "";
            setQuery(null);
            setCurrentPage(1);

          }}
        />
      </div>

      {pagesLoading || isLoading ? (
        // <PuffLoader
        <ContentSkeleton />
      ) : (
        <>
          <ProductBankSearchTable
            username={username}
            user_id={user_id}
            activeKey={activeKey}
            data={data?.pages?.[0]}
            setLimitError={setLimitError}
            filter={query}
            refetchData={refetch}
            currentPage={currentPage}
          // setIsContentLoading={setIsContentLoading}
          // subscription_type={subscription_type}
          />
          <Pagination
            showQuickJumper
            current={currentPage}
            pageSize={POSTS_PER_PAGE}
            total={pages?.feedCount || 0}
            onChange={handlePageDataChange}
            showSizeChanger={false}
            style={{ margin: "10px", display: "block", textAlign: "center" }}
          />
        </>
      )}
    </div>
  </div>
  );
};

export default ProductBank;
