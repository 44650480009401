import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomPagination from "../../CustomPagination";
import LinksTemplate from "../../LinksTemplate";
import Spinner from "react-bootstrap/esm/Spinner";
import PuffLoader from "react-spinners/PuffLoader";
import "./QueryContentData.css";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import DataSkeleton from "../DataSkeleton";
import ImagePostTag from "../../../GeneralPostTag/ImagePostTag";
import ImagePostCarouselTag from "../../../GeneralPostTag/ImagePostCarouselTag";
import VideoPostTag from "../../../GeneralPostTag/VideoPostTag";
import { useLoadQueryFeed } from "../../../../Utils/customHooks/UpToDateContent/useLoadQueryFeed";
import { debounce } from "lodash";
const QueryContentData = (props: any) => {

  const itemsPerPage = 3;

  const { user_id, username, postIds, title, brand_color,query,removedPostIds } =
    props;
    const isShopLinks = false;
    const showSocials = StaticVariables().PLATFORMS;
    const contentOrder = "Default";
  
    const {
      isLoading,
      data,
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
      refetch,
    }: any = useLoadQueryFeed(
      username,
      user_id,
      query,
  
      showSocials,
      contentOrder,
      isShopLinks,
      removedPostIds

    );
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<any>(null);
  const isButtonClicked = useRef(false);

  // const handleScroll = () => {
  //   if (!isButtonClicked.current) {
  //     if (containerRef.current) {
  //       const scrollLeft = containerRef.current.scrollLeft;
  //       const itemWidth =
  //         containerRef.current.scrollWidth / data.length;
  //       const newCurrentIndex = Math.round(scrollLeft / itemWidth);
  //       setCurrentIndex(newCurrentIndex);
  //     }
  //   }
  // };
  const handleScroll = useCallback(
    debounce(() => {
      if (!isButtonClicked.current) {
        if (containerRef.current) {
          const scrollLeft = containerRef.current.scrollLeft;
          const itemWidth = containerRef.current.scrollWidth / data.length;
          const newCurrentIndex = Math.round(scrollLeft / itemWidth);
          setCurrentIndex(newCurrentIndex);
        }
      }
    }, 100),
    [data]
  );
  useEffect(() => {
    if (containerRef.current) {
      const itemWidth =
        containerRef.current.scrollWidth / data.length;
      const scrollPosition = itemWidth * currentIndex;
      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex, data]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [data,handleScroll]);

  const handlePrevPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNextPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, data.length - itemsPerPage)
    );
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

 return (
    <div>
      {isLoading ? (
        <DataSkeleton />
      ) : (
        <>
          {data && data.length ? (
            <div>
              <div
                className="mainDiv"
              >
        <p
          className="component-name"
        >
          {title}
        </p>
                <CustomPagination
                  totalItems={data?.length}
                  currentIndex={currentIndex}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
                  s
                />
              </div>
              <div
                className="outer-container-reels"
                ref={containerRef}
              >
                <div
                  className="links-grid-container-reels"
                >
                  {data &&
                    data.map((link: any, index: number) => (
                      <div
                        key={link.id}
                        className="link-url"
                      >
                        <>
                          {link.media_url ? (
                            link.media_url.split(",").length > 1 &&
                            link.media_url
                              .split(",")
                              .every((url: any) => url.trim() !== "") ? (
                              <>
                                <ImagePostCarouselTag
                                  post={link}
                                  handleRedirect={props.handleRedirect}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={false}
                                  isDashboard={false}
                                  
                                />
                              </>
                            ) : (
                              <>
                               {link.product_image &&
                                link.product_image !== "" ? (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={props.handleRedirect}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={false}
                                      isDashboard={false}
                                    />
                                  </>
                                ) : (
                                  <>
                                    {link.media_url.includes("video") ? (
                                      <>
                                        <VideoPostTag
                                          post={link}
                                          handleRedirect={props.handleRedirect}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={false}
                                          isDashboard={false}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <ImagePostTag
                                          data={link}
                                          handleRedirect={props.handleRedirect}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={false}
                                          isDashboard={false}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                                {/* {link.media_url.includes("video") ? (
                                  <>
                                    <VideoPostTag
                                      post={link}
                                      handleRedirect={props.handleRedirect}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={false}
                                      isDashboard={false}

                                    />
                                  </>
                                ) : (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={props.handleRedirect}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={false}
                                      isDashboard={false}

                                    />
                                  </>
                                )} */}
                              </>
                            )
                          ) : (
                            <>
                              <ImagePostTag
                                data={link}
                                handleRedirect={props.handleRedirect}
                                title={title}
                                brand_color={brand_color}
                                isLinks={false}
                                isDashboard={false}

                              />
                            </>
                          )}
                        </>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}{" "}
        </>
      )}
    </div>
  );
};

export default QueryContentData;
