import { useState } from "react";
import { useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import { StaticVariables } from "../../../Utils/StaticVariables";

/**
 *  Use this hook to control feed pagination
 * @param userId
 * @param index - "active" || "archived"
 * @param postsPerPage
 * @returns
 */
export function useLoadPages(
  userIds: any,
  index: string,
  postsPerPage: number,
  filter: any,
  usernames: any
) {
  async function getCount() {
    let feedCount;
    let postIds: any = [];

    if (filter) {
      const elementsCount = 5000;
      const connectingWords = StaticVariables().connectingWords


      let words = filter.split(" ");
      let singleWords: any = [];
      if (
        words.length === 1 &&
        !StaticVariables().SEARCH_EXCEPTIONS.includes(filter)
      ) {
        const suffixRegex = /(s)$/i;
        if (suffixRegex.test(words[0])) {
         
          words=[`${words[0].replace(suffixRegex, "")}`];

        }
      }

      if(words.includes("shop") || words.includes("buy")){
        // contentOrder="shoppable"

        words = words.filter((word:any) => word !== "shop" && word !== "buy");
      }
   

      const filteredWords = words.filter(
        (word: any) => !connectingWords.includes(word.toLowerCase())
      );
      if (words.length <= 2) {
        const result = filteredWords.join(" ");
        const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");

        const { data: countResult, error: countError }: any =
          await supabase.rpc("semantic_ssearchcountftspostiddi", {
            query_string: pgQuery,
            similarity_threshold: 0.8,
            match_count: elementsCount,
            userid: userIds,
            username: usernames,
            socials: StaticVariables().PLATFORMS,
            single: singleWords[0] || null,
            plural: singleWords[1] || null,
            index_type:index
          });

        // let resCount = countResult.length;
        const { data, error } = await supabase.rpc("get_productbankcountti", {
          query_string: filter || "",
          userid: userIds,
          query_tagg: filter,
          index_type:index
        });

        let resCount = countResult.length + data?.[0]?.count;
        postIds = countResult.map((item: any) => item.post_id);
        feedCount = resCount;
      } else {
        feedCount = 0;
      }

    } else {
      if (index === "active,ad") {
        const { data: count, error } = await supabase.rpc(
          "get_dashboardadscontenttcount",
          {
            userid: userIds,

            user_name: usernames,
            index_type: index,
          }
        );
        feedCount = count?.[0].count;
      } else {
        const { data: content, error: contentError } = await supabase.rpc(
          "get_generalfffeedcounti",
          {
            userid: userIds,
            filter: StaticVariables().TYPES,
            query_str: "",
            user_name: usernames,
            socials: StaticVariables().PLATFORMS,
            index_type: index,
          }
        );
        feedCount = content[0].count;
      }
    }

    return {
      totalPages: Math.ceil(feedCount / postsPerPage),
      feedCount: feedCount,
      postIds: postIds || null,
    };
  }

  return useQuery(["paginatedPosts", filter, userIds, index], getCount, {});
}
