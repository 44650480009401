import React from "react";
import SocialLinks from "../../../../components/SocialLinks/SocialLinks";
import ActiveSocialLinks from "../../../../components/SocialLinks/ActiveSocialLinks";

const SocialLinksComponent = () => {
  return (
    <div style={{ marginTop: "8%" }}>
       <div className="account-values-profiles">
        <div className="account-social-logins-heading">Active Social Links Preview</div>
        <ActiveSocialLinks />
      </div>
      <div className="account-values-profiles">
        <div className="account-social-logins-heading">Available Links</div>
        <SocialLinks />
      </div>
    </div>
  );
};

export default SocialLinksComponent;
