import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginSocialFacebook } from "reactjs-social-login";
import { useEffect, useState } from "react";
import {
  BsCaretDownFill,
  BsCaretLeftFill,
  BsChevronRight,
  BsFacebook,
  BsInstagram,
  BsYoutube,
} from "react-icons/bs";
import track, { TRACKING_TYPES } from "../../Utils/SupabaseServices/Track";
import AuthPagesHeader from "../../components/Header/AuthPagesHeader";
import GoogleAuthButton from "./GoogleAuthButton";
import { Alert, Input, Progress } from "antd";
import { storeYoutubeVideosByChannelId } from "../../Utils/backendServices/youtube";
import { useAuth } from "../../hooks/useAuth";
import { storeUserControls } from "../../Utils/SupabaseServices/ControlsDatabase";
import { supabase } from "../../config/supabaseClient";
import {
  activateUser,
  storeDirectInstagramUser,
} from "../../Utils/SupabaseServices/UserDatabase";
const { Search } = Input;

export default function AuthPlatforms({
  handleFacebookAuthentication,
}: {
  handleFacebookAuthentication: (
    token: string,
    id: string,
    profiles: any
  ) => void;
}) {
  const navigate = useNavigate();
  const { user, username } = useAuth();
  const user_id = user?.id || "";
  const location = useLocation();
  let userProfiles: any = [];
  const facebookDomain = process.env.REACT_APP_FACEBOOK_DOMAIN;
  const [activeTab, setActiveTab] = useState("");
  const [errorUsername, setErrorUsername] = useState("");
  const [errorChannelId, setErrorChannelId] = useState("");
  const [showChannelIdInstructions, setShowChannelIdInstructions] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [progressLoading, setProgressLoading] = useState(0);

  useEffect(() => {
    if (progressLoading > 0 && progressLoading <= 96) {
      setTimeout(() => {
        setProgressLoading(progressLoading + 4);
      }, 1000);
    }
  }, [progressLoading]);

 

  useEffect(() => {
    track(location.pathname, TRACKING_TYPES.PAGE, {
      pathname: location.pathname,
      user_id: user_id,
    });
  }, []);

  const responseFacebook = (response: any) => {
    // token: facebook session. we store it as "provider_token" in the user table
    // id: unique facebook user ID. we store it as "provider_id" in the user table
    const { accessToken, id } = response.data;
    // TODO - before fetching, we need to check if the provider_id is already in the database. This way we prevent having the same facebook account on different users.
    fetchFeed(id, accessToken);
  };

  async function fetchFeed(id: any, token: any) {
    const associatedResponseResult: any = await axios.get(
      `${facebookDomain}/${id}/accounts?access_token=${token}`
    );

    let associatedPagesArr: any[] = [];
    associatedResponseResult.data.data.map((response: any) => {
      associatedPagesArr.push(response);
    });

    let promises: any = [];
    associatedPagesArr.map(function (obj) {
      promises.push(businessAccount(obj.id, token));
    });
    Promise.all(promises).then((result) => {
      let finalList: any = [];
      result.map((e) => {
        if (e.username) {
          finalList.push(e);
        }
      });

      userProfiles = [...finalList];

      handleFacebookAuthentication(token, id, userProfiles);
    });
  }

  let businessAccount = function (id: any, token: any) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${facebookDomain}/${id}?fields=instagram_business_account%7Busername%2Cname%2Cprofile_picture_url%2Cig_id%7D&access_token=${token}`
        )
        .then(
          function (results) {
            if (
              results &&
              results.data &&
              results.data.instagram_business_account &&
              results.data.instagram_business_account.username
            ) {
              return resolve(results.data.instagram_business_account);
            } else {
              return resolve({});
            }
          },
          (err) => {
            return resolve({});
          }
        );
    });
  };

  const onSearch = async (value: string) => {
    if (value === "") {
      return;
    }
    setLoading(true);
    setProgressLoading(4);
    const url = `https://dev.thesearchbubble.com/createTable`;
    await axios.post(url, {
      id: user_id,
      user: username,
    });
    const resApi = await storeYoutubeVideosByChannelId(user_id, value,username);
    if (resApi.error) {
      setErrorChannelId(resApi.message);
      setLoading(false);
      setProgressLoading(0);
    } else {
      setProgressLoading(99);
      await storeUserControls(username, 0);
      await supabase.auth.updateUser({
        data: { isOnboardingFinished: true },
      });
      await activateUser(user_id);
      // navigate(`/creator/${username}`);
      // navigate(`/follower/${username}`);
      navigate(`/dashboard/${username}`);

    }
  };

  const onUsernameSearch = async (value: string) => {
    if (value === "") {
      return;
    }
    setLoading(true);
    setProgressLoading(4);
    const resApi = await storeDirectInstagramUser(user_id, value);

    if (!resApi) {
      setErrorUsername("Check the username");
      setLoading(false);
      setProgressLoading(0);
    } else {
      setProgressLoading(99);
      await storeUserControls(user_id, 0);
      await supabase
        .from("user")
        .update({ is_username_signup: true })
        .eq("user_id", user_id);

      navigate(`/loading/${username}`);
    }
    // if (resApi.error) {

    // } else {
    //
    //

    // }
  };

  return (
    <div className="auth-phone-signup-container" style={{ padding: "4%" }}>
      <AuthPagesHeader />
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            margin: "auto 0",
          }}
        >
          <Progress
            type="circle"
            percent={progressLoading}
            status="active"
            className="white-progress"
          />
          <p
            style={{
              marginTop: "30px",
              fontSize: "1rem",
              textAlign: "center",
            }}
          >
            Setting up your account, don't refresh the page...
          </p>
        </div>
      ) : (
        <div className="auth-signup-details" style={{ marginTop: "2rem" }}>
          <p className="auth-signup-details-title">One more step</p>
          <p
            className="auth-signup-details-subtitle"
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              marginBottom: "3rem",
            }}
          >
            Connect your first platform
          </p>
          <div
            className={`auth-signup-tabs-platforms ${
              activeTab === "instagram" ? "active" : ""
            }`}
            onClick={() => {
              if (activeTab === "instagram") {
                setActiveTab("");
              } else {
                setActiveTab("instagram");
              }
            }}
          >
            <p className="auth-signup-tab-title">
              <BsInstagram className="auth-signup-tab-icon" /> Instagram
            </p>
            {activeTab === "instagram" ? (
              <BsCaretLeftFill style={{ fontSize: "1.2rem" }} />
            ) : (
              <BsCaretDownFill style={{ fontSize: "1.2rem" }} />
            )}
          </div>
          {activeTab === "instagram" && (
            <div className="auth-signup-tab-wrp">
              <p
                className="auth-signup-details-subtitle"
                style={{ marginBottom: "1rem" }}
              >
                We need your facebook authorization to get your posts
              </p>
              <LoginSocialFacebook
                scope="instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement"
                appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}
                fieldsProfile={"id,name"}
                onResolve={responseFacebook}
                onReject={(err) => {
                }}
                auth_type="rerequest"
                className="auth-facebook-btn"
              >
                <BsFacebook style={{ fontSize: "20px", marginRight: "20px" }} />
                <p style={{ margin: 0 }}>Continue With Facebook</p>
              </LoginSocialFacebook>
            
            </div>
          )}
          <div
            className={`auth-signup-tabs-platforms ${
              activeTab === "youtube" ? "active" : ""
            }`}
            onClick={() => {
              if (activeTab === "youtube") {
                setActiveTab("");
              } else {
                setActiveTab("youtube");
              }
            }}
          >
            <p className="auth-signup-tab-title">
              <BsYoutube className="auth-signup-tab-icon" /> Youtube
            </p>
            {activeTab === "youtube" ? (
              <BsCaretLeftFill style={{ fontSize: "1.2rem" }} />
            ) : (
              <BsCaretDownFill style={{ fontSize: "1.2rem" }} />
            )}
          </div>
          {activeTab === "youtube" && (
            <div className="auth-signup-tab-wrp">
              <p
                className="auth-signup-details-subtitle"
                style={{ marginBottom: "1rem" }}
              >
                We need your Google authorization to get your videos
              </p>
              <GoogleAuthButton />

              <hr style={{ width: "100%", margin: "40px 0 0 0", zIndex: 5 }} />
              <p
                style={{
                  width: "40px",
                  margin: "0 auto",
                  marginTop: "10px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                Or
              </p>
              {errorChannelId && (
                <Alert
                  type="error"
                  message={`Error: ${errorChannelId}`}
                  showIcon
                  style={{ margin: "10px 0" }}
                />
              )}

              <p>Enter your Channel ID:</p>
              <Search
                placeholder="Channel ID"
                allowClear
                enterButton="Sumbit"
                size="large"
                onSearch={onSearch}
                className="auth-signup-channelId-btn"
              />
              {!showChannelIdInstructions ? (
                <p
                  className="auth-signup-tab-channelid-text"
                  onClick={() => setShowChannelIdInstructions(true)}
                >
                  Find Your Channel ID
                </p>
              ) : (
                <div className="account-social-content-channelid-ins">
                  <p>
                    You can see your channel ID in your advanced{" "}
                    <a
                      href="http://www.youtube.com/account_advanced"
                      className="auth-signup-tab-channelid-text"
                      target="_blank"
                      rel="noreferrer"
                    >
                      account settings
                    </a>
                  </p>
                  <ol>
                    <li>Sign in to YouTube</li>
                    <li>
                      In the top right, click your <b>profile picture</b>{" "}
                      <BsChevronRight style={{ margin: "0 6px 0 0" }} />
                      <b>Settings</b>
                    </li>
                    <li>
                      From the left menu, select <b>Advanced settings</b>
                    </li>
                    <li>
                      You’ll see your channel’s user and channel ID, copy your{" "}
                      <b>channel ID</b>
                    </li>
                  </ol>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
