import { Button, Carousel, Input, List, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import "../../StayUpToDateControls.css";
import { updateComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import Skeleton from "react-loading-skeleton";
import { supabase } from "../../../../../config/supabaseClient";
import { HiCheck, HiPencil } from "react-icons/hi";
import { useLoadYesterdayLinks } from "../../../../../Utils/customHooks/UpToDateContent/useLoadYesterdayLinks";
import dayjs from "dayjs";
import { StaticVariables } from "../../../../../Utils/StaticVariables";
import VideoComponent from "../../../../../components/VideoComponent/VideoComponent";
import RemovedContentTable from "../../../../../components/EditModalComponents.tsx/RemovedContentTable";

const EditYesterdayLinks = (props: any) => {
  const {
    username,
    user_id,
    isYesterdayLinksEditModalOpen,
    setIsYesterdayLinksEditModalOpen,
    type,
    component,
  } = props;
  const [name, setName] = useState(component.name || ""); // const [details, setDetails] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage button disable state
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [removePostIds, setRemovePostIds] = useState<any>("");
  useEffect(() => {
    setPostIds(component?.post_ids);
    setName(component?.name);
    setDefaultQuery(component?.query);
    setRemovePostIds(component?.removed_postids);
  }, [component]);
  const handleInputChange = (value: string, setter: Function) => {
    setter(value); // Set the input value
    setIsButtonDisabled(!value); // Disable button if either name or details is empty
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await updateComponent(
      name,
      component,
      username,
      user_id,
      defaultQuery,
      component?.type,
      "",
      "",
      removePostIds
    );
    if (res === "true") {
      props.refetch();

      props.setIsYesterdayLinksEditModalOpen(false);
      props.setSelectedComponent("");
      setConfirmLoading(false);

      setName("");
      setPostIds("");
      setErrorMsg("");
      setDefaultQuery("");
      setRemovePostIds("");
    } else {
      setErrorMsg("Category with this name already exists");
      setConfirmLoading(false);

    }

    // props.setIsCustomLinksComponentOpen(false);
  };

  const handleCancel = () => {
    props.setIsYesterdayLinksEditModalOpen(false);
    props.setSelectedComponent("");

    // props.setIsCustomLinksComponentOpen(false);
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    setRemovePostIds("");
  };

  // function handleRemovedPostIds(post_ids: any) {
  //   setRemovePostIds((prev: any) => {
  //     const idsArray = prev.split(",").filter(Boolean);
  //     idsArray.push(post_ids);
  //     return idsArray.join(","); // Convert array back to string
  //   });
  //   // setRemovePostIds((prev: any) => (prev ? [...prev, post_ids] : [post_ids]));
  //   // setPostIds(post_ids);
  // }
  function handleRemovedPostIds(post_ids: any, type: any) {
    if (type === "minus") {
      setRemovePostIds((prev: any) => {
        const idsArray = prev?.split(",").filter(Boolean);
        idsArray.push(post_ids);
        return idsArray.join(","); // Convert array back to string
      });
    } else if (type === "add") {
      setRemovePostIds((prev: any) => {
        const idsArray = prev.split(",").filter(Boolean);
        const filteredArray = idsArray.filter((elem: any) => elem !== post_ids);

        return filteredArray.join(","); // Convert array back to string
      });
    }
  }

  return (
    <>
      <Modal
        open={isYesterdayLinksEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !name }}

      >
        <div className="mt-1">
          <h5
            className="editTitle"
          >
            Editing {name}
          </h5>
          <label
             className="bold-label"
          >
            Carousel Title
          </label>
          <Input
            placeholder="Enter Title"
            value={name}
            onChange={(e: any) => {
              handleInputChange(e.target.value, setName);
              setErrorMsg("");
            }}
            className="mb-15"
          />
          {errorMsg && <p className="errorMsg">{errorMsg}</p>}
          {component?.display_name && component?.display_name !== "" && (
            <p>
              {component?.display_name &&
                component.display_name.split(",").join(" & ")}
              's Shoppable Content
            </p>
          )}
          <AddedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={props.refetch}
            removePostIds={removePostIds}
            setRemovePostIds={setRemovePostIds}
            type={component?.type}
            displayName={component?.display_name}
            // yesterdayLinksData={yesterdayLinksData}
            // refetchLinks={refetchLinks}
            // yesterdayLinksLoading={yesterdayLinksLoading}
          />
          <RemovedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={props.refetch}
            removePostIds={removePostIds}
            setRemovePostIds={setRemovePostIds}
            type={component?.type}
            displayName={component?.display_name}
            // yesterdayLinksData={yesterdayLinksData}
            // refetchLinks={refetchLinks}
            // yesterdayLinksLoading={yesterdayLinksLoading}
          />
        </div>
      </Modal>
    </>
  );
};
const AddedContentTable = (props: any) => {
  const {
    username,
    user_id,
    post_ids,
    componentName,
    refetchDashboard,
    setRemovePostIds,
    type,
    displayName,
    removePostIds,
    // yesterdayLinksData,
    // refetchLinks,
    // yesterdayLinksLoading
  } = props;
  const [postIds, setPostIds] = useState(post_ids);
  const [deletePostIds, setDeletePostIds] = useState(removePostIds);
  useEffect(() => {
    setDeletePostIds(removePostIds);
  }, [removePostIds]);
  const daysSinceMultipleDays = (targetDaysOfWeek: number[]) => {
    const currentDayOfWeek = dayjs().day();
    const daysSinceTargetDays = targetDaysOfWeek?.map(
      (targetDayOfWeek) => (currentDayOfWeek - targetDayOfWeek + 7) % 7
    );

    // return Math.min(...daysSinceTargetDays);
    return daysSinceTargetDays;
  };
  let numberOfDays;
  if (displayName === "This Week") {
    numberOfDays = [7];
  } else if (displayName === "Yesterday") {
    numberOfDays = [1];
  } else {
    const dayMappings = StaticVariables().dayMappings;
    numberOfDays = daysSinceMultipleDays(dayMappings[displayName]);
  }
  let {
    isLoading: yesterdayLinksLoading,
    data: yesterdayLinksData,
    refetch,
  }: any = useLoadYesterdayLinks(
    username,
    user_id,
    numberOfDays,
    postIds,
    deletePostIds
  );
  const onMinus = (post_id: string) => {
    setDeletePostIds((prev: any) => {
      const idsArray = prev.split(",").filter(Boolean);
      idsArray.push(post_id);
      return idsArray.join(","); // Convert array back to string
    });
    props.handleRemovedPostIds(post_id, "minus");
    refetch();
  };
  const [value, setValue] = useState<string>();
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");

  const handleDone = async (post_id: string) => {
    const { data: user, error } = await supabase
      .from(`${username}`)
      .select("*")
      .eq("post_id", post_id)
      .eq("user_id", user_id);

    const { data } = await supabase
      .from(`${username}`)
      .update({
        product_name: value,
        combined_text: user?.[0].combined_text + " " + `|${value}|`,
      })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    const { data: newContent, error: newErroor } = await supabase
      .from("content_new")
      .update({
        product_name: value,
        combined_text: user?.[0].combined_text + " " + `|${value}|`,
      })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
      const { data: bankContent, error: bankErroor } = await supabase
      .from("product_bank")
      .update({
        name: value,
        // combined_text: user?.[0]?.combined_text + " " + `|${value}|`,
      })
      .eq("product_id", post_id)
      .eq("user_id", user_id);
    setEdit(false);
    setEditIndex("");
    refetch();
    // PostIdDataRefetch();
    refetchDashboard();
  };
  
  const filteredData = useMemo(() => {
    return (yesterdayLinksData || [])
      .filter((element: any) => element !== undefined)
      .filter((obj: any) => {
        const deleteIdsArray = deletePostIds?.split(",") || [];
        return !deleteIdsArray.includes(obj.post_id);
      });
  }, [yesterdayLinksData, deletePostIds]);

  const hasFilteredData = filteredData.length > 0;
  const SkeletonStatBox = () => {
    return (
      <div
        className="skeletonDiv"
      >
        <Skeleton
          className="skeletonStyle"
        />
      </div>
    );
  };

  // useEffect(()=>{
  //   refetchLinks();
  // },[deletePostIds])
  return (
    <>
      <div
        className="addedContent"
      >
        <h5
          className="addedContentTitle"
        >
          {componentName}
        </h5>
        {!yesterdayLinksLoading ? (
          <div
            className="addedSvg"
          >
            {hasFilteredData ? (
              // yesterdayLinksData && yesterdayLinksData
              //   .filter((element: any) => element !== undefined)
              //   .filter((obj: any) => !deletePostIds?.includes(obj.post_id))
              //   .length
              filteredData
                // yesterdayLinksData
                //   .filter((element: any) => element !== undefined)
                //   .filter((obj: any) => !deletePostIds?.includes(obj.post_id))
                .map((post: any, index: any) => (
                  <React.Fragment key={post.post_id}>
                    {/* <div
                key={index}
                className="feed-tab-post-container"
                style={{
                  flex: "0 0 auto",
                  marginRight: "0px",
                  width: "180px", // Set a fixed width for the posts
                }}
              > */}
                    <div
                      key={index}
                      className="feed-tab-post-container added-post"
                    >
                      <div
                        className="feed-tab-media-wrapper"
                        style={
                          post?.platform === "newsletter"
                            ? {
                                backgroundColor: "#efeded",
                                textAlign: "center",
                              }
                            : {}
                        }
                      >
                        {/* <FaMinusCircle onClick={() => onMinus(post?.post_id)} /> */}

                        <Button
                          onClick={() => onMinus(post.post_id)}
                          className="carousel-content-remove-btn"
                        >
                          Remove
                        </Button>

                        {post?.platform !== "newsletter" ? (
                          <>
                            {post?.media_url ? (
                              <>
                                {post?.media_url.split(",").length > 1 &&
                                post?.media_url
                                  .split(",")
                                  .every((url: any) => url.trim() !== "") ? (
                                  <Carousel
                                    dotPosition="bottom"
                                    className="feed-tab-media-carousel"
                                  >
                                    {post?.media_url
                                      .split(",")
                                      .map((media_url: any, index: any) => (
                                        <React.Fragment key={index}>
                                          {media_url.includes("video") ? (
                                            // <video
                                            //   style={{
                                            //     maxHeight: "300px",
                                            //   }}
                                            //   className="feed-tab-media-check"
                                            //   autoPlay
                                            //   playsInline
                                            //   muted
                                            //   loop
                                            //   poster={
                                            //     post?.thumbnail &&
                                            //     post?.thumbnail.split(",")
                                            //       .length &&
                                            //     post?.thumbnail.split(",")[
                                            //       index
                                            //     ]
                                            //       ? post?.thumbnail.split(",")[
                                            //           index
                                            //         ]
                                            //       : "/assets/dummy_video.png"
                                            //   }
                                            // >
                                            //   <source src={media_url} />
                                            // </video>
                                            <div className="h-255">
                                              <VideoComponent
                                                media_url={media_url}
                                                thumbnail={
                                                  post.thumbnail &&
                                                  post.thumbnail.split(",")
                                                    .length &&
                                                  post.thumbnail.split(",")[
                                                    index
                                                  ]
                                                    ? post.thumbnail.split(",")[
                                                        index
                                                      ]
                                                    : "/assets/dummy_video.png"
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <img
                                              className="feed-tab-media-check mh-300"
                                              src={media_url}
                                              alt="post"
                                            />
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </Carousel>
                                ) : (
                                  <>
                                    {post.media_url.includes("video") ? (
                                      // <video
                                      //   style={{
                                      //     maxHeight: "300px",
                                      //     whiteSpace: "nowrap",
                                      //     overflowY: "scroll",
                                      //     borderRadius: "1rem",
                                      //   }}
                                      //   className="feed-tab-media-check"
                                      //   autoPlay
                                      //   playsInline
                                      //   muted
                                      //   loop
                                      //   poster={
                                      //     post.thumbnail
                                      //       ? post.thumbnail
                                      //       : "/assets/dummy_video.png"
                                      //   }
                                      // >
                                      //   <source src={post.media_url} />
                                      // </video>
                                      <div className="h-255">
                                        <VideoComponent
                                          media_url={post.media_url}
                                          thumbnail={
                                            post.thumbnail 
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <img
                                        className="feed-tab-media-check"
                                        src={
                                          post.media_url
                                            ? post.media_url
                                            : "/assets/dummy_video.png"
                                        }
                                        alt="post"
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <img
                                className="feed-tab-media-check"
                                src={
                                  post?.thumbnail
                                    ? post?.thumbnail
                                    : "/assets/dummy_video.png"
                                }
                                alt="post"
                              />
                            )}
                          </>
                        ) : (
                          <div>
                            <h4 className="mt-7">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(post?.post_date))}
                            </h4>
                            <h4> Newsletter</h4>
                          </div>
                        )}
                      </div>
                      {edit === true && editIndex === index ? (
                        <>
                          <Input
                            value={value}
                            onChange={(e: any) => setValue(e.target.value)}
                          />{" "}
                          <HiCheck
                            onClick={() => {
                              handleDone(post.post_id);
                            }}
                          />
                        </>
                      ) : (
                        <p
                          style={{
                            textAlign:
                              post.product_name !== "" ? "left" : "center",
                          }}
                        >
                          {post?.product_name}{" "}
                          <HiPencil
                            onClick={() => {
                              setValue(post.product_name);
                              setEdit(true);
                              setEditIndex(index);
                            }}
                          />
                        </p>
                      )}
                    </div>
                  </React.Fragment>
                ))
            ) : (
              <React.Fragment>
                {/* <p>No Added Data</p> */}
                <>
                  <div
                    // key={index}
                    // className="feed-tab-post-container"
                    className="feed-tab-post"
                  >
                    {[...Array(4)].map((_, index) => (
                      <SkeletonStatBox key={index} />
                    ))}
                  </div>
                </>
              </React.Fragment>
            )}
          </div>
        ) : (
          <>
            <React.Fragment>
              {/* <p>No Added Data</p> */}
              <>
                <div
                  // key={index}
                  // className="feed-tab-post-container"
                  className="feed-tab-post"
                >
                  {[...Array(4)].map((_, index) => (
                    <SkeletonStatBox key={index} />
                  ))}
                </div>
              </>
            </React.Fragment>
          </>
        )}
      </div>
    </>
  );
};
// const RemovedContentTable = (props: any) => {
//   const {
//     username,
//     user_id,
//     post_ids,
//     componentName,
//     refetchDashboard,
//     setRemovePostIds,
//     type,
//     displayName,
//     removePostIds,
//     contentData,
//     refetchLinks,
//   } = props;
//   const [postIds, setPostIds] = useState(post_ids);
//   const [deletePostIds, setDeletePostIds] = useState(removePostIds);
//   useEffect(() => {
//     // setPostIds(post_ids);
//     setDeletePostIds(removePostIds);
//   }, [removePostIds]);
//   let data: any = [];

//   const onAdd = (post_id: string) => {
//     setDeletePostIds((prev: any) => {
//       const idsArray = prev.split(",").filter(Boolean);
//       const filteredArray = idsArray.filter((elem: any) => elem !== post_id);

//       return filteredArray.join(","); // Convert array back to string
//     });

//     props.handleRemovedPostIds(post_id, "add");
//     // refetchLinks();
//   };
//   const {
//     isLoading: PostIdFeedLoading,
//     data: PostIdData,

//     refetch: PostIdDataRefetch,
//   }: any = useLoadPostIdsData(
//     username,
//     user_id,

//     deletePostIds?.split(",")
//   );
//   const [value, setValue] = useState<string>();
//   const [edit, setEdit] = useState(false);
//   const [editIndex, setEditIndex] = useState("");

//   const handleDone = async (post_id: string) => {
//     const { data: user, error } = await supabase
//       .from(`${username}`)
//       .update({ product_name: value })
//       .eq("post_id", post_id)
//       .eq("user_id", user_id);
//     const { data: newContent, error: newErroor } = await supabase
//       .from("content_new")
//       .update({ product_name: value })
//       .eq("post_id", post_id)
//       .eq("user_id", user_id);

//     setEdit(false);
//     setEditIndex("");
//     refetchLinks();
//     // PostIdDataRefetch();
//     refetchDashboard();
//   };

//   const SkeletonStatBox = () => {
//     return (
//       <div
//         style={{
//           margin: "0 auto",
//           // marginTop: "2%",
//           marginBottom: "2%",

//           width: "100%",
//           height: "160px",
//           marginRight: "4%",
//         }}
//       >
//         <Skeleton
//           style={{
//             width: "100%",
//             height: "100%",
//             cursor: "pointer",
//             borderRadius: "15px",
//             padding: "2%",
//           }}
//         />
//       </div>
//     );
//   };
//   return (
//     <>
//       <div
//         style={{
//           // border: "1.5px solid #c9ccd1",
//           marginBottom: "5%",
//           // maxHeight: "300px",
//           padding: "2%",
//           borderRadius: "5px",
//         }}
//       >
//         <h5
//           style={{
//             fontWeight: "500",
//             color: "grey",
//           }}
//         >
//           Removed Content
//         </h5>

//         {PostIdData?.filter((element: any) => element !== undefined) ? (
//           <div
//             style={{
//               overflowX: "auto",
//               whiteSpace: "nowrap",
//             }}
//             className="addedSvg"
//           >
//             {
//               // .filter(
//               //   (obj: any) => !deletePostIds?.includes(obj.post_id)
//               // )
//               PostIdData.filter((element: any) => element !== undefined)
//                 .length ? (
//                 PostIdData.filter((element: any) => element !== undefined)
//                   // .filter((obj: any) => !deletePostIds?.includes(obj.post_id))
//                   .map((post: any, index: any) => (
//                     <React.Fragment key={index}>
//                       {/* <div
//       key={index}
//       className="feed-tab-post-container"
//       style={{
//         flex: "0 0 auto",
//         marginRight: "0px",
//         width: "180px", // Set a fixed width for the posts
//       }}
//     > */}
//                       <div
//                         key={index}
//                         className="feed-tab-post-container"
//                         style={{
//                           // maxHeight: "250px",
//                           display: "inline-block",
//                           marginRight: "10px", // Add spacing between posts
//                           width: "140px", // Set a fixed width for the posts
//                           verticalAlign: "top", // Align posts at the top of the container
//                         }}
//                       >
//                         <div
//                           className="feed-tab-media-wrapper"
//                           style={
//                             post?.platform === "newsletter"
//                               ? {
//                                   backgroundColor: "#efeded",
//                                   textAlign: "center",
//                                 }
//                               : {}
//                           }
//                         >
//                           {/* <FaMinusCircle onClick={() => onMinus(post?.post_id)} /> */}

//                           {/* <Button
//                 onClick={() => onMinus(post.post_id)}
//                 className="carousel-content-remove-btn"
//               >
//                 Remove
//               </Button> */}
//                           <Button
//                             onClick={() => onAdd(post.post_id)}
//                             className="carousel-content-add-btn"
//                           >
//                             Add
//                           </Button>

//                           {post?.platform !== "newsletter" ? (
//                             <>
//                               {post?.media_url ? (
//                                 <>
//                                   {post?.media_url.split(",").length > 1 &&
//                                   post?.media_url
//                                     .split(",")
//                                     .every((url: any) => url.trim() !== "") ? (
//                                     <Carousel
//                                       dotPosition="bottom"
//                                       className="feed-tab-media-carousel"
//                                     >
//                                       {post?.media_url
//                                         .split(",")
//                                         .map((media_url: any, index: any) => (
//                                           <React.Fragment key={index}>
//                                             {media_url.includes("video") ? (
//                                               // <video
//                                               //   style={{
//                                               //     maxHeight: "300px",
//                                               //   }}
//                                               //   className="feed-tab-media"
//                                               //   autoPlay
//                                               //   playsInline
//                                               //   muted
//                                               //   loop
//                                               //   poster={
//                                               //     post?.thumbnail &&
//                                               //     post?.thumbnail.split(",")
//                                               //       .length &&
//                                               //     post?.thumbnail.split(",")[
//                                               //       index
//                                               //     ]
//                                               //       ? post?.thumbnail.split(
//                                               //           ","
//                                               //         )[index]
//                                               //       : "/assets/dummy_video.png"
//                                               //   }
//                                               // >
//                                               //   <source src={media_url} />
//                                               // </video>
//                                               <div className="h-255">
//                                                 <VideoComponent
//                                                   media_url={media_url}
//                                                   thumbnail={
//                                                     post.thumbnail &&
//                                                     post.thumbnail.split(",")
//                                                       .length &&
//                                                     post.thumbnail.split(",")[
//                                                       index
//                                                     ]
//                                                       ? post.thumbnail.split(
//                                                           ","
//                                                         )[index]
//                                                       : "/assets/dummy_video.png"
//                                                   }
//                                                 />
//                                               </div>
//                                             ) : (
//                                               <img
//                                                 style={{
//                                                   maxHeight: "300px",
//                                                 }}
//                                                 className="feed-tab-media-check"
//                                                 src={media_url}
//                                                 alt="post"
//                                               />
//                                             )}
//                                           </React.Fragment>
//                                         ))}
//                                     </Carousel>
//                                   ) : (
//                                     <>
//                                       {post.media_url.includes("video") ? (
//                                         // <video
//                                         //   style={{
//                                         //     maxHeight: "300px",
//                                         //     whiteSpace: "nowrap",
//                                         //     overflowY: "scroll",
//                                         //     borderRadius: "1rem",
//                                         //   }}
//                                         //   className="feed-tab-media-check"
//                                         //   autoPlay
//                                         //   playsInline
//                                         //   muted
//                                         //   loop
//                                         //   poster={
//                                         //     post.thumbnail
//                                         //       ? post.thumbnail
//                                         //       : "/assets/dummy_video.png"
//                                         //   }
//                                         // >
//                                         //   <source src={post.media_url} />
//                                         // </video>
//                                         <div className="h-255">
//                                           <VideoComponent
//                                             media_url={post.media_url}
//                                             thumbnail={
//                                               post.thumbnail 
//                                             }
//                                           />
//                                         </div>
//                                       ) : (
//                                         <img
//                                           className="feed-tab-media-check"
//                                           src={
//                                             post.media_url
//                                               ? post.media_url
//                                               : "/assets/dummy_video.png"
//                                           }
//                                           alt="post"
//                                         />
//                                       )}
//                                     </>
//                                   )}
//                                 </>
//                               ) : (
//                                 <img
//                                   className="feed-tab-media-check"
//                                   src={
//                                     post?.thumbnail
//                                       ? post?.thumbnail
//                                       : "/assets/dummy_video.png"
//                                   }
//                                   alt="post"
//                                 />
//                               )}
//                             </>
//                           ) : (
//                             <div>
//                               <h4 style={{ marginTop: "7.5%" }}>
//                                 {new Intl.DateTimeFormat("en-US", {
//                                   year: "numeric",
//                                   day: "numeric",
//                                   month: "long",
//                                 }).format(new Date(post?.post_date))}
//                               </h4>
//                               <h4> Newsletter</h4>
//                             </div>
//                           )}
//                         </div>
//                         {edit === true && editIndex === index ? (
//                           <>
//                             <Input
//                               value={value}
//                               onChange={(e: any) => setValue(e.target.value)}
//                             />{" "}
//                             <HiCheck
//                               onClick={() => {
//                                 handleDone(post.post_id);
//                               }}
//                             />
//                           </>
//                         ) : (
//                           <p
//                             style={{
//                               textAlign:
//                                 post.product_name !== "" ? "left" : "center",
//                             }}
//                           >
//                             {post?.product_name}{" "}
//                             <HiPencil
//                               onClick={() => {
//                                 setValue(post.product_name);
//                                 setEdit(true);
//                                 setEditIndex(index);
//                               }}
//                             />
//                           </p>
//                         )}
//                       </div>
//                     </React.Fragment>
//                   ))
//               ) : (
//                 <React.Fragment>
//                   {/* <p>No Added Data</p> */}
//                   <>
//                     <div
//                       // key={index}
//                       // className="feed-tab-post-container"
//                       className="skeletonLoader"
//                     >
//                       {[...Array(4)].map((_, index) => (
//                         <SkeletonStatBox key={index} />
//                       ))}
//                     </div>
//                   </>
//                 </React.Fragment>
//               )
//             }
//           </div>
//         ) : (
//           <></>
//         )}
//       </div>
//     </>
//   );
// };
export default EditYesterdayLinks;
