import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";

export function useLoadProductBankContent(
  user_id: string,
  username: any,
  currentPage: any,
  postsPerPage: any,
  totalPages: any,
  query:any
) {
  const fetchProductBankPosts = async () => {
    const start = (currentPage - 1) * postsPerPage;
    const end = start + postsPerPage - 1;
    // const input=query?.split(" ").map((elem:any)=>(`%${elem}%`))
    const input = query ? query.split(" ").map((elem: any) => `%${elem}%`) : [];

      const { data, error } = await supabase.rpc("get_productbank_postss", {

      query_string: input,
      userid: user_id,

      start_number: start,
      end_number: end,
      query_tagg : input
    });
    if (error) {
      throw new Error(error.message);
    }
    const seen = new Set();
    const uniquePosts = data.filter((post:any) => {
      const duplicate = seen.has(post.table_id);
      seen.add(post.table_id);
      return !duplicate;
    });
    return uniquePosts;
    // return data;
  };

  return useInfiniteQuery(
    ["ProductBankPosts", currentPage,query],
    fetchProductBankPosts,
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < totalPages) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      enabled: Boolean(totalPages),
    }
  );
}
