import React, { useCallback, useEffect, useRef, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import "./ReelsData.css";
import { useLoadTypeContentData } from "../../../../Utils/customHooks/UpToDateContent/useLoadTypeContent";
import { BsHourglassSplit } from "react-icons/bs";
import Card from "react-bootstrap/esm/Card";
import CustomPagination from "../../CustomPagination";
import DataSkeleton from "../DataSkeleton";
import ImagePostCarouselTag from "../../../GeneralPostTag/ImagePostCarouselTag";
import VideoPostTag from "../../../GeneralPostTag/VideoPostTag";
import ImagePostTag from "../../../GeneralPostTag/ImagePostTag";
import { debounce } from "lodash";

const ReelsData = (props: any) => {
  const { user_id, username, handleRedirect, brand_color ,title} = props;
  const platform = "instagram";
  const type = "REELS";
  const itemsPerPage = 3;

  const { isLoading: ReelsDataLoading, data: ReelsData }: any =
    useLoadTypeContentData(username, user_id, platform, type);

  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<any>(null);
  const isButtonClicked = useRef(false);

  // const handleScroll = () => {
  //   if (!isButtonClicked.current) {
  //     if (containerRef.current) {
  //       const scrollLeft = containerRef.current.scrollLeft;
  //       const itemWidth = containerRef.current.scrollWidth / ReelsData.length;
  //       const newCurrentIndex = Math.round(scrollLeft / itemWidth);
  //       setCurrentIndex(newCurrentIndex);
  //     }
  //   }
  // };
  const handleScroll = useCallback(
    debounce(() => {
      if (!isButtonClicked.current) {
        if (containerRef.current) {
          const scrollLeft = containerRef.current.scrollLeft;
          const itemWidth = containerRef.current.scrollWidth / ReelsData.length;
          const newCurrentIndex = Math.round(scrollLeft / itemWidth);
          setCurrentIndex(newCurrentIndex);
        }
      }
    }, 100),
    [ReelsData]
  );
  
  useEffect(() => {
    if (containerRef.current) {
      const itemWidth = containerRef.current.scrollWidth / ReelsData.length;
      const scrollPosition = itemWidth * currentIndex;
      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex, ReelsData]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [ReelsData,handleScroll]);

  const handlePrevPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNextPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, ReelsData.length - itemsPerPage)
    );
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };
return (
    <>
      {ReelsDataLoading ? (
        <DataSkeleton />
      ) : (
        <>
          {ReelsData && ReelsData.length ? (
            <div className="reels-wrapper">
              <div
                className="mainDiv"
              >
        <p
          className="component-name"
        >
                  Latest Reels
                </p>
                <CustomPagination
                  totalItems={ReelsData?.length}
                  currentIndex={currentIndex}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
                />
              </div>
              <div
                className="outer-container-reels"
                style={{ whiteSpace: "nowrap" }}
                ref={containerRef}
              >
                <div
                  className="links-grid-container-reels"
                  style={{ display: "flex" }}
                >
                  {ReelsData &&
                    ReelsData.map((link: any, index: number) => (
                      <div
                        key={link.id}
                        className="link-url"
                      >
                        {link.media_url ? (
                          link.media_url.split(",").length > 1 &&
                          link.media_url
                            .split(",")
                            .every((url: any) => url.trim() !== "") ? (
                            <>
                              <ImagePostCarouselTag
                                post={link}
                                handleRedirect={handleRedirect}
                                brand_color={brand_color}
                                isLinks={link?.shop_link ? true : false}
                                isDashboard={false}
                              />
                            </>
                          ) : (
                            <>
                             {link.product_image &&
                                link.product_image !== "" ? (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={handleRedirect}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={link?.shop_link?true:false}
                                      isDashboard={false}
                                    />
                                  </>
                                ) : (
                                  <>
                                    {link.media_url.includes("video") ? (
                                      <>
                                        <VideoPostTag
                                          post={link}
                                          handleRedirect={handleRedirect}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={link?.shop_link?true:false}
                                          isDashboard={false}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <ImagePostTag
                                          data={link}
                                          handleRedirect={handleRedirect}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={link?.shop_link?true:false}
                                          isDashboard={false}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                              {/* {link.media_url.includes("video") ? (
                                <>
                                  <VideoPostTag
                                    post={link}
                                    handleRedirect={handleRedirect}
                                    brand_color={brand_color}
                                    isLinks={link?.shop_link ? true : false}
                                    isDashboard={false}
                                  />
                                </>
                              ) : (
                                <>
                                  <ImagePostTag
                                    data={link}
                                    handleRedirect={handleRedirect}
                                    brand_color={brand_color}
                                    isLinks={link?.shop_link ? true : false}
                                    isDashboard={false}
                                  />
                                </>
                              )} */}
                            </>
                          )
                        ) : (
                          <>
                            <ImagePostTag
                              data={link}
                              handleRedirect={handleRedirect}
                              brand_color={brand_color}
                              isLinks={link?.shop_link ? true : false}
                              isDashboard={false}
                            />
                          </>
                        )}
                        {/* <ImagePostTag data={link} /> */}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default ReelsData;
