import { Button, Carousel, Input, List, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import "../../StayUpToDateControls.css";
import { updateComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import Skeleton from "react-loading-skeleton";
import { supabase } from "../../../../../config/supabaseClient";
import { HiCheck, HiPencil } from "react-icons/hi";
import { StaticVariables } from "../../../../../Utils/StaticVariables";
import { useLoadQueryFeed } from "../../../../../Utils/customHooks/UpToDateContent/useLoadQueryFeed";
import VideoComponent from "../../../../../components/VideoComponent/VideoComponent";
import RemovedContentTable from "../../../../../components/EditModalComponents.tsx/RemovedContentTable";

const EditQueryContent = (props: any) => {
  const {
    username,
    user_id,
    isQueryLinksEditModalOpen,
    setIsQueryLinksEditModalOpen,
    type,
    component,
    refetchDashboard,
  } = props;
  const [name, setName] = useState(component.name || ""); // const [details, setDetails] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage button disable state
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [removePostIds, setRemovePostIds] = useState<any>("");
  useEffect(() => {
    setPostIds(component?.post_ids);
    setName(component?.name);
    setDefaultQuery(component?.query);
    setRemovePostIds(component?.removed_postids);
  }, [component]);
  const handleInputChange = (value: string, setter: Function) => {
    setter(value); // Set the input value
    setIsButtonDisabled(!value); // Disable button if either name or details is empty
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await updateComponent(
      name,
      component,
      username,
      user_id,
      defaultQuery,
      component?.type,
      "",
      "",
      removePostIds
    );
    if (res === "true") {
      // props.refetch();
      refetchDashboard();
      props.setIsQueryLinksEditModalOpen(false);
      props.setSelectedComponent("");
      setConfirmLoading(false);

      setName("");
      setPostIds("");
      setErrorMsg("");
      setDefaultQuery("");
      setRemovePostIds("");
    } else {
      setErrorMsg("Category with this name already exists");
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    props.setIsQueryLinksEditModalOpen(false);
    props.setSelectedComponent("");

    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    setRemovePostIds("");
  };

  function handleRemovedPostIds(post_ids: any, type: any) {
    if (type === "minus") {
      setRemovePostIds((prev: any) => {
        const idsArray = prev?.split(",").filter(Boolean);
        idsArray.push(post_ids);

        return idsArray.join(",");
      });
    } else if (type === "add") {
      setRemovePostIds((prev: any) => {
        const idsArray = prev.split(",").filter(Boolean);
        const filteredArray = idsArray.filter((elem: any) => elem !== post_ids);
        return filteredArray.join(",");
      });
    }
  }

  return (
    <>
      <Modal
        open={isQueryLinksEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !name }}
      >
        <div  className="mt-1">
          <h5 className="editTitle">Editing {name}</h5>
          <label className="bold-label">Carousel Title</label>
          <Input
            placeholder="Enter Carousel Title"
            value={name}
            onChange={(e: any) => {
              handleInputChange(e.target.value, setName);
              setErrorMsg("");
            }}
            className="mb-15"
          />
          {errorMsg && <p className="errorMsg">{errorMsg}</p>}
          {component?.query && component?.query !== "" && (
            <p>Content shown based on "{component?.query}"</p>
          )}
          <AddedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={refetchDashboard}
            removePostIds={removePostIds}
            setRemovePostIds={setRemovePostIds}
            type={component?.type}
            displayName={component?.display_name}
            query={component?.query}
          />
          <RemovedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={refetchDashboard}
            removePostIds={removePostIds}
            setRemovePostIds={setRemovePostIds}
            type={component?.type}
            displayName={component?.display_name}
            query={component?.query}
          />
        </div>
      </Modal>
    </>
  );
};
const AddedContentTable = (props: any) => {
  const {
    username,
    user_id,
    post_ids,
    componentName,
    refetchDashboard,
    setRemovePostIds,
    type,
    displayName,
    removePostIds,

    query,
  } = props;
  const [postIds, setPostIds] = useState(post_ids);
  const [deletePostIds, setDeletePostIds] = useState(removePostIds);
  useEffect(() => {
    setDeletePostIds(removePostIds);
  }, [removePostIds]);
  const isShopLinks = type === "query-content" ? false : true;
  const showSocials = StaticVariables().PLATFORMS;
  const contentOrder = "Default";
  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadQueryFeed(
    username,
    user_id,
    query,

    showSocials,
    contentOrder,
    isShopLinks,
    deletePostIds
  );
  const onMinus = (post_id: string) => {
    setDeletePostIds((prev: any) => {
      const idsArray = prev.split(",").filter(Boolean);
      idsArray.push(post_id);
      return idsArray.join(",");
    });
    props.handleRemovedPostIds(post_id, "minus");
    refetch();
  };
  const [value, setValue] = useState<string>();
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");

  const handleDone = async (post_id: string) => {
    const { data: user, error } = await supabase
      .from(`${username}`)
      .select("*")
      .eq("post_id", post_id)
      .eq("user_id", user_id);

    const { data } = await supabase
      .from(`${username}`)
      .update({
        product_name: value,
        combined_text: user?.[0]?.combined_text + " " + `|${value}|`,
      })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    const { data: newContent, error: newErroor } = await supabase
      .from("content_new")
      .update({
        product_name: value,
        combined_text: user?.[0]?.combined_text + " " + `|${value}|`,
      })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    const { data: bankContent, error: bankErroor } = await supabase
      .from("product_bank")
      .update({
        name: value,
      })
      .eq("product_id", post_id)
      .eq("user_id", user_id);

    setEdit(false);
    setEditIndex("");
    refetch();
    refetchDashboard();
  };
  const SkeletonStatBox = () => {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
  };

  const filteredData = useMemo(() => {
    return (data || [])
      .filter((element: any) => element !== undefined)
      .filter((obj: any) => {
        const deleteIdsArray = deletePostIds?.split(",") || [];
        return !deleteIdsArray.includes(obj.post_id);
      });
  }, [data, deletePostIds]);

  const hasFilteredData = filteredData.length > 0;
  return (
    <>
      <div className="addedContent">
        <h5 className="addedContentTitle">{componentName}</h5>
        {!isLoading ? (
          <div className="addedSvg">
            {hasFilteredData ? (
              filteredData.map((post: any, index: any) => (
                <React.Fragment key={post.post_id}>
                  <div
                    key={index}
                    className="feed-tab-post-container added-post"
                  >
                    <div
                      className="feed-tab-media-wrapper"
                      style={
                        post?.platform === "newsletter"
                          ? {
                              backgroundColor: "#efeded",
                              textAlign: "center",
                            }
                          : {}
                      }
                    >
                      <Button
                        onClick={() => onMinus(post.post_id)}
                        className="carousel-content-remove-btn"
                      >
                        Remove
                      </Button>

                      {post?.platform !== "newsletter" ? (
                        <>
                          {post?.media_url ? (
                            <>
                              {post?.media_url.split(",").length > 1 &&
                              post?.media_url
                                .split(",")
                                .every((url: any) => url.trim() !== "") ? (
                                <Carousel
                                  dotPosition="bottom"
                                  className="feed-tab-media-carousel"
                                >
                                  {post?.media_url
                                    .split(",")
                                    .map((media_url: any, index: any) => (
                                      <React.Fragment key={index}>
                                        {media_url.includes("video") ? (
                                          <div className="h-255">
                                            <VideoComponent
                                              media_url={media_url}
                                              thumbnail={
                                                post.thumbnail &&
                                                post.thumbnail.split(",")
                                                  .length &&
                                                post.thumbnail.split(",")[index]
                                                  ? post.thumbnail.split(",")[
                                                      index
                                                    ]
                                                  : "/assets/dummy_video.png"
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <img
                                            className="feed-tab-media-check mt-300"
                                            src={media_url}
                                            alt="post"
                                          />
                                        )}
                                      </React.Fragment>
                                    ))}
                                </Carousel>
                              ) : (
                                <>
                                  {post.media_url.includes("video") ? (
                                    <div className="h-255">
                                      <VideoComponent
                                        media_url={post.media_url}
                                        thumbnail={post.thumbnail}
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      className="feed-tab-media-check"
                                      src={
                                        post.media_url
                                          ? post.media_url
                                          : "/assets/dummy_video.png"
                                      }
                                      alt="post"
                                    />
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <img
                              className="feed-tab-media-check"
                              src={
                                post?.thumbnail
                                  ? post?.thumbnail
                                  : "/assets/dummy_video.png"
                              }
                              alt="post"
                            />
                          )}
                        </>
                      ) : (
                        <div>
                          <h4 className="mt-7">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post?.post_date))}
                          </h4>
                          <h4> Newsletter</h4>
                        </div>
                      )}
                    </div>
                    {edit === true && editIndex === index ? (
                      <>
                        <Input
                          value={value}
                          onChange={(e: any) => setValue(e.target.value)}
                        />{" "}
                        <HiCheck
                          onClick={() => {
                            handleDone(post.post_id);
                          }}
                        />
                      </>
                    ) : (
                      <p
                        style={{
                          textAlign:
                            post.product_name !== "" ? "left" : "center",
                        }}
                      >
                        {post?.product_name}{" "}
                        <HiPencil
                          onClick={() => {
                            setValue(post.product_name);
                            setEdit(true);
                            setEditIndex(index);
                          }}
                        />
                      </p>
                    )}
                  </div>
                </React.Fragment>
              ))
            ) : (
              <React.Fragment>
                <>
                  <div className="feed-tab-post">
                    {[...Array(4)].map((_, index) => (
                      <SkeletonStatBox key={index} />
                    ))}
                  </div>
                </>
              </React.Fragment>
            )}
          </div>
        ) : (
          <>
            <React.Fragment>
              <>
                <div className="feed-tab-post">
                  {[...Array(4)].map((_, index) => (
                    <SkeletonStatBox key={index} />
                  ))}
                </div>
              </>
            </React.Fragment>
          </>
        )}
      </div>
    </>
  );
};

export default EditQueryContent;
