import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Spin } from "antd";
import AuthPageSwitch from "../../../components/AuthPageSwitch/authPageSwitch";
import SelectAccount from "../../../components/SelectAccount/SelectAccount";
import AuthPagesHeader from "../../../components/Header/AuthPagesHeader";
import CreatorQuote from "../../../components/CreatorQuote/creatorQuote";
import { googleCallback } from "../../../Utils/backendServices/youtube";
import { TabTitle } from "../../../Utils/GeneralFunctions";
import PhoneSignUp from "../SignUpProviders/PhoneSignUp";
import SignUpDetailsForm from "./SignUpDetailsForm";
import { useAuth } from "../../../hooks/useAuth";
import AuthPlatforms from "../AuthPlatforms";

import "../Auth.css";
import AuthTerm from "../../../components/AuthTerm/authTerm";
import SelectGoogleAccount from "../../../components/SelectGoogleAccount/SelectGoogleAccount";
import EmailSignUp from "../SignUpProviders/EmailSignUp";

enum STEPS {
  PHONE = 1,
  DETAILS = 2,
  PLATFORM = 3,
  SELECT_PLATFORM = 4,
  YOUTUBE_CHANNEL = 5,
  LOADING = 1000,
}

const SignUp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentOnboardingStep, user } = useAuth();
  const user_id = user?.id || "";
  const [step, setStep] = useState(currentOnboardingStep);
  const [youtubeChannelData, setYoutubeChannelData] = useState({
    name: "",
    videos: "",
    views: "",
    id: "",
    img: "",
  });
  const [facebookData, setFacebookData] = useState<{
    token: string;
    id: string;
    profiles: any;
  }>({
    token: "",
    id: "",
    profiles: null,
  });
  TabTitle("Signup • Bubble");

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());



  useEffect(() => {
    const { code, scope, error } = params;
    if (error) {
    } else if (code && scope) {
      setStep(STEPS.LOADING);
      googleCallback(code, scope, user_id).then((data) => {
        if (data) {
          setYoutubeChannelData(data);
          setStep(STEPS.YOUTUBE_CHANNEL);
        } else {
          setStep(STEPS.PLATFORM);
        }
      });
      navigate("/signup");
    }
  }, [params]);

  function handleFacebookAuthentication(
    token: string,
    id: string,
    profiles: any
  ) {
    setFacebookData({
      token,
      id,
      profiles,
    });
    setStep(4);
  }

  return (
    <div className="auth-container">
      {(() => {
        switch (step) {
          case 1:
            return (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  minHeight: "100%",
                }}
              >
                <div className="auth-phone-signup-container-flex">
                  {/* <AuthPagesHeader /> */}

                  <div className='navbar_container'>
					<a href="/" className='navbar_logo-link w-nav-brand' aria-label='home'>
						<div className='logo-text'>
							<img
								className='homepage-icon'
								src='/assets/output-onlinepngtools-edited.png'
								alt='thesearchbubble logo'
							/>
						</div>
					</a>
						<nav className='navbar_menu w-nav-menu'>
							<div className='navbar_menu-link-wrapper'>
								{/* <a href="/pricing" className="navbar_link w-nav-link pricing">pricing</a> */}
								{/* <a href="/about" className="navbar_link w-nav-link">about</a> */}
								{/* <a href="/how-it-works" className="navbar_link w-nav-link">how it works</a> */}
								<a href="/login" className="navbar_link w-nav-link sign-up">log in</a>
								{/* <a href="/signup" className="navbar_link w-nav-link sign-up">sign up for free</a> */}
							</div>
						</nav>
					</div>

                  <div className="auth-form-wrapper">
                    <div className="auth-title-div">
                      <p className="auth-title-div-p">Create your account</p>
                      {/* <p className="auth-title-div-p-2">Create a new account</p> */}
                    </div>
                    {/* <PhoneSignUp
                      moveToNextStep={(nextStep: number) => setStep(nextStep)}
                    /> */}
                    <EmailSignUp
                                          moveToNextStep={(nextStep: number) => setStep(nextStep)}

                    />
                    <AuthPageSwitch />
                  </div>

                  <AuthTerm />
                </div>
                <CreatorQuote />
              </div>
            );
          case 2:
            return (
              <SignUpDetailsForm
                moveToNextStep={(nextStep: number) => setStep(nextStep)}
              />
            );
          case 3:
            return (
              <AuthPlatforms
                handleFacebookAuthentication={handleFacebookAuthentication}
              />
            );
          case 4:
            return (
              <SelectAccount
                token={facebookData.token}
                id={facebookData.id}
                profiles={facebookData.profiles}
              />
            );
          case 5:
            return (
              <SelectGoogleAccount
                name={youtubeChannelData.name}
                videos={youtubeChannelData.videos}
                views={youtubeChannelData.views}
                imgUrl={youtubeChannelData.img}
                changeAccount={() => {
                  setStep(STEPS.PLATFORM);
                }}
              />
            );
          default:
            return (
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Spin size="large" className="white-spinner" />
                <p style={{ marginTop: "30px", fontSize: "1.2rem" }}>
                  Loading...
                </p>
              </div>
            );
        }
      })()}
    </div>
  );
};

export default SignUp;
