import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { addComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";

declare global {
  interface Window {
    fd: any;
  }
}

export const NewsletterComponent = (props: any) => {
  const { isNewsletterModalOpen, user_id, username, StayUpToDateComponents } =
    props;

  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await addComponent(
      "Join my list",
      "",
      username,
      user_id,
      "false",
      "Check it out!",
      "",
      "Newsletter"
    );
    if (res === "true") {
      props.refetch();
      props.setIsNewsletterModalOpen(false);
      props.setIsModalOpen(false);
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    props.setIsNewsletterModalOpen(false);
    props.setIsModalOpen(false);
  };

  const handlePrevious = () => {
    props.setIsModalOpen(true);
    props.setIsNewsletterModalOpen(false);
    // setName("");
    // setLink("");
    // setImageUrl("");
    // setFileList([]);
    // setErrorMsg("");
    // setCTA("Check it out!")
  };


  return (
    <>
      <Modal
        title={
          <div>
            <button onClick={handlePrevious} className="newbutton">
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isNewsletterModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
      >
        <div>
          <h5 className="title mb-15">Collect Contacts</h5>
          <p>Collecting email template will be visible to the followers.</p>
        </div>
      </Modal>
    </>
  );
};

// export default Flowdesk;

// 
