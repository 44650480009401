import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomPagination from "../../CustomPagination";
import "./CustomLinkData.css";
import Card from "react-bootstrap/esm/Card";
import { Button } from "antd";
import DataSkeleton from "../DataSkeleton";
import { useLoadCustomProduct } from "../../../../Utils/customHooks/UpToDateContent/useLoadCustomProduct";
import { updateRefClick } from "../../../../Utils/SupabaseServices/ContentDatabase";
import track, {
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";

const ProductBankData = (props: any) => {
  const location = useLocation();
  const itemsPerPage = 3;

  const {
    user_id,
    username,
    postIds,
    title,
    isLinks,
    handleShop,
    brand_color,
  } = props;
  const { isLoading: CustomProductLoading, data: CustomProduct }: any =
    useLoadCustomProduct(user_id, username, title);
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<any>(null);
  const isButtonClicked = useRef(false);

  function handleRedirect(
    postId: string,
    media_url: string,
    title: any,
    name: any
  ) {
    track("Post Click", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: {
        post_id: postId,
        media_url: media_url,
        title: title,
        name: name,
      },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    updateRefClick(postId, user_id);
  }

  const handleScroll = useCallback(
    debounce(() => {
      if (!isButtonClicked.current) {
        if (containerRef.current) {
          const scrollLeft = containerRef.current.scrollLeft;
          const itemWidth =
            containerRef.current.scrollWidth / CustomProduct.length;
          const newCurrentIndex = Math.round(scrollLeft / itemWidth);
          setCurrentIndex(newCurrentIndex);
        }
      }
    }, 100),
    [CustomProduct]
  );
  useEffect(() => {
    if (containerRef.current) {
      const itemWidth = containerRef.current.scrollWidth / CustomProduct.length;
      const scrollPosition = itemWidth * currentIndex;
      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex, CustomProduct]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [CustomProduct, handleScroll]);

  const handlePrevPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNextPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, CustomProduct.length - itemsPerPage)
    );
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };
  return (
    <div>
      {CustomProductLoading ? (
        <DataSkeleton />
      ) : (
        <>
          {CustomProduct && CustomProduct.length ? (
            <div>
              <div
                className="mainDiv"
              >
                <p
                  className="component-name"
                >
                  {title}
                </p>
                <CustomPagination
                  totalItems={CustomProduct?.length}
                  currentIndex={currentIndex}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
                />
              </div>
              <div
                className="outer-container-reels"
                ref={containerRef}
              >
                <div
                  className="links-grid-container-reels"
                >
                  {CustomProduct &&
                    CustomProduct.map((link: any, index: number) => (
                      <div
                        key={link.id}
                        className="link-url"
                      >
                        <>
                          <a
                            href={link?.table_url}
                            target="_blank"
                            onClick={(e) => {
                              e.preventDefault();
                              handleRedirect(
                                link?.table_id,
                                link?.table_image,
                                title,
                                link?.table_name
                              );
                              if (link?.table_url && link?.table_url !== "") {
                                window.open(link?.table_url, "_blank");
                              }
                            }}
                          >
                            <div
                              className={"creator-feed-image-wrapper-links"}
                              style={{
                                marginBottom: "2%",
                              }}
                            >
                              <Card.Img
                                variant="top"
                                src={link?.table_image}
                                className={"creator-feed-card-links-image"}
                                loading="eager"
                                style={{
                                  backgroundColor: brand_color && brand_color,
                                }}
                              />
                            </div>
                            {link?.table_name && link?.table_image && (
                              <p
                                className="product-name"
                              >
                                {link?.table_name}
                              </p>
                            )}
                            {isLinks && (
                              <Button className={"template-shop-btn-links"}>
                                Shop
                              </Button>
                            )}
                          </a>
                        </>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default ProductBankData;
