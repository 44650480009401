import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import { getEmbedding } from "../../../Utils/SupabaseServices/ContentDatabase";
import { StaticVariables } from "../../../Utils/StaticVariables";

/**
 * Load content
 */

export function useLoadEmbeddingPosts(
  currentPage: any,
  index: string,
  postsPerPage: number,
  totalPages: any,
  filter: string | null,
  usernames: any,
  userIds: any,
  postIds: any[],
  threshold: any
) {
  async function getPaginatedFeed({ pageParam = 1 }) {
  
    // if (activeCreator !== undefined) {
    //   selectedCreators = activeCreator;
    // }
    const start = (currentPage - 1) * postsPerPage;
    const end = start + postsPerPage - 1;
    // const start = ((pageParam || 1) - 1) * postsPerPage;
    // const end = start + postsPerPage - 1;
    let data,
      error = null;
      let contentOrder:any="default"
    // if (userIds && userIds.length === 1) {
      if (filter) {
        const elementsCount = 5000;

        const connectingWords = StaticVariables().connectingWords


        // Split the sentence into words
        let words = filter.split(" ");
        if (words.includes("shop") || words.includes("buy")) {
          contentOrder = "shoppable";
          words = words.filter(
            (word: any) => word !== "shop" && word !== "buy"
          );}
        // Filter out connecting words
        const filteredWords = words.filter(
          (word: any) => !connectingWords.includes(word.toLowerCase())
        );

        // Join the filtered words back into a sentence
        const result = filteredWords.join(" ");
        const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");
        const embedding = await getEmbedding(pgQuery);

        let totalResult = [];

        const { data: searchResult, error: searchError } = await supabase.rpc(
          // "semantic_sssearch",
          // "semanticc_multipleadssearch",
          "semanticcc_embedpostidddddtioo",
          {
            query_string: pgQuery,
            query_embedding: embedding,
            // similarity_threshold: 0.3146,
            similarity_threshold: threshold,

            // match_count: elementsCount,
            start_number: start,
            end_num: end,
            userid: userIds,
            username: usernames,
            socials: [
              "instagram",
              "youtube",
              "tiktok",
              "podcast",
              "pinterest",
              "newsletter",
            ],
            idx: 0,
            show_ads: false,
            postids: postIds,
            index_type:index,
            content_order:contentOrder // true if end + 1 is a multiple of 10
          }
        );
        
        data = searchResult;
        error = searchError;
      }

      if (data && data.length >= 0 && Array.isArray(data)) {
        return data;
      } else if (error) {
        //   throw new Error(error);
      } else {
        throw new Error("Failed to fetch posts");
      }
    // }
  }

  return useInfiniteQuery(
    ["paginatedEmbedPosts", currentPage, index, filter],
    getPaginatedFeed,
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < totalPages) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      enabled: Boolean(totalPages),
    }
  );
}
