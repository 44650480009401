import { Button, Input, Upload, UploadProps } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import "../../StayUpToDateControls.css";
import { supabase } from "../../../../../config/supabaseClient";

const HighlightLinksComponents = (props: any) => {
  const { username, user_id, defaultName, link, image, defaultFileList, cta } =
    props;
  const [name, setName] = useState("");
  const [shoplink, setShoplink] = useState("");
  const [fileList, setFileList] = useState([]);
  const [shopImage, setShopImage] = useState("");
  const [shopCTA, setShopCTA] = useState("");
  useEffect(() => {
    setName(defaultName);
    setShoplink(link);
    setFileList(defaultFileList);
    setShopCTA(cta || "Check it out!");
    setShopImage(image);
  }, [defaultName, link, cta, image]);
  // const handleInputChange = (value: string, setter: Function) => {
  //   setter(value); // Set the input value
  //   props.handleData(shopImage, name, shoplink, "link",shopCTA);

  //   // setIsButtonDisabled(!value || !details); // Disable button if either name or details is empty
  // };
  // const handleInputChange = useCallback(
  //   (value: any, setter: any) => {
  //     setter(value);
  //     props.handleData(shopImage, name, shoplink, "link", shopCTA);
  //   },
  //   [props, shopImage, name, shoplink, shopCTA]
  // );

  useEffect(() => {
    props.handleData(shopImage, name, shoplink, "link", shopCTA);
  }, [shopImage, name, shoplink, shopCTA]);

  const handleInputChange = (value: any, setter: any) => {
    setter(value);
  };
  const uploadProps: UploadProps<any> = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    // beforeUpload: (file) => {
    //   // Clear the fileList before uploading a new file
    //   setFileList([]);
    //   return false; // Prevent default upload behavior
    // },
    async onChange(info: any) {
      info.file.status = "uploading";

      let fileList: any = [...info.fileList];

      fileList = fileList.slice(-1);

      // 2. Read from response and show file link
      fileList = fileList.map((file: any) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
          file.staus = "uploaading";
        }
        return file;
      });
      setFileList(fileList);
      setTimeout(() => {
        info.file.status = "done";
      }, 1000);

      const { data: highlightslist }: any = await supabase.storage
        .from("highlight_section")
        .list(`${username}/links/${name}`, { limit: 5000 });

      for (let item of highlightslist) {
        await supabase.storage
          .from("highlight_section")
          .remove([`${username}/links/${name}/${item.name}`]);
      }
      const post_id = info.file.name.split("_")[0];
      const { data, error }: any = await supabase.storage
        .from("highlight_section")
        .upload(
          `${username}/links/${name}/${info.file.type.split("/")[0]
          }${post_id}.${info.file.type.split("/")[1]}`,
          info.file.originFileObj
            ? info.file.originFileObj
            : info.fileList[info.fileList.length - 1].originFileObj,
          {
            contentType: `${info.file.type}`,
          }
        );

      var { data: url }: any = await supabase.storage
        .from("highlight_section")
        .getPublicUrl(
          `${username}/links/${name}/${info.file.type.split("/")[0]
          }${post_id}.${info.file.type.split("/")[1]}`
        );
      setShopImage(url.publicUrl);
      handleInputChange(url.publicUrl, setShopImage);

      // 1. Limit the number of uploaded files
      // Only one file is allowed
    },
  };
  return (
    <div>
      {" "}
      {/* <p>Preview</p> */}
      {/* <h6 className="mb-15">
        Create a custom shop links highlight{" "}
      </h6> */}
      <div className="highlight-data-section mb-2">
        <div className="highlight-media">
          <img
            src={shopImage ? shopImage : "/assets/image-bg.jpeg"}
            className="shop-image"
          // src={component?.details?.split(",")[0]}
          />
        </div>

        <div className="highlight-details">
          <div className="highlight-name">{name ? name : "Highlight Title"}</div>
          <div className="highlight-link-tag">
            <Button className="highlight-shop-button">{shopCTA}</Button>
          </div>
        </div>
      </div>
      <div className="fieldGroup">
        <label
          className="bold-label"
        >
          Highlight Title
        </label>
        <Input
          placeholder="Enter Title"
          value={name}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setName);
            props.setErrorMsg("");
          }}
          className="mb-15"
        />

        <label
          className="bold-label"
        >
          Button Text
        </label>
        <Input
          placeholder="Check it Out!"
          value={shopCTA}
          onChange={(e: any) => handleInputChange(e.target.value, setShopCTA)}
          className="mb-15"
        />

        <label
          className="bold-label"
        >
          Button Link
        </label>
        <Input
          placeholder="www.yourlink.com"
          value={shoplink}
          onChange={(e: any) => handleInputChange(e.target.value, setShoplink)}
          className="mb-15"
        />

        <div
          className="image-upload-section"
        >
          <Upload
            {...uploadProps}
            multiple={false}
            maxCount={1}
            // progress={}
            showUploadList={fileList.length === 0 ? false : true}
          >
            <Button
              // icon={<UploadOutlined />}
              className="uploadButton"
            >
              <div className="text-center">
                <p
                  className="textContainerHeading"
                >
                  Click to select image or drag & drop to this area to upload
                </p>
                <p
                  className="upload-text"
                >
                  {" "}
                  JPG, JPEG, PNG, HEIC, or WEBP
                </p>
              </div>
              {/* Click to Upload */}
            </Button>
          </Upload>
        </div>
      </div>
    </div>
  );
};

export default HighlightLinksComponents;
