import { BsGoogle } from "react-icons/bs";
import { getGoogleAuthUrl } from "../../Utils/backendServices/youtube";
import { Spin } from "antd";
import { useState } from "react";

export default function GoogleAuthButton() {
	const [isLoading, setIsLoading] = useState(false);

	const onClick = async () => {
		setIsLoading(true);
		const googleUrl = await getGoogleAuthUrl();
		if (googleUrl) {
			window.location.replace(googleUrl);
		} else {
		}
	};
	return (
		<Spin spinning={isLoading}>
			<div className='auth-facebook-btn' onClick={onClick}>
				<BsGoogle style={{ marginRight: "20px" }} />
				<p style={{ margin: 0 }}>Continue With Google</p>
			</div>
		</Spin>
	);
}
