import React, { useEffect, useState } from "react";
import { BsHourglassSplit } from "react-icons/bs";
import { Button } from "antd";
import Card from "react-bootstrap/esm/Card";
import PuffLoader from "react-spinners/PuffLoader";
import "../SubComponents.css";

import track, {
  TRACKING_TYPES,
} from "../../../../../Utils/SupabaseServices/Track";
import { updateRefClick } from "../../../../../Utils/SupabaseServices/ContentDatabase";
import { useLocation } from "react-router-dom";
import { useLoadCustomContent } from "../../../../../Utils/customHooks/UpToDateContent/useLoadCustomContent";
import ContentSkeletonTemplateWrapper from "../ContentSkeletonTemplateWrapper";
import VideoPostTag from "../../../../../components/GeneralPostTag/VideoPostTag";
import ImagePostCarouselTag from "../../../../../components/GeneralPostTag/ImagePostCarouselTag";
import ImagePostTag from "../../../../../components/GeneralPostTag/ImagePostTag";
const CustomContentWrapper = (props: any) => {
  const {
    user_id,
    username,
    numberOfDays,
    title,
    isLinks,
    // handleShop,
    platform,
    type,
    postIds,
    brand_color,
  } = props;
  const location = useLocation();
  function handleShop(postId: string, media_url: string) {
    track("Redirect to shop link", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
      title: "Stay Up To Date",
    });
  }
  const {
    isLoading: CustomContentDataLoading,
    data: CustomContentData,
    forceRefetch,
  }: any = useLoadCustomContent(username, user_id, postIds, title);
  useEffect(() => {
    forceRefetch();
  
    // Optionally, you can return a cleanup function if necessary
    return () => {
      // Cleanup code here (if needed)
    };
  });
  return (
    <>
      <div>
        {CustomContentDataLoading ? (
          <div className="outer-container-subcomponents">
            <ContentSkeletonTemplateWrapper />
          </div>
        ) : (
          <div className="outer-container-subcomponents">
            {CustomContentData && CustomContentData.length ? (
              <div className="links-grid-container-subcomponents">
                {CustomContentData &&
                  CustomContentData.map((link: any, index: any) => (
                    <React.Fragment key={index}>
                      {link.media_url ? (
                        link.media_url.split(",").length > 1 &&
                        link.media_url
                          .split(",")
                          .every((url: any) => url.trim() !== "") ? (
                          <>
                            <ImagePostCarouselTag
                              post={link}
                              handleRedirect={handleShop}
                              title={title}
                              brand_color={brand_color}
                              isLinks={false}
                              isDashboard={true}
                            />
                          </>
                        ) : (
                          // <>
                          //   {link.product_image && link.product_image !== "" ? (
                          //     <>
                          //       <ImagePostTag
                          //         data={link}
                          //         handleRedirect={handleShop}
                          //         title={title}
                          //         brand_color={brand_color}
                          //         isLinks={false}
                          //         isDashboard={true}
                          //         type={type}
                          //       />
                          //     </>
                          //   ) : (
                          //     <>
                          //       {link.media_url.includes("video") ? (
                          //         <>
                          //           <VideoPostTag
                          //             post={link}
                          //             handleRedirect={handleShop}
                          //             title={title}
                          //             brand_color={brand_color}
                          //             isLinks={false}
                          //             isDashboard={true}
                          //           />
                          //         </>
                          //       ) : (
                          //         <>
                          //           <ImagePostTag
                          //             data={link}
                          //             handleRedirect={handleShop}
                          //             title={title}
                          //             brand_color={brand_color}
                          //             isLinks={false}
                          //             isDashboard={true}
                          //             type={type}

                          //           />
                          //         </>
                          //       )}
                          //     </>
                          //   )}
                          // </>

                          <>
                          {link.media_url.includes("video") ? (
                            <>
                              <VideoPostTag
                                post={link}
                                handleRedirect={handleShop}
                                title={title}
                                brand_color={brand_color}
                                isLinks={false}
                                isDashboard={true}
                              />
                            </>
                          ) : (
                            <>
                              <ImagePostTag
                                data={link}
                                handleRedirect={handleShop}
                                title={title}
                                brand_color={brand_color}
                                isLinks={false}
                                isDashboard={true}
                                type={type}

                              />
                            </>
                          )}
                          </>
                        )
                      ) : (
                        <>
                          <ImagePostTag
                            data={link}
                            handleRedirect={handleShop}
                            title={title}
                            brand_color={brand_color}
                            isLinks={false}
                            isDashboard={true}
                          />
                        </>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            ) : (
              <div className="outer-container-subcomponents">
                <p>No content at this time</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomContentWrapper;
