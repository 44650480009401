import React, { useState } from "react";
import { Alert, Col, Input, Modal, Row, Spin } from "antd";
import { useAuth } from "../../hooks/useAuth";
import { storeLinks } from "../../Utils/SupabaseServices/UserDatabase";
import { useLoadLinks } from "../../Utils/customHooks/useLoadLinks";
import "./AccountsIntegration.css";
import "../AccountDescription/AccountDescription.css";
import useUpdateLinkTag from "./useUpdateLinkTag";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MdOutlineDragIndicator } from "react-icons/md";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Button, message, Upload } from "antd";
import { supabase } from "../../config/supabaseClient";
import { BsTrash } from "react-icons/bs";
import { isMobile } from "react-device-detect";

const AccountsIntegration = (props: any) => {
  const { isLinksModalOpen, username, setIsLinksModalOpen } = props;
  const { user } = useAuth();
  const user_id = user?.id || "";
  const [linkURL, setLinkURL] = useState("");
  const [imageLinkURL, setImageLinkURL] = useState("");

  const [linkName, setLinkName] = useState("");
  const [isEditLink, setIsEditLink] = useState(false);
  const [isEditImageLink, setIsEditImageLink] = useState(false);

  const [isEditLinkName, setIsEditLinkName] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [showDeleteConfirmationIndex, setShowDeleteConfirmationIndex] =
    useState(-1);
  const [errorLink, setErrorLink] = useState("");

  const { mutate: updateLinkTags } = useUpdateLinkTag();
  let {
    isLoading,

    data,

    refetch,
  } = useLoadLinks(user_id);
  const [localLinks, setLocalLinks] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const handleOk = async () => {
    const urlRegex = /^(?:(https?|ftp):\/\/)?(www\.)?[^\s/$.?#].[^\s]*$/;

    if (linkURL && linkName) {
      if (urlRegex.test(linkURL)) {
        const res = await storeLinks(
          user_id,
          username,
          linkName,
          linkURL,
          imageLinkURL
        );
        if (res) {
          refetch();
          props.setIsLinksModalOpen(false);
          setErrorLink("");
          setLinkURL("");
          setLinkName("");
          setImageLinkURL("");
        }
      } else {
        setErrorLink("Enter valid url");
      }
    } else {
      if (linkName) {
        setErrorLink("Enter valid url");
      } else if (linkURL) {
        setErrorLink("Enter valid name");
      }
    }
  };
  const removeTag = (index: any) => {
    setShowDeleteConfirmationIndex(index);
  };

  const handleDeleteConfirmation = (index: any) => {
    removeTagLogic(index);
    setShowDeleteConfirmationIndex(-1);
    setIsEditLink(false);
    setIsEditLinkName(false);
    setIsEditImageLink(false);

    setEditIndex(-1);
    setLinkURL("");
    setLinkName("");
    setImageLinkURL("");
  };

  const removeTagLogic = (index: any) => {
    const updatedData = data.split(",").filter((_: any, i: any) => i !== index);
    updateLinkTags({
      tagString: updatedData.toString(),
      user_id,
      pageIndex: 1,
      index: 1,
    });
  };

  const handleCancel = () => {
    props.setIsLinksModalOpen(false);
    setErrorLink("");
    setLinkURL("");
    setLinkName("");
    setImageLinkURL("");
    setErrorLink("");
  };

  function editLink(link: any) {
    setLinkURL(link.split(":-")[1]);

    setImageLinkURL(link.split(":-")[2] ? link.split(":-")[2] : "");
  }

  const editLinkName = (link: any) => {
    setLinkName(link.split(":-")[0]);

    setEditIndex(-1);
  };
  const handleEdit = (data: any, changedLink: any) => {
    const idx = data.indexOf(changedLink);

    data.splice(idx, 1);

    const newLink = `${linkName}:-${linkURL}:-${imageLinkURL}`;
    data.splice(idx, 0, newLink);
    updateLinkTags({
      tagString: data.toString(),
      user_id,
      pageIndex: 1,
      index: 1,
    });

    setIsEditLinkName(false);
    setIsEditLink(false);
    setIsEditImageLink(false);

    setEditIndex(-1);
    setImageLinkURL("");
  };

  const handleKeyPress = async (
    data: any,
    link: any,
    element: any,
    index: any
  ) => {
    if (linkName && linkURL) {
      handleEdit(data, link);
      setErrorLink("");
      setIsEditLinkName(false);
      setIsEditLink(false);
      setIsEditImageLink(false);

      setEditIndex(index);
    } else {
      setErrorLink("Enter correct values");
    }
  };

  function onDragEnd(result: any) {
    setIsDragging(true);
    const { destination, source, draggableId } = result;
    // Check if there's no destination (i.e., dropped outside of droppable)
    if (!destination) {
      setLocalLinks(data);
      setIsDragging(false);
      return;
    }

    // Check if the item was dropped in its original location
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      setLocalLinks(data);
      setIsDragging(false);
      return;
    }

    // Reorder logic goes here based on the provided result object
    // You might want to update your state to reflect the new order of items
    // Example:
    const newLinks = Array.from(data.split(","));
    const movedLink = newLinks.splice(source.index, 1)[0];
    newLinks.splice(destination.index, 0, movedLink);
    updateLinkTags({
      tagString: newLinks.toString(),
      user_id,
      pageIndex: 1,
      index: 1,
    });
    // refetch()
    data = newLinks.toString();
    setLocalLinks(newLinks.toString());
    refetch();
    setTimeout(() => {
      setIsDragging(false);
    }, 4000);
  }

  const uploadProps: UploadProps<any> = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      info.file.status = "uploading";
      setImageLinkURL("/assets/image-bg.jpeg")
      setTimeout(() => {
        info.file.status = "done";
      }, 1500);

      const { data: highlightslist }: any = await supabase.storage
        .from("mylinks")
        .list(`${username}/${linkName}`, { limit: 5000 });

      for (let item of highlightslist) {
        await supabase.storage
          .from("mylinks")
          .remove([`${username}/${linkName}/${item.name}`]);
      }

      const post_id = info.file.name.split("_")[0];
      const { data, error }: any = await supabase.storage
        .from("mylinks")
        .upload(
          `${username}/${linkName}/${info.file.type.split("/")[0]}${post_id}.${
            info.file.type.split("/")[1]
          }`,
          info.file.originFileObj
            ? info.file.originFileObj
            : info.fileList[info.fileList.length - 1].originFileObj,
          {
            contentType: `${info.file.type}`,
          }
        );

      var { data: url }: any = await supabase.storage
        .from("mylinks")
        .getPublicUrl(
          `${username}/${linkName}/${info.file.type.split("/")[0]}${post_id}.${
            info.file.type.split("/")[1]
          }`
        );
      setImageLinkURL(url.publicUrl);

      // setShopImage(url.publicUrl);
      // handleInputChange(url.publicUrl, setShopImage);
    },
  };

  async function removeImage(link: any) {
    const localData = data?.split(",");
    setImageLinkURL("");
    const foundElement = localData.find((element: any) =>
      element.includes(link)
    );
    if (foundElement) {
      const parts = foundElement.split(":-");
      const modifiedElement = parts.slice(0, 2).join(":-"); // Remove the third part

      const index = localData.indexOf(foundElement);
      if (index !== -1) {
        localData[index] = modifiedElement;
      }
    }

    const updatedData = localData.join(",");

    const { data: upd } = await supabase
      .from("user")
      .update({ instagram_links: updatedData })
      .eq("user_id", user_id);
  }
  return (
    <>
      <>
        <div className="account-social-logins-heading">
          <h5>
            <Button
              onClick={() => {
                setIsLinksModalOpen(true);
                setIsEditLinkName(false);
                setIsEditLink(false);
                setIsEditImageLink(false);
                setImageLinkURL("");
              }}
              className="btn-primary"
            >
              Add Link
            </Button>
            {/* <BsPlusCircle onClick={() => setIsLinksModalOpen(true)} /> */}
          </h5>
        </div>
        <div className="account-social-content-connect">
          {isLoading ? (
            <div
              className="spin-style"
            >
              <Spin />
            </div>
          ) : (
            <>
            
              {data ? (
                <>
                  <DragDropContext
                    onDragEnd={(result: any) => {
                      onDragEnd(result);
                    }}
                  >
                    <Droppable droppableId="links">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {(isDragging ? localLinks : data)
                            .split(",")
                            .map((link: any, index: any) => {
                              const draggableId = `link-${index}`;
                              const positionNames = [
                                "First",
                                "Second",
                                "Third",
                                "Fourth",
                                "Fifth",
                                "Sixth",
                                "Seventh",
                                "Eighth",
                                "Ninth",
                                "Tenth",
                                "Eleventh",
                                "Twefth",
                                "Thirteenth",
                                "Fourteenth",
                                "Fifteenth",
                                "Sixteenth",
                                "Seventeenth",
                                "Eighteenth",
                                "Nineteenth",
                                "Twentieth",
                              ];

                              const positionText =
                                positionNames[index] || `${index + 1}th`;

                              return (
                                <Draggable
                                  key={index}
                                  draggableId={draggableId}
                                  index={index}
                                  // isDragDisabled={activeKey==="active,ad"?false:true}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <React.Fragment key={index}>
                                        <div
                                          key={index}
                                          className="account-social-link-connect-row ml-2"
                                        >
                                          <div className="account-social-link-connect-row-cont">
                                            <p>
                                              <MdOutlineDragIndicator
                                                className="drag-indicator"
                                              />
                                              {positionText} Link{" "}
                                              {isEditLink &&
                                              isEditLinkName &&
                                              editIndex === index ? (
                                                <span
                                                  className="title-btn mb-2"
                                                >
                                                  <Button
                                                    className="btn-primary vertical-top ml-1"
                                                    onClick={() => {
                                                      handleKeyPress(
                                                        data.split(","),
                                                        link,
                                                        "all",
                                                        index
                                                      );
                                                    }}
                                                  >
                                                    Save
                                                  </Button>
                                                  <Button
                                                    className="btn-primary vertical-top ml-1"
                                                    onClick={() => {
                                                      setIsEditLinkName(false);
                                                      setIsEditLink(false);
                                                      setIsEditImageLink(false);

                                                      setEditIndex(index);
                                                    }}
                                                  >
                                                    Cancel
                                                  </Button>
                                                </span>
                                              ) : (
                                                <Button
                                                className="btn-primary vertical-top ml-1 mr-2 float-right"
                                                  onClick={() => {
                                                    setIsEditLinkName(true);
                                                    setEditIndex(index);
                                                    editLinkName(link);
                                                    setIsEditLink(true);
                                                    setIsEditImageLink(true);
                                                    setErrorLink("");
                                                    setEditIndex(index);
                                                    editLink(link);
                                                  }}
                                                >
                                                  Edit
                                                </Button>
                                              )}
                                            </p>

                                            {!isMobile ? (
                                              <Row className="mt-2">
                                                <Col span={18} push={6}>
                                                  {isEditLinkName &&
                                                  editIndex === index ? (
                                                    <>
                                                      <label
                                                        className="label"
                                                      >
                                                        Link Name
                                                      </label>
                                                      <Input
                                                        value={linkName}
                                                        onChange={(e: any) => {
                                                          setLinkName(
                                                            e.target.value
                                                          );
                                                        }}
                                                        placeholder="Name"
                                                        className="input-style"
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      <p
                                                        className="label"
                                                      >
                                                        {link.split(":-")[0]}
                                                      </p>
                                                    </>
                                                  )}
                                                  {isEditLink &&
                                                  editIndex === index ? (
                                                    <>
                                                      <label
                                                        className="label"
                                                      >
                                                        URL
                                                      </label>
                                                      <br />
                                                      <Input
                                                        value={linkURL}
                                                        placeholder="www.yourlink.com"
                                                        onChange={(e: any) => {
                                                          // e.target.value.length
                                                          //   ?
                                                          setLinkURL(
                                                            e.target.value
                                                          );
                                                          // : setErrorLink(
                                                          //     "Enter valid link url"
                                                          //   );
                                                        }}
                                                        className="input-style"
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      <p
                                                        className="link"
                                                      >
                                                        {link.split(":-")[1]}
                                                      </p>
                                                    </>
                                                  )}{" "}
                                                </Col>
                                                <Col span={6} pull={18}>
                                                  {isEditImageLink &&
                                                  editIndex === index ? (
                                                    <>
                                                      {imageLinkURL &&
                                                      imageLinkURL != "" ? (
                                                        <img
                                                          src={imageLinkURL}
                                                          className="uploaded-image"
                                                        />
                                                      ) : (
                                                        <img
                                                          src="/assets/image-bg.jpeg"
                                                          className="uploaded-image"
                                                        />
                                                      )}
                                                      <Upload
                                                        {...uploadProps}
                                                        maxCount={1}
                                                        showUploadList={
                                                          imageLinkURL &&
                                                          imageLinkURL !== ""
                                                            ? true
                                                            : false
                                                        }
                                                      >
                                                        <Button
                                                          className="editUploadButton"
                                                          icon={
                                                            <UploadOutlined />
                                                          }
                                                        >
                                                          Click to Upload
                                                        </Button>
                                                      </Upload>
                                                     {imageLinkURL && imageLinkURL !="" &&  <Button
                                                        className="editUploadButton edit-upload-btn"
                                                        icon={<BsTrash />}
                                                        onClick={() =>
                                                          removeImage(link)
                                                        }
                                                      >
                                                        Remove Image
                                                      </Button>
                            }
                                                    </>
                                                  ) : (
                                                    <>
                                                      {link.split(":-")[2] &&
                                                        link.split(":-")[2] !==
                                                          "" && (
                                                          <img
                                                            src={
                                                              link.split(
                                                                ":-"
                                                              )[2]
                                                            }
                                                            className="w-60"
                                                          />
                                                        )}
                                                    </>
                                                  )}{" "}
                                                </Col>
                                              </Row>
                                            ) : (
                                              <Row className="mt-2" style={{ display: "block"}}>
                                                <Col style={{ width: "100%"}}>
                                                  <div
                                                    className="text-center"
                                                  >
                                                    {isEditImageLink &&
                                                    editIndex === index ? (
                                                      <>
                                                        {imageLinkURL &&
                                                        imageLinkURL !== "" ? (
                                                          <img
                                                            src={imageLinkURL}
                                                            className="w-60 mb-2"
                                                          />
                                                        ) : (
                                                          <img
                                                            src="/assets/image-bg.jpeg"
                                                            className="edit-image-link"
                                                          />
                                                        )}
                                                        <div
                                                          className="image-link-div"
                                                        >
                                                          {isEditImageLink &&
                                                          editIndex ===
                                                            index ? (
                                                            <>
                                                              <div
                                                                className="mb-2 mt-2"
                                                              >
                                                                <Upload
                                                                  {...uploadProps}
                                                                  maxCount={1}
                                                                  showUploadList={
                                                                    imageLinkURL &&
                                                                    imageLinkURL !==
                                                                      ""
                                                                      ? true
                                                                      : false
                                                                  }
                                                                >
                                                                  <Button
                                                                    className="editUploadButton font-6"
                                                                    icon={
                                                                      <UploadOutlined />
                                                                    }
                                                                  >
                                                                    Click to
                                                                    Upload
                                                                  </Button>
                                                                </Upload>
                                                                {imageLinkURL && imageLinkURL !="" &&   <Button
                                                                  className="editUploadButton trash-icon"
                                                                  icon={
                                                                    <BsTrash />
                                                                  }
                                                                  onClick={() =>
                                                                    removeImage(
                                                                      link
                                                                    )
                                                                  }
                                                                >
                                                                  Remove Image
                                                                </Button>}
                                                              </div>
                                                            </>
                                                          ) : null}
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {link.split(":-")[2] &&
                                                          link.split(
                                                            ":-"
                                                          )[2] !== "" && (
                                                            <img
                                                              src={
                                                                link.split(
                                                                  ":-"
                                                                )[2]
                                                              }
                                                              className="w-60"
                                                            />
                                                          )}
                                                      </>
                                                    )}
                                                  </div>
                                                  {isEditLinkName &&
                                                  editIndex === index ? (
                                                    <>
                                                      <label
                                                        className="label"
                                                      >
                                                        Display as:
                                                      </label>
                                                      <Input
                                                        value={linkName}
                                                        onChange={(e: any) => {
                                                          setLinkName(
                                                            e.target.value
                                                          );
                                                        }}
                                                        placeholder="Ex. Link Name"
                                                        className="input-style"
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      <p
                                                        className="bold-link"
                                                      >
                                                        {link.split(":-")[0]}
                                                      </p>
                                                    </>
                                                  )}

                                                  {isEditLink &&
                                                  editIndex === index ? (
                                                    <>
                                                      <label
                                                        className="label"
                                                      >
                                                        Url
                                                      </label>
                                                      <br />
                                                      <Input
                                                        value={linkURL}
                                                        placeholder="https://links.com"
                                                        onChange={(e: any) => {
                                                          // e.target.value.length
                                                          //   ?
                                                          setLinkURL(
                                                            e.target.value
                                                          );
                                                          // : setErrorLink(
                                                          //     "Enter valid link url"
                                                          //   );
                                                        }}
                                                        className="input-style"
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      <p
                                                        className="link"
                                                      >
                                                        {link.split(":-")[1]}
                                                      </p>
                                                    </>
                                                  )}
                                                </Col>
                                              </Row>
                                            )}

                                            {isEditLink &&
                                            isEditLinkName &&
                                            editIndex === index &&
                                            errorLink ? (
                                              <p
                                                className="error-link"
                                              >
                                                {errorLink}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            <p>
                                              {isEditLink &&
                                                isEditLinkName &&
                                                editIndex === index && (
                                                  <Button
                                                  className="remove-btn"
                                                    style={{
                                                      marginTop: `${
                                                        !isMobile ? "-1%" : "2%"
                                                      }`,
                                                    }}
                                                    onClick={() =>
                                                      removeTag(index)
                                                    }
                                                  >
                                                    Remove
                                                  </Button>
                                                )}
                                            </p>
                                          </div>
                                        </div>
                                        {showDeleteConfirmationIndex ===
                                          index && (
                                          <div className="confirmation-dialog">
                                            <p>
                                              Are you sure you want to delete
                                              this link?
                                            </p>
                                            <button
                                              className="confirm-btn cancel"
                                              onClick={() =>
                                                setShowDeleteConfirmationIndex(
                                                  -1
                                                )
                                              }
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="confirm-btn confirm confirm-color"
                                              onClick={() =>
                                                handleDeleteConfirmation(index)
                                              }
                                            >
                                              Confirm
                                            </button>
                                          </div>
                                        )}
                                        <hr
                                          className="height-style"
                                        />
                                      </React.Fragment>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </>
              ) : (
                <p className="no-links-msg">No Links</p>
              )}
            </>
          )}
        </div>
      </>

      {isLinksModalOpen ? (
        <Modal
          title="Add Link"
          open={isLinksModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Add"
          className="add-link-modal"
          okButtonProps={{ disabled: !linkName || !linkURL   }}
          >
          {errorLink && (
            <Alert
              type="error"
              message={`Error: ${errorLink}`}
              showIcon
              className="alert-margin"
            />
          )}
          <label
            className="modal-label"
          >
            Link Name
            <Input
              placeholder="Name"
              required
              onChange={(e) => {
                setLinkName(e.target.value);
              }}
            />
          </label>
          <br />
          <br />
          <label
            className="modal-label"
          >
            URL
            <Input
              placeholder="www.yourlink.com"
              required
              // value={linkURL}
              onChange={(e) => {
                // e.target.value.length
                //   ?
                setLinkURL(e.target.value);
                // : setErrorLink("Enter valid link");
              }}
              className="w-100"
            />
          </label>
          <br />

          {imageLinkURL && imageLinkURL !== "" && (
            <div className="image-link">
              <img
                src={imageLinkURL}
              />
            </div>
          )}
          <Upload
            {...uploadProps}
            maxCount={1}
            className="upload-btn"
          >
            <Button icon={<UploadOutlined />} className="mt-10">
              Click to Upload
            </Button>
          </Upload>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default AccountsIntegration;
