import { Button, Input, Modal, Spin } from "antd";
import React, { useState } from "react";
import "./AccountDescription.css";
import TextArea from "antd/es/input/TextArea";
import useUpdateDescription from "./useUpdateDescription";
import { useLoadLDescription } from "./useLoadDescription";

const AccountDescription = (props: any) => {
  const { userData } = props;
  const {
    isLoading,

    data,

    refetch,
  } = useLoadLDescription(userData?.user_id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [newDescription, setNewDescription] = useState(data || "");
  const { mutate: updateDescription } = useUpdateDescription();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    updateDescription({
      tagString: description,
      user_id: userData?.user_id,
      pageIndex: 1,
      index: 1,
    });
    refetch();

    setIsModalOpen(false);
  };
  const handleSaveCancel = () => {
    setIsEdit(false)
  }
  const handleUpdate = () => {
    updateDescription({
      tagString: newDescription,
      user_id: userData?.user_id,
      pageIndex: 1,
      index: 1,
    });
    refetch();
    setIsEdit(false);
    // setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div>
        <div className="account-social-logins-heading">
          <h5>
            Add Profile Description{" "}
            </h5>
            {data ? (
              <>
                {!isEdit ? (
                  <Button
                    className="btn-primary ml-1"
                    onClick={() => {
                      setNewDescription(data)
                      setIsEdit(true);
                    }}
                  >
                    Edit
                  </Button>
                ) : (
                  <>
                    <Button
                      className="btn-primary"
                      onClick={() => {
                        //   setIsEdit(false);
                        handleUpdate();
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      className="btn-primary"
                      onClick={() => {
                        //   setIsEdit(false);
                        handleSaveCancel();
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </>
            ) : (
              <Button
                className="btn-primary"
                onClick={showModal}
              >
                Add
              </Button>
            )}

        </div>
        {isLoading ? (
          <div
            className="spin-style"
          >
            <Spin />
          </div>
        ) : (
          <>
            {data ? (
              <>
                <div className="account-social-content-connect">
                  <React.Fragment>
                    {/* <div className="account-social-link-connect-row"> */}
                    <div
                      className="account-social-link-connect-row-cont textarea"
                    >
                      {isEdit ? (
                        <TextArea
                          value={
                            newDescription
                          }
                          onChange={(e: any) =>
                            setNewDescription(e.target.value)
                          }
                        />
                      ) : (
                        <div className="account-social-link-connect-row">
                            <p>{data}</p>
                          </div>
                      )}
                    </div>
                    {/* </div> */}

                    <hr
                      className="hr-style"
                    />
                  </React.Fragment>
                </div>
              </>
            ) : (
              <div
                className="account-social-content-connect social-content-color"
              ></div>
            )}
          </>
        )}{" "}
      </div>
      <Modal
        title="Add description"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add"
      >
        <TextArea
          rows={4}
          placeholder="Enter your description here"
          onChange={(e: any) => setDescription(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default AccountDescription;
