import dayjs from "dayjs";
import { useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";

export function useLoadStayUpToDateComponents(
  username: any,
  user_id: any,
  status?: any
) {
  async function getStayUpToDateComponents() {
    let componentsOrder: any = [];
    let showingComponents: any = [];
    let archiveComponents: any = [];
    let draftComponents: any = [];
    let activeDraftComponents: any = [];

    const { data, error }: any = await supabase
      .from("stayuptodate_components")
      .select("*")
      .eq("user_id", user_id)
      .order("index");
    if (data && data.length) {
      for (let i of data) {
        componentsOrder.push(i.name);
        if (i.status === "archive") {
          archiveComponents.push(i);
        } else {
          showingComponents.push(i);
        }
      }
    }
    if (data.length === 0) {
      await supabase
        .from("controls_live")
        .update({ initial_content2: "Feed" })
        .eq("user_id", user_id);
    }
    return {
      order: componentsOrder,
      data: data,
      showingComponents: showingComponents,
      archiveComponents: archiveComponents,
    };
  }

  return useQuery(
    ["StayUpTpDateComponents", user_id],
    getStayUpToDateComponents,
    {}
  );
}
