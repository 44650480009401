import { Button, List, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { GrNext } from "react-icons/gr";
import YesterdayLinksComponents from "./SubListComponents/YesterdayLinksComponents";
import ThisWeekLinksComponents from "./SubListComponents/ThisWeekLinksComponents";
import DayWiseLinksComponents from "./SubListComponents/DayWiseLinksComponents";
import CustomLinksComponents from "./SubListComponents/CustomLinksComponents";
import "../../StayUpToDateControls.css"
import QueryLinksComponents from "./SubListComponents/QueryLinksComponents";
import TrendingProductsComponent from "./SubListComponents/TrendingProductsComponent/TrendingProductsComponent";

const ShopLinksComponents = (props: any) => {
  const { isShoplinksModalOpen, username, user_id ,StayUpToDateComponents} = props;

  const [isYesterdayLinksComponentOpen, setIsYesterdayLinksComponentOpen] =
    useState(false);
  const [isThisWeekLinksComponentOpen, setIsThisWeekLinksComponentOpen] =
    useState(false);
  const [isDayWiseLinksComponentOpen, setIsDayWiseLinksComponentOpen] =
    useState(false);
    const [isCustomLinksComponentOpen, setIsCustomLinksComponentOpen] =
    useState(false);
    const [isQueryLinksComponentOpen, setIsQueryLinksComponentOpen] =
    useState(false);
    const [isTrendingProductsModalOpen, setIsTrendingProductsModalOpen] =
    useState(false);

  const handleYesterdayLinks = () => {
    setIsYesterdayLinksComponentOpen(true);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
    setIsThisWeekLinksComponentOpen(false);
  };
  const handleThisWeekLinks = () => {
    setIsThisWeekLinksComponentOpen(true);
    setIsYesterdayLinksComponentOpen(false);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
  };
  const handleDayWiseLinks = () => {
    setIsDayWiseLinksComponentOpen(true);
    setIsThisWeekLinksComponentOpen(false);
    setIsYesterdayLinksComponentOpen(false);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
  };
  const handleCustomLinks = () => {
    setIsCustomLinksComponentOpen(true)
    setIsDayWiseLinksComponentOpen(false);
    setIsThisWeekLinksComponentOpen(false);
    setIsYesterdayLinksComponentOpen(false);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
  };
  const handleQueryLinks = () => {
    setIsQueryLinksComponentOpen(true)

    setIsCustomLinksComponentOpen(false)
    setIsDayWiseLinksComponentOpen(false);
    setIsThisWeekLinksComponentOpen(false);
    setIsYesterdayLinksComponentOpen(false);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
  };
  function handleTrendingProducts() {
    setIsTrendingProductsModalOpen(true)
    setIsQueryLinksComponentOpen(false)

    setIsCustomLinksComponentOpen(false)
    setIsDayWiseLinksComponentOpen(false);
    setIsThisWeekLinksComponentOpen(false);
    setIsYesterdayLinksComponentOpen(false);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
  }
  const data = [
    {
      // icon:,
      title: "Yesterday's Shoppable Content",
      description: "Automated",
      component: handleYesterdayLinks,
      disabled:StayUpToDateComponents?.data.some((obj :any)=> obj.display_name === 'Yesterday')

      //   task: true,
    },
    {
      // icon:,
      title: "This Week's Shoppable Content",
      description: "Automated",
      component: handleThisWeekLinks,
      disabled:StayUpToDateComponents?.data.some((obj :any)=> obj.display_name === 'This Week')

    },
    {
      // icon:,
      title: "Shoppable Content by the Day",
      description: "Automated",
      task: true,
      component: handleDayWiseLinks,
    },
    {
      title: "Trending Products",
      description: "Automated",
      task: true,
      component: handleTrendingProducts,
      disabled:StayUpToDateComponents?.data.some((obj :any)=> obj.display_name === 'Trending Products')

    },
    {
      // icon:,
      title: "Show Shoppable Content Based on a Specific Word or Phrase",
      description: "Automated",
      task: true,
      component: handleQueryLinks,
    },
    {
      // icon:,
      title: "Custom Shoppable Content Carousel",
      description: "Manual | Create a carousel using any of your shoppable content",
      task: true,
      component: handleCustomLinks,
    },

    // {
    //   // icon:,
    //   title: "Ant Design Title 4",
    // },
  ];

  const handleOk = () => {
    props.setIsShoplinksModalOpen(false);
  };

  const handleCancel = () => {
    props.setIsShoplinksModalOpen(false);
  };
  const handlePrevious = () => {
    props.setIsModalOpen(true);
    props.setIsShoplinksModalOpen(false);
  };

  function handleTask(title: any) {
  }
  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <button onClick={handlePrevious} className="newbutton mr-auto">
              <FaArrowLeft />
            </button>
            {/* <span>Basic Modal</span> */}
          </div>
        }
        open={isShoplinksModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <h5 className="title mb-20" >Shoppable Carousel Options</h5>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item>
              <Button
                className="item-disabled"
                type="text"
                onClick={item.component}
                disabled={item.disabled}
              >
                <div
                  className="list-item"
                >
                  <p className="m-0">{item.title}</p>
                  <p className="item-disc">{item.description}</p>
                  {item.task && (
                    <button
                      onClick={() => handleTask(item.title)}
                      className="item-task"
                    >
                      {/* <GrNext /> */}
                    </button>
                  )}
                </div>
              </Button>
            </List.Item>
          )}
        />
      </Modal>
      <YesterdayLinksComponents
        isYesterdayLinksComponentOpen={isYesterdayLinksComponentOpen}
        setIsYesterdayLinksComponentOpen={setIsYesterdayLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}

      />{" "}
      <ThisWeekLinksComponents
        isThisWeekLinksComponentOpen={isThisWeekLinksComponentOpen}
        setIsThisWeekLinksComponentOpen={setIsThisWeekLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}

      />
      <DayWiseLinksComponents
        isDayWiseLinksComponentOpen={isDayWiseLinksComponentOpen}
        setIsDayWiseLinksComponentOpen={setIsDayWiseLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        StayUpToDateComponents={StayUpToDateComponents}

      />
       <CustomLinksComponents
        isCustomLinksComponentOpen={isCustomLinksComponentOpen}
        setIsCustomLinksComponentOpen={setIsCustomLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}

      />
        <QueryLinksComponents
        isQueryLinksComponentOpen={isQueryLinksComponentOpen}
        setIsQueryLinksComponentOpen={setIsQueryLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}

      />
         <TrendingProductsComponent
          isTrendingProductsModalOpen={isTrendingProductsModalOpen}
          setIsTrendingProductsModalOpen={setIsTrendingProductsModalOpen}
          setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
          user_id={user_id}
          username={username}
          refetch={props.refetch}
          />

    </>
  );
};

export default ShopLinksComponents;
