import { Button, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { supabase } from "../../../config/supabaseClient";

const ManageLinksModal = (props: any) => {
  const { selectedPost, user_id, username, linksModal } = props;
  const [newPermalink, setNewPermalink] = useState("");
  const [newShoplink, setNewShoplink] = useState("");
  const [newTaggedLink, setNewTaggedLink] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  useEffect(() => {
    setNewPermalink(selectedPost?.permalink);
    setNewShoplink(selectedPost?.shop_link);
    setNewTaggedLink(selectedPost?.tagged_link);
  }, [selectedPost, linksModal]);
  async function handleLinksOk() {
    setConfirmLoading(true);
    const updatedPost = {
      ...selectedPost,
      permalink: newPermalink,
      shop_link: newShoplink,
      tagged_link: newTaggedLink,
    };
    await supabase
      .from("content_new")
      .update({
        permalink: newPermalink,
        shop_link: newShoplink,
        tagged_link: newTaggedLink,
      })
      .eq("post_id", selectedPost.post_id)
      .eq("user_id", user_id);

    await supabase
      .from(`${username}`)
      .update({
        permalink: newPermalink,
        shop_link: newShoplink,
        tagged_link: newTaggedLink,
      })
      .eq("post_id", selectedPost.post_id)
      .eq("user_id", user_id);
    props.setSelectedPost(updatedPost);

    props.setLinksModal(false);
    setConfirmLoading(false);
    props.refetchData();

    setNewPermalink("");
    setNewShoplink("");
    setNewTaggedLink("");
  }
  async function handleLinksCancel() {
    props.setLinksModal(false);
    setNewPermalink("");
    setNewShoplink("");
    setNewTaggedLink("");
  }
  return (
    <>
      <Modal
        open={linksModal}
        onOk={handleLinksOk}
        onCancel={handleLinksCancel}
        bodyStyle={{
          overflowY: "auto",
          maxHeight: "calc(100vh - 300px)",
        }}
        confirmLoading={confirmLoading}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={handleLinksOk}
          >
            Save
          </Button>,
        ]}
      >
        <>
          <h5 className="text-center mb-4">
            Manage Links
          </h5>
            <label className="bold-label">
              Permalink
            </label>
            {selectedPost?.permalink ? (
              <Input
                value={newPermalink}
                onChange={(e: any) => {
                  setNewPermalink(e.target.value);
                }}
                placeholder="enter permalink here"
                className="mb-20"
              />
            ) : (
              <>
                {" "}
                <Input
                  value={newPermalink}
                  onChange={(e: any) => {
                    setNewPermalink(e.target.value);
                  }}
                  placeholder="enter permalink here"
                  className="mb-20"
                />
              </>
            )}

            <label className="bold-label">
              Shop Link
            </label>
            {selectedPost?.shop_link ? (
              <>
                <Input
                  value={newShoplink}
                  onChange={(e: any) => {
                    setNewShoplink(e.target.value);
                  }}
                  placeholder="enter shoplink here"
                  className="mb-20"
                />
              </>
            ) : (
              <>
                {" "}
                <Input
                  value={newShoplink}
                  onChange={(e: any) => {
                    setNewShoplink(e.target.value);
                  }}
                  placeholder="enter shoplink here"
                  className="mb-20"
                />
              </>
            )}

            <label className="bold-label">
              Tagged Account Link
            </label>
            {selectedPost?.tagged_link ? (
              <>
                <Input
                  value={newTaggedLink}
                  onChange={(e: any) => {
                    setNewTaggedLink(e.target.value);
                  }}
                  placeholder="enter tagged link here"
                  className="mb-20"
                />
              </>
            ) : (
              <>
                {" "}
                <Input
                  value={newTaggedLink}
                  onChange={(e: any) => {
                    setNewTaggedLink(e.target.value);
                  }}
                  placeholder="enter tagged link here"
                  className="mb-20"
                />
              </>
            )}
        </>
      </Modal>
    </>
  );
};

export default ManageLinksModal;
