import { Button, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import "../../../StayUpToDateControls.css";
import { addComponent } from "../../../../../../Utils/SupabaseServices/StayUpToDateDatabase";

const YesterdayLinksComponents = (props: any) => {
  const { username, user_id, isYesterdayLinksComponentOpen } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await addComponent(
      "Yesterday's Shoppable Content",
      "",
      username,
      user_id,
      "true",
      "Check it out!",
      "",
      "Yesterday"
    );
    if (res === "true") {
      props.refetch();
      props.setIsShoplinksModalOpen(false);
      props.setIsYesterdayLinksComponentOpen(false);
      setConfirmLoading(false);

    }

  };

  const handleCancel = () => {
    props.setIsShoplinksModalOpen(false);
    props.setIsYesterdayLinksComponentOpen(false);
  };
  const handlePrevious = () => {
    props.setIsYesterdayLinksComponentOpen(false);
    props.setIsShoplinksModalOpen(true);
  };

  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <button
              onClick={handlePrevious}
              className="newbutton"
            >
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isYesterdayLinksComponentOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
      >
        <h5 className="title mb-20">
          Yesterday's Shoppable Content
        </h5>
        <p  className="text-center">
          All of your shoppable content posted yesterday will always be shown in this carousel. Content will automatically be updated!
        </p>
      </Modal>
    </>
  );
};

export default YesterdayLinksComponents;
