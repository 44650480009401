import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";

export function useLoadCustomProduct(
  user_id: string,
  username: any,
  title:any
) {
 
  const fetchProductBankPosts = async () => {
  
    const { data, error } = await supabase.rpc("get_queryproductbankpostssss", {
      query_string: "",
      userid: user_id,

      title:title,
      query_tagg:""
    });

    if (error) {
      throw new Error(error.message);
    }
    const seen = new Set();
    const uniquePosts = data.filter((post:any) => {
      const duplicate = seen.has(post.table_id);
      seen.add(post.table_id);
      return !duplicate;
    });
    return uniquePosts;

  };
  return useQuery(
    ["CustomProducts", user_id, title],
    fetchProductBankPosts,
    {enabled:Boolean(title)}
  );
  
}
