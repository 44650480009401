import React, { useRef, useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { useLoadPartialTableCount } from "../Feed/useLoadPartialTableCount";
import { useLoadFirstEmbedding } from "./useLoadFirstSimilarity";
import { useLoadEmbeddingCount } from "./useLoadEmbeddingCount";
import { useLoadPages } from "./useLoadPages";
import { useLoadPosts } from "./useLoadPosts";
import { useLoadNecData } from "./useLoadNecData";
import { useLoadEmbeddingPosts } from "./useLoadEmbeddingPosts";
import { useLoadUser } from "../../../Utils/customHooks/useLoadUser";
import { Modal, Pagination, PaginationProps } from "antd";
import { ContentSkeleton } from "../../../components/skeleton/DashboardView/feedSkeleton";
import TabOptions from "../Feed/TabOptions";
import SearchInput from "../../../components/SearchInput/SearchInput";
import BrokenLinkContent from "../Feed/BrokenLinkContent";
import ContentSearchTable from "./ContentSearchTable";
import { useLoadBrokenPages } from "./useLoadBrokenPages";
import { useLoadBrokenPosts } from "./useLoadBrokenPosts";
import { StaticVariables } from "../../../Utils/StaticVariables";

const POSTS_PER_PAGE = 10;

const FeedNew = (props: any) => {
  const { subscription_type } = props;
  const { user } = useAuth();
  const { username } = useParams();
  const user_id = user?.id || "";
  const [activeKey, setActiveKey] = useState<any>("active");
  const [isLimitError, setLimitError] = useState(false);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [currentBrokenPage, setCurrentBrokenPage] = useState(1);
  const [currentDataPage, setCurrentDataPage] = useState(1);

  const handleOk = () => {
    setLimitError(false);
  };
  const handleCancel = () => {
    setLimitError(false);
  };
  const { isLoading: userLoading, data: userData }: any = useLoadUser(username);
  const [iscontentLoading, setIsContentLoading] = useState(false);
  const { data: brokenPages, isLoading: brokenPagesLoading } =
    useLoadBrokenPages(
      user_id || "",
      activeKey,
      POSTS_PER_PAGE,
      activeFilter,
      username
    );
  const {
    isLoading: brokenDataLoading,
    // isError,

    data: brokenData,
    hasNextPage: hasNextBrokenPage,
    fetchNextPage: fetchNextBrokenPage,
    // isFetching,
    isFetchingNextPage: isFetchingNextBrokenPage,
    refetch: refetchBrokenData,
  } = useLoadBrokenPosts(
    currentBrokenPage,
    activeKey,
    POSTS_PER_PAGE,
    brokenPages?.totalPages,
    activeFilter,
    username,
    user_id || "",
    brokenPages?.postIds
  );
  const { value }: any = useLoadPartialTableCount(
    user_id,

    username
  );

  const { isLoading: pagesLoading, data: pages } = useLoadPages(
    user_id || "",
    activeKey,
    POSTS_PER_PAGE,
    activeFilter,
    username
  );
  const { data: firstEmbedding }: any = useLoadFirstEmbedding(
    username || "",
    user_id || "",
    activeFilter,

    ["instagram", "youtube", "tiktok", "podcast", "pinterest", "newsletter"],
    pages?.feedCount
  );

  const { isLoading: pagesEmbedLoading, data: pagesEmbed } =
    useLoadEmbeddingCount(
      user_id || "",
      activeKey,
      POSTS_PER_PAGE,
      activeFilter,
      username,
      firstEmbedding?.threshold
    );

  const {
    isLoading,
    // isError,
    error,
    data,
    hasNextPage,
    fetchNextPage,
    // isFetching,
    isFetchingNextPage,
    refetch,
  } = useLoadPosts(
    currentDataPage,
    activeKey,
    POSTS_PER_PAGE,
    pages?.totalPages,
    activeFilter,
    username,
    user_id || "",
    pages?.postIds
  );

  const {
    isLoading: necLoading,
    // isError,
    error: necError,
    data: necData,

    refetch: refetchNec,
  }: any = useLoadNecData(
    user_id || "",
    activeKey,
    POSTS_PER_PAGE,
    activeFilter,
    username
  );
  const {
    isLoading: embedLoading,
    // isError,
    error: embedError,
    data: embedData,
    hasNextPage: hasEmbedNextPage,
    fetchNextPage: fetchEmbedNextPage,
    // isFetching,
    // isFetchingNextPage,
    refetch: refectchEmbed,
  }: any = useLoadEmbeddingPosts(
    currentDataPage,
    activeKey,
    POSTS_PER_PAGE,
    pagesEmbed?.totalPages,
    activeFilter,
    username,
    user_id || "",
    pagesEmbed?.postIds,
    firstEmbedding?.threshold
  );

  let necDataIncluded = false;

  const loadingRef = useRef(true); // useRef for mutable reference

  const logProcessStartEnd = (fn: any, ...args: any) => {
    let result;
    try {
      result = fn(...args);

      loadingRef.current = false; // Set loading to false

      // setMergedDataLoading(result); // Set the result in state
    } catch (error) {
      console.error(`Error in ${fn.name}:`, error);
    } finally {
      loadingRef.current = false; // Set loading to false
    }

    return result;
  };
  const mergedData: any =
    pages && pages?.totalPages !== 0 && data?.pages
      ? logProcessStartEnd(
          processPages,
          data.pages,
          pages?.feedCount,
          necData,
          embedData?.pages
        )
      : pagesEmbed && pagesEmbed?.totalPages != 0
      ? logProcessStartEnd(
          processEmbeddingPages,
          embedData?.pages,
          pagesEmbed?.feedCount,
          necData
        )
      : necData?.length > 0
      ? logProcessStartEnd(processNecData, necData)
      : "";

  function processPages(
    pagesData: any[],
    dataCount: any,
    necData: any[],
    embeddingData?: any[]
  ) {
    return pagesData
      ? pagesData
          .flatMap((page: any) => page)
          .filter((post: any) => post?.post_id)
          .reduce((result: any[], post: any, index: number) => {
            const isAllowedDuplicate = post.index === "active,ad";
            const isDuplicate = result.some(
              (uniquePost) => uniquePost.post_id === post?.post_id
            );

            if (!isDuplicate || isAllowedDuplicate) {
              if (result.length + 1 < dataCount) {
                result.push(post);
              } else {
                result.push(post);

                if (!necLoading) {
                  if (necData && necData.length && !necDataIncluded) {
                    const filteredNecData = necData.filter(
                      (necPost) =>
                        !result.some(
                          (resPost) => resPost?.post_id === necPost?.post_id
                        )
                    );
                    filteredNecData.forEach((necPost) => {
                      result.push(necPost);
                    });
                    necDataIncluded = true;
                  } else {
                    if (embeddingData?.length) {
                      embeddingData
                        .flatMap((page: any) => page)
                        .filter((post: any) => post?.post_id)
                        .reduce((embedResult: any[], post: any) => {
                          const isAllowedDuplicate = post.index === "active,ad";
                          const isDuplicate = result.some(
                            (uniquePost) => uniquePost.post_id === post?.post_id
                          );
                          if (!isDuplicate || isAllowedDuplicate) {
                            result.push(post);
                          }
                        });
                    }
                  }
                }
                if (necDataIncluded && embeddingData?.length) {
                  embeddingData
                    .flatMap((page: any) => page)
                    .filter((post: any) => post?.post_id)
                    .reduce((embedResult: any[], post: any) => {
                      const isAllowedDuplicate = post.index === "active,ad";
                      const isDuplicate = result.some(
                        (uniquePost) => uniquePost.post_id === post?.post_id
                      );
                      if (!isDuplicate || isAllowedDuplicate) {
                        result.push(post);
                      }
                    });
                }
              }
            }

            return result;
          }, [])
      : "";
  }
  function processEmbeddingPages(
    pagesData: any[],
    dataCount: any,

    necData: any[]
  ) {
    return pagesData
      ? pagesData
          .flatMap((page: any) => page)
          .filter((post: any) => post?.post_id)
          .reduce((result: any[], post: any) => {
            const isAllowedDuplicate = post?.index === "active,ad";
            const isDuplicate = result.some(
              (uniquePost) => uniquePost?.post_id === post?.post_id
            );
            if (!isDuplicate || isAllowedDuplicate) {
              if (userData?.is_nec_present) {
                if (
                  necData !== undefined &&
                  necData &&
                  necData.length &&
                  !necDataIncluded
                ) {
                  const filteredNecData = necData.filter(
                    (necPost) =>
                      !result.some(
                        (resPost) => resPost?.post_id === necPost?.post_id
                      )
                  );
                  // result.push(...filteredNecData);
                  filteredNecData.forEach((necPost) => {
                    result.push(necPost);

                    // Add an ad after every 10 posts
                  });
                  necDataIncluded = true;
                } else {
                  if (
                    !result.some(
                      (resPost) => resPost?.post_id === post?.post_id
                    )
                  ) {
                    result.push(post);
                  }
                }

                if (necDataIncluded) {
                  if (
                    !result.some(
                      (resPost) => resPost?.post_id === post?.post_id
                    )
                  ) {
                    result.push(post);
                  }
                }
              } else {
                if (
                  !result.some((resPost) => resPost?.post_id === post?.post_id)
                ) {
                  result.push(post);
                }
              }
            }

            return result;
          }, [])
      : "";
  }
  function processNecData(necData: any[]) {
    return necData
      ? necData
          .flatMap((page: any) => page)
          .filter((post: any) => post?.post_id)
          .reduce((result: any[], post: any, index: number) => {
            const isAllowedDuplicate = post.index === "active,ad";
            const isDuplicate = result.some(
              (uniquePost) => uniquePost.post_id === post?.post_id
            );

            if (!isDuplicate || isAllowedDuplicate) {
              if (necData && necData.length && !necDataIncluded) {
                const filteredNecData = necData.filter(
                  (necPost) =>
                    !result.some(
                      (resPost) => resPost?.post_id === necPost?.post_id
                    )
                );
                // result.push(...filteredNecData);
                filteredNecData.forEach((necPost) => {
                  result.push(necPost);

                  // Add an ad after every 10 posts
                });
                necDataIncluded = true;
              }
            }

            return result;
          }, [])
      : "";
  }
  const handleActiveFilter = () => {
    // const elem: any = document.getElementById("search-feed");
    // elem.value = "";
    // setActiveFilter(null);
    // setActiveFilter("")
  };

  const handlePageChange = (page: any) => {
    setCurrentBrokenPage(page);
  };
  const handlePageDataChange = (page: any) => {
    setCurrentDataPage(page);
  };


  return (
    <div>
      <div className="d-side">
        <h1 className="dashboard-mobile-hide">
          {StaticVariables().CONTENT_TITLE}
        </h1>

        <TabOptions
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          handleActiveFilter={handleActiveFilter}
          subscription_type={subscription_type}
          brokenLinksContent={brokenData}
          loadingRef={loadingRef}
          setActiveFilter={setActiveFilter}
        />
      </div>

      <div style={{ margin: "0 0 40px 0" }}>
        <SearchInput
          placeholder={
            activeKey === "active,ad"
              ? `Search all ads`
              : activeKey === "active,featured"
              ? `Search all featured content`
              : `Search all ${activeKey} content`
          }
          id="search-feed"
          handleKeyUp={(e: any) => {
            e.preventDefault();
            if (e.keyCode === 13) {
              const elem: any = document.getElementById("search-feed");
              const searchVal = e.target.value;
              if (searchVal.length === 0) {
                elem.blur();
                setActiveFilter(null);
                return;
              }
              elem.blur();
              setActiveFilter(searchVal);
            }
          }}
          isActiveFilter={Boolean(activeFilter)}
          onSearchClick={() => {
            const elem: any = document.getElementById("search-feed");
            const searchVal = elem.value;
            if (searchVal.length === 0) {
              return;
            }
            elem.blur();
            setActiveFilter(searchVal);
          }}
          onClearClick={() => {
            const elem: any = document.getElementById("search-feed");
            elem.value = "";
            setActiveFilter(null);
          }}
        />
      </div>

      {activeKey === "broken_links" ? (
        <>
          {brokenDataLoading || brokenPagesLoading ? (
            <ContentSkeleton />
          ) : (
            <>
              <BrokenLinkContent
                username={username}
                user_id={user_id}
                activeKey={activeKey}
                data={brokenData}
                setLimitError={setLimitError}
                filter={activeFilter}
                refetchData={refetchBrokenData}
                setIsContentLoading={setIsContentLoading}
                subscription_type={subscription_type}
                brokenDataLoading={brokenDataLoading && brokenPagesLoading}
                // brokenLinksContent={aaa}
              />
              {brokenPages?.feedCount > POSTS_PER_PAGE && (
                <Pagination
                  showQuickJumper
                  current={currentBrokenPage}
                  pageSize={POSTS_PER_PAGE}
                  total={brokenPages?.feedCount}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                  style={{
                    margin: "10px",
                    display: "block",
                    textAlign: "center",
                  }}
                />
              )}
            </>
          )}
        </>
      ) : (
        <>
          {" "}
          {isLoading ||
          pagesLoading ||
          pagesEmbedLoading ||
          iscontentLoading ? (
            <ContentSkeleton />
          ) : value ? (
            <>
              <ContentSearchTable
                username={username}
                user_id={user_id}
                activeKey={activeKey}
                data={mergedData}
                setLimitError={setLimitError}
                filter={activeFilter}
                refetchData={refetch}
                setIsContentLoading={setIsContentLoading}
                subscription_type={subscription_type}
                isLoading={isLoading}
                loadingRef={loadingRef.current}
                totalSize={
                  (pages?.feedCount || 0) + (pagesEmbed?.feedCount || 0)
                }
              />
              {(pages?.feedCount || 0) + (pagesEmbed?.feedCount || 0) >
                POSTS_PER_PAGE && (
                <Pagination
                  showQuickJumper
                  current={currentDataPage}
                  pageSize={POSTS_PER_PAGE}
                  total={(pages?.feedCount || 0) + (pagesEmbed?.feedCount || 0)}
                  onChange={handlePageDataChange}
                  showSizeChanger={false}
                  style={{
                    margin: "10px",
                    display: "block",
                    textAlign: "center",
                  }}
                />
              )}
            </>
          ) : (
            ""
          )}
        </>
      )}
      <Modal
        title="Only 2 posts can be added as ads."
        open={isLimitError}
        onOk={handleOk}
        onCancel={handleCancel}
        className="account-modal"
      ></Modal>
    </div>
  );
};

export default FeedNew;
