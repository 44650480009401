import React, { useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { HiCheck, HiPencil } from "react-icons/hi";
import { updateEmail } from "../../../../Utils/SupabaseServices/UserDatabase";
import validator from "validator";
import { supabase } from "../../../../config/supabaseClient";
import { Button, Modal } from "antd";

const Email = (props: any) => {
  const { user_id, username, userData, setIsEmail, setIsEmailVerified } = props;
  const [email, setEmail] = useState(userData?.email || "");

  const [editEmail, setEditEmail] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [isEmailVerify, setIsEmailVerify] = useState<any>();
  const [otp, setOTP] = useState("");
  const [modalError, setModalError] = useState("");
  const [modalContent, setModalContent] = useState(false);
  const [isTabsLocked, setIsTabsLocked] = useState<any>();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const data = localStorage.getItem("email_verify");
      if (data === "false") {
        setIsTabsLocked(true);
      } else {
        setIsTabsLocked(false);
      }
    };

    // Add event listener for storage changes
    window.addEventListener("storage", handleStorageChange);

    // Initial setup (run once)
    handleStorageChange();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [window]);

  const validateEmail = async (email: any) => {
    localStorage.setItem("email_verify", "false");
    if (validator.isEmail(email)) {
      return true;
    } else {
      setModalTitle("Enter Valid Email!");
      showModal();
      setIsEmail(false);
      await updateEmail(user_id, username, email);
    }
  };

  async function sendEmailOTP() {
    setOTP("");
    setModalError("");
    setIsModalOpen(true);
    setModalContent(true);
    setModalTitle("Verify Email");

    const { data, error } = await supabase.auth.signInWithOtp({
      email: email,
    });
    if (error !== null) {
      setModalError("Error in sending OTP. Try again after 60 seconds");
    }
  }

  function handleUpdateValue(e: any, option: any) {
    if (option === "email") {
      setEmail(e.target.value);
    }
  }
  const doneEdit = async (option: any) => {
    if (option === "email") {
      if (email === "") {
        setModalTitle("Enter Email!");
        showModal();
        setIsEmailVerify("");
        setIsEmail(false);

        await updateEmail(user_id, username, email);
      } else {
        if (await validateEmail(email)) {
          await updateEmail(user_id, username, email);
          setIsEmailVerify(false);
          setIsEmailVerified(false);
        }
      }
      setEditEmail(false);
    }
  };

  async function verifyEmail() {
    try {
      await supabase.auth
        .verifyOtp({ email: email, token: otp, type: "email" })
        .then(async (res: any) => {
          if (res.error === null) {
            const { data, error } = await supabase
              .from("user")
              .update({
                // email: `${email}`,
                // emailSignup: `${email}`,
                email_verify: true,
              })
              .eq("username", `${username}`);

            // supabase.auth.admin.deleteUser(user_id)
            localStorage.removeItem("email_verify");

            setIsModalOpen(false);
            props.setIsEmail(true);
            props.setIsEmailVerified(true);

            setIsEmailVerify(true);
          } else {
            setModalError("Invalid OTP");
          }
        });
    } catch (error) {}
  }

  return (
    <div className="account-grid-item">
      <div>
        <h3 className="account-grid-item-key">
          Email{" "}
          <BsInfoCircle className="info-tooltip" data-tooltip-id="email" />
        </h3>
        <h3 className="account-grid-item-value">
          {!editEmail ? (
            email ? (
              email
            ) : (
              "Add Email"
            )
          ) : (
            <input
              type="text"
              className="account-value-input"
              onChange={(e: any) => handleUpdateValue(e, "email")}
              value={email}
              required
            />
          )}
          {!editEmail ? (
            // <button
            //   className="account-edit-btn-email"
            //   onClick={() => {
            //     setEditEmail(true);
            //   }}
            // >
            // {/* <HiPencil /> */}
            <Button
            className="changeButton"
              onClick={() => {
                setEditEmail(true);
              }}
            >
              Change
            </Button>
          ) : (
            <div 
            className="saveButtonDiv"
            >
                <Button
                  className="saveButton"
                  onClick={() => {
                    setEditEmail(false);
                    doneEdit("email");
                  }}
                >
                  Save
                </Button>
                <Button
                  className="saveButton"
                  onClick={() => {
                    setEditEmail(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
          )}{" "}
          {isEmailVerify === false || isTabsLocked ? (
            <button className="verify-btn" onClick={sendEmailOTP}>
              Verify Email
            </button>
          ) : isEmailVerify === true ? (
            <p className="verified-text">Verified</p>
          ) : (
            ""
          )}
        </h3>
      </div>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="account-modal"
        footer={null}
      >
        {modalContent === true ? (
          <>
            <input
              type="text"
              className="otp-input"
              placeholder="Enter 6 digit OTP"
              onChange={(e: any) => setOTP(e.target.value)}
              value={otp}
            />
            <button className="modal-verify-btn" onClick={verifyEmail}>
              Verify
            </button>
          </>
        ) : (
          ""
        )}
        {modalError ? (
          <p className="errorText">
            {modalError}
          </p>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default Email;

// 		{modalTitle.includes("Phone") ? (
// 			<>
// 				<input
// 					type='text'
// 					className='otp-input'
// 					placeholder='Enter 6 digit OTP'
// 					onChange={(e: any) => setOTP(e.target.value)}
// 					value={otp}
// 				/>
// 				<button
// 					className='modal-verify-btn'
// 					onClick={verifyPhoneNumber}
// 				>
// 					Verify
// 				</button>
// 			</>
// 		) : modalTitle.includes("Email") ? (
// 			<>

//                  : (
// 	""
// )}
