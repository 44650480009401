import {
  Alert,
  Button,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  Spin,
} from "antd";
import React, { useState } from "react";

import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { storeTiktokUser } from "../../../Utils/SupabaseServices/ContentDatabase";

const TiktokIntegrationModal = (props: any) => {
  const { user } = useAuth();
  const { username } = useParams();
  const { showTiktokModal, user_id, data } = props;
  const [tiktokUser, setTiktokUser] = useState(data?.tiktok_user);
  const [errorTiktok, setErrorTiktok] = useState("");
  const [loadingTiktokConnect, setLoadingTiktokConnect] = useState(false);
  const [integrateTiktokMsg, setIntegrateTiktokMsg] = useState("");

  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  // const onChange = async (e: RadioChangeEvent) => {
  //   setValue(e.target.value);
  // };

  const handleOk = async () => {
    setShowIntegrationModal(false);
  };

  const handleCancel = () => {
    setShowIntegrationModal(false);
  };
  return (
    <>
      <Modal
        title="Connect to Tiktok"
        open={showTiktokModal}
        onOk={async () => {
          setLoadingTiktokConnect(true);

          const res = await storeTiktokUser(user?.id as string, tiktokUser,username);
          if (!res) {
            setIntegrateTiktokMsg("");
            setErrorTiktok("error while connecting to this Tiktok user");
          } else {
            setIntegrateTiktokMsg(
              "Your Tiktok data will be integrated in your feed within 24 hours!"
            );
              props.setShowTiktokModal(false);
              setShowIntegrationModal(true)
          }

          setLoadingTiktokConnect(false);
        }}
        onCancel={() => {
          setErrorTiktok("");
          props.setShowTiktokModal(false);

        }}
        okText="Connect"
        okButtonProps={{ loading: loadingTiktokConnect }}
        cancelButtonProps={{ disabled: loadingTiktokConnect }}
      >
        <p>
          By connecting your Tiktok username, all your Tiktok data will be
          integrated and shown as posts in the feed of your main page. In the
          content tab you will be able to hide or delete the tiktoks you don't
          want to show.{" "}
        </p>
        {loadingTiktokConnect ? (
          <div
            className="spin-integration"
          >
            <Spin />
          </div>
        ) : (
          <>
            {errorTiktok && (
              <Alert
                type="error"
                message={`Error: ${errorTiktok}`}
                showIcon
                className="alert-margin"
              />
            )}
            {data?.tiktok_user ? (
              <>
                <p>
                  <b>Already connected Tiktok username: {data?.tiktok_user}</b>
                </p>
                <p>New name will connection will replace this one.</p>
              </>
            ) : (
              ""
            )}
            <p>To connect just enter your Tiktok username :</p>

            <Input
              placeholder="username"
              // value={TiktokId}
              onChange={(e) => {
                e.target.value.length
                  ? setTiktokUser(e.target.value)
                  : setErrorTiktok("Enter valid name");
              }}
            />
          </>
        )}
        {/* <Button type="primary" onClick={showModal}>
          Open Modal
        </Button> */}
      </Modal>
      <Modal
        title={tiktokUser ? `${tiktokUser}'s Tiktoks` : ""}
        open={showIntegrationModal}
        onOk={handleOk}
        okText="Ok"
        onCancel={handleCancel}
      >
        <>
          <p>{integrateTiktokMsg} </p>
        </>
      </Modal>
    </>
  );
};

export default TiktokIntegrationModal;
