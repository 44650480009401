import { Col, Pagination, Row, Spin } from "antd";
import "../Charts.css";
import { useMemo, useState } from "react";
import React, { useEffect } from "react";

function ChartClicksTable(props: any) {
  const { chartClicks, loadingPostClicksAnalytics, defaultCurrentPage,setDefaultCurrentPage } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20; // Number of items per page
  // Calculate the start and end indices for the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = currentPage * pageSize;
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setDefaultCurrentPage(page)
  };
  useEffect(() => {
    setCurrentPage(defaultCurrentPage);
  }, [defaultCurrentPage]);

  const totalSum = useMemo(() => {
    return (
      chartClicks?.reduce((sum: any, oneKey: any) => sum + oneKey.count, 0) || 0
    );
  }, [chartClicks]);
  return (
    <>
      {loadingPostClicksAnalytics ? (
        <div className="spin">
          <Spin />
        </div>
      ) : (
        <>
          <div className="total-count">
            <strong>Clicks : {totalSum}</strong>
          </div>
          <Row className="analytics-table">
            {chartClicks &&
              chartClicks
                ?.slice(startIndex, endIndex)
                ?.map((oneKey: any, index: any) => (
                  <div key={index} className="analyticsTable">
                    <div>
                      <div className="sup-analytics-grid-item">
                        <div className="analytics-chartClicks-thumbnail-div">
                          <img
                            className="analytics-chartClicks-thumbnail"
                            src={oneKey.media_url?.split(",")[0]}
                            alt="feed"
                          />
                        </div>
                        <div>{oneKey.count}</div>
                      </div>
                    </div>
                    {oneKey.name && <div>{oneKey.name}</div>}
                  </div>
                ))}
          </Row>
          {chartClicks && chartClicks.length > pageSize && (
            <div className="mt-4">

              <Pagination
                align="center"
                current={currentPage}
                total={chartClicks?.length}
                pageSize={pageSize}
                onChange={handlePageChange}
                showSizeChanger={false}
                showQuickJumper
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ChartClicksTable;
