import { useState } from "react";
import { useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";

/**
 *  Use this hook to control feed pagination
 * @param userId
 * @param index - "active" || "archived"
 * @param postsPerPage
 * @returns
 */
export function useLoadProductBankPages(
  userIds: any,

  postsPerPage: number,
  filter: any,
  usernames: any
) {
  async function getCount() {
    let feedCount;
    let postIds;
   
    const input = filter
      ? filter.split(" ").map((elem: any) => `%${elem}%`)
      : [];
    const { data, error } = await supabase.rpc("get_productbankcounttt", {
      query_string: input,
      userid: userIds,
      query_tagg: input,
    });
    feedCount = data?.[0]?.count;
    return {
      totalPages: Math.ceil(feedCount / postsPerPage),
      feedCount: feedCount,
      postIds: postIds || null,
    };
  }

  return useQuery(["totalProductBankCount", userIds, filter], getCount, {
    // enabled: Boolean(userIds),
  });
}
