import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../../config/supabaseClient";

// Hook for updating post status: archived or active
export default function useUpdatePostStatus() {
  const queryClient = useQueryClient();

  const updateIndexValue = async ({
    newPostStatus,
    postId,
    username,
  }: {
    newPostStatus: "archived" | "active" | "active,ad" | "active,featured";
    postId: string;
    activeKey: string;
    pageIndex: number;
    postIndex: number;
    filter: string | null;
    username: any;
  }) => {
    // const { error }:any = await supabase
    // 	.from("content_new")
    // 	.update({ index: newPostStatus })
    // 	.eq("post_id", postId);

    const { error: creatorError } = await supabase
      .from(`${username}`)
      .update({ index: newPostStatus })
      .eq("post_id", postId);

    if (newPostStatus === "active,ad") {
      // Select the post with the given post_id
      const { data: adPost }: any = await supabase
        .from(`${username}`)
        .select("*")
        .eq("post_id", postId)
        .limit(1);

      // Insert the post into ads_content table
      if (adPost && adPost.length) {
        delete adPost[0].fts;
        delete adPost[0].id;
        delete adPost[0]?.combined_text;
      
        adPost[0]["username"] = username;

        const { error: adInsertError } = await supabase
          .from("ads_content")
          .insert([adPost[0]]);

        if (adInsertError) {
        }
      }
    }

    if (creatorError) {
      throw new Error(creatorError?.message);
    } else {
      return true;
    }
  };

  // Tag optimistic update
  // return useMutation(updateIndexValue, {
  //   // When mutate is called:
  //   onMutate: async ({
  //     newPostStatus,
  //     pageIndex,
  //     postIndex,
  //     activeKey,
  //     filter,
  //   }) => {
  //     await queryClient.cancelQueries(["paginatedPosts", activeKey, filter]);
  //     const previousData = queryClient.getQueryData([
  //       "paginatedPosts",
  //       activeKey,
  //       filter,
  //     ]);
  //     if (previousData) {
  //       // Deep clone the object, otherwise the objects inside will have the same reference in memory
  //       const newData = JSON.parse(JSON.stringify(previousData)) as {
  //         pageParams: any;
  //         pages: any[];
  //       };

  //       if (
  //         newPostStatus === "active,ad" ||
  //         newPostStatus === "active,featured"
  //       ) {
  //         newData.pages[pageIndex][postIndex].index = newPostStatus;
  //       } else {
  //         newData.pages[pageIndex].splice(postIndex, 1);
  //       }

  //       queryClient.setQueryData(
  //         ["paginatedPosts", activeKey, filter],
  //         newData
  //       );
  //     }
  //     return { previousData };
  //   },
  //   // If the mutation fails, use the context returned from onMutate to roll back
  //   onError: (_error, { activeKey, filter }, context) => {
  //     queryClient.setQueryData(
  //       ["paginatedPosts", activeKey, filter],
  //       context?.previousData
  //     );
  //   },
  //   // Always refetch after error or success:
  //   onSettled: async (_data, _error, { activeKey, newPostStatus, filter }) => {
  //     queryClient.invalidateQueries(["paginatedPosts", activeKey, filter], {
  //       refetchPage: () => {
  //         return true;
  //       },
  //     });
  //     queryClient.invalidateQueries(["paginatedPosts", newPostStatus, filter], {
  //       refetchPage: () => {
  //         return true;
  //       },
  //     });
  //   },
  // });


  return useMutation(updateIndexValue, {
    onMutate: async ({
      newPostStatus,
      postId,
     
    }) => {
      await queryClient.cancelQueries("paginatedPosts");

      // Optimistically update the local data
      const previousData = queryClient.getQueryData(["paginatedPosts"]);

      queryClient.setQueryData(["paginatedPosts"], (oldData: any) => {
        if (oldData) {
          // Find and update the specific post with new permalink
          const newData = oldData.map((page: any) =>
            page.map((post: any) =>
              post.post_id === postId ? { ...post, index:newPostStatus } : post
            )
          );
          return newData;
        }
        return oldData;
      });

      return { previousData }; // Return context for rollback
    },
    onError: (error, variables, context) => {
      // Rollback on error by restoring the previous data
      queryClient.setQueryData(["paginatedPosts"], context?.previousData);
    },
    onSettled: () => {
      // Invalidate and refetch the query to update the data
      queryClient.invalidateQueries("paginatedPosts");
    },
  });
}
