import { Button, Carousel } from "antd";
import React from "react";
import { Card } from "react-bootstrap";
import { BsHourglassSplit } from "react-icons/bs";
import ImagePostTag from "./ImagePostTag";
import VideoPostTag from "./VideoPostTag";

const ImagePostCarouselTag = (props: any) => {
  const {
    post,
    redirectVariable,
    isLinks,
    handleRedirect,
    title,
    brand_color,
    isDashboard,
    height,
  } = props;
  const mediaUrls = post.media_url
    .split(",")
    .filter((url: any) => url.trim() !== "");
  const onChange = (currentSlide: number) => {};

  const ImagePostTagCarousel = React.memo((props: any) => {
    const { media_url, isVideo, post, handleRedirect } = props;

    return (
      <>
        <a
          href={
            post?.shop_link && post?.shop_link !== ""
              ? post?.shop_link
              : post?.permalink? post?.permalink :post?.table_url
          }
          target="_blank"
          onClick={(e) => {
            e.preventDefault();
            handleRedirect(
              post?.post_id,
              post?.media_url.includes("video")
                ? post?.product_image
                  ? post?.product_image
                  : post?.thumbnail
                : post?.product_image
                ? post?.product_image
                : post?.media_url,
              title,
              post?.product_name
            );
            if (
              (post?.shop_link && post?.shop_link !== "") ||
              (post?.permalink && post?.permalink !== "") ||   (post?.table_url && post?.table_url !== "")
            ) {
              window.open(
                post?.shop_link && post?.shop_link !== ""
                  ? post?.shop_link
                  : post.permalink? post?.permalink :post?.table_url,
                "_blank"
              );
            }
          }}
        >
          {media_url === "" ? (
            <div className={"creator-feed-card-video not-available"}>
              <BsHourglassSplit
                className="hourglassSplit"
              />
              <p>
                Work in progress: This content will be available in a few
                minutes.
              </p>
            </div>
          ) : (
            <>
              <Card.Img
                variant="top"
                src={media_url}
                className={"creator-feed-card-links-image"}
                loading="eager"
                style={{
                  backgroundColor: brand_color,
                  marginBottom: "2%",
                  height: height,
                }}
              />

              {post?.product_name && (
                <p
                  style={{
                    color: "black",
                    alignContent: "center",
                    justifyContent: "center",
                    textDecoration: "none",
                    marginTop: "5px",
                    marginBottom: "5px",
                    fontWeight: "500",
                    marginLeft: ".3rem",
                  }}
                >
                  {post?.product_name}
                </p>
              )}
              {isLinks && (
                <Button className={"template-shop-btn-links"}>Shop</Button>
              )}
            </>
          )}
        </a>
      </>
    );
  });
  return (
    <>
      {" "}
      <span>
        <div className={"creator-feed-image-wrapper-links"}>
          {mediaUrls.some((media_url: string) =>
            media_url.includes("video")
          ) ? (
            <Carousel afterChange={onChange} dotPosition="bottom">
              {mediaUrls.map((media_url: string, index: number) => (
                <div key={index}>
                  {media_url.includes("video") ? (
                    <div className={"creator-feed-image-wrapper-links"}>
                      <VideoPostTag
                        media_url={media_url}
                        post={post}
                        thumbnail={
                          post.thumbnail &&
                          post.thumbnail.split(",").length &&
                          post.thumbnail.split(",")[index]
                            ? post.thumbnail.split(",")[index]
                            : "/assets/dummy_video.png"
                        }
                        handleRedirect={handleRedirect}
                      />
                    </div>
                  ) : (
                    <div className={"creator-feed-image-wrapper-links"}>
                      <ImagePostTag
                        data={post}
                        title={title}
                        media_url={media_url}
                        isVideo={true}
                        handleRedirect={handleRedirect}
                      />
                    </div>
                  )}
                </div>
              ))}
            </Carousel>
          ) : (
            <Carousel afterChange={onChange} dotPosition="bottom">
              {mediaUrls.map((media_url: any, index: any) => (
                <div key={index} className={"creator-feed-image-wrapper-links"}>
                  <ImagePostTagCarousel
                    post={post}
                    media_url={media_url}
                    handleRedirect={handleRedirect}
                  />
                </div>
              ))}
            </Carousel>
          )}
        </div>
      </span>
    </>
  );
};

export default ImagePostCarouselTag;
