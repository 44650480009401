// import dayjs from "dayjs";
// import { useQuery } from "react-query";
// import { supabase } from "../../../config/supabaseClient";

// export function useLoadYesterdayLinks(
//   username: any,
//   user_id: any,
//   daysNumber: any,
//   postIds: any,
//   removedPostIds: any
// ) {
//   async function getPaginatedFeed() {
//     let data,
//       error = null;
//     const today = dayjs().format("YYYY-MM-DD");
//     if(removedPostIds && removedPostIds!==""){

//     removedPostIds = Array.from(
//       new Set(removedPostIds?.split(",").filter((elem: any) => elem !== ""))
//     );
//     }

//     let allData = []; // Initialize an array to store all fetched data
//     for (let num of daysNumber) {
//       if (num === 7) {
//         const numDayStart = dayjs()
//           .subtract(num, "day")
//           .startOf("day")
//           .format("YYYY-MM-DD HH:mm:ss");
//         // Get the end of the day
//         const numDayEnd = dayjs().format("YYYY-MM-DD HH:mm:ss");
//         const { data: rpc, error: rpcError } = await supabase.rpc(
//           "get_yesterdaycontentshopppo",
//           {
//             userid: user_id,
//             user_name: username,
//             // start_date: numDay,
//             // end_date: numDay+1,
//             start_date: numDayStart,
//             end_date: numDayEnd,
//             post_ids: [],
//             order_type: "false",
//           }
//         );
//         if (rpc) {
//           allData.push(...rpc); // Push the fetched data into the array
//         }

//         if (rpcError) {
//           error = rpcError;
//           // Handle errors if needed
//         }
//       } else {
//         const numDayStart = dayjs()
//           .subtract(num, "day")
//           .startOf("day")
//           .format("YYYY-MM-DD HH:mm:ss");
//         // Get the end of the day
//         const numDayEnd = dayjs()
//           .subtract(num, "day")
//           .endOf("day")
//           .format("YYYY-MM-DD HH:mm:ss");

//         const { data: rpc, error: rpcError } = await supabase.rpc(
//           "get_yesterdaycontentshopppo",
//           {
//             userid: user_id,
//             user_name: username,
//             // start_date: numDay,
//             // end_date: numDay+1,
//             start_date: numDayStart,
//             end_date: numDayEnd,
//             post_ids: [],
//             order_type: "true",
//           }
//         );
//         if (rpc) {
//           allData.push(...rpc); // Push the fetched data into the array
//         }

//         if (rpcError) {
//           error = rpcError;
//           // Handle errors if needed
//         }
//       }
//     }
//     // if()
//     const filteredArray = allData?.filter(
//       (obj) => !removedPostIds.includes(obj.post_id)
//     );

//     data = filteredArray;

//     // Get yesterday's date
//     if (data && data.length >= 0 && Array.isArray(data)) {
//       return data;
//     } else if (error) {
//       throw new Error(error.message);
//     } else {
//       throw new Error("Failed to fetch posts");
//     }
//     // return resultArray
//   }

//   return useQuery(
//     ["YesterdayPosts", user_id, daysNumber, removedPostIds],
//     getPaginatedFeed,
//     { enabled: Boolean(removedPostIds?.length >= 0) }
//   );
// }


import dayjs from "dayjs";
import { useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import { useState } from "react";

export function useLoadYesterdayLinks(
  username:any,
  user_id:any,
  daysNumber:any,
  postIds:any,
  removedPostIds:any
) {
  const [cachedData, setCachedData] = useState([]);

  async function getPaginatedFeed() {
    let data,
      error = null;
    const today = dayjs().format("YYYY-MM-DD");
    if (removedPostIds && removedPostIds !== "") {
      removedPostIds = Array.from(
        new Set(removedPostIds?.split(",")?.filter((elem:any) => elem !== ""))
      );
    }

    let allData = []; // Initialize an array to store all fetched data
    for (let num of daysNumber) {
      const numDayStart = dayjs()
        .subtract(num, "day")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      const numDayEnd = num === 7
        ? dayjs().format("YYYY-MM-DD HH:mm:ss")
        : dayjs()
            .subtract(num, "day")
            .endOf("day")
            .format("YYYY-MM-DD HH:mm:ss");

      const { data: rpc, error: rpcError } = await supabase.rpc(
        "get_yesterdaycontentshopppo",
        {
          userid: user_id,
          user_name: username,
          start_date: numDayStart,
          end_date: numDayEnd,
          post_ids: [],
          order_type: num === 7 ? "false" : "true",
        }
      );

      if (rpc) {
        allData.push(...rpc); // Push the fetched data into the array
      }

      if (rpcError) {
        error = rpcError;
        // Handle errors if needed
      }
    }

    const filteredArray = allData?.filter(
      (obj) => !removedPostIds.includes(obj.post_id)
    );

    data = filteredArray;

    // Get yesterday's date
    if (data && data.length >= 0 && Array.isArray(data)) {
      return data;
    } else if (error) {
      throw new Error(error.message);
    } else {
      throw new Error("Failed to fetch posts");
    }
  }

  const queryResult = useQuery(
    ["YesterdayPosts", user_id, daysNumber, removedPostIds],
    getPaginatedFeed,
    {
      enabled: Boolean(removedPostIds?.length >= 0),
      // staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: (data:any) => {
        setCachedData(data);
      },
      onError: () => {
        // Keep showing the cached data on error
      },
      initialData: () => cachedData,
    }
  );

  return queryResult;
}
