import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLoadYesterdayLinks } from "../../../../Utils/customHooks/UpToDateContent/useLoadYesterdayLinks";
import CustomPagination from "../../CustomPagination";
import "./YesterdayLinks.css";
import { BsHourglassSplit } from "react-icons/bs";
import Card from "react-bootstrap/esm/Card";
import { Button } from "antd";
import DataSkeleton from "../DataSkeleton";
import ImagePostCarouselTag from "../../../GeneralPostTag/ImagePostCarouselTag";
import VideoPostTag from "../../../GeneralPostTag/VideoPostTag";
import ImagePostTag from "../../../GeneralPostTag/ImagePostTag";
import { debounce } from "lodash";

const YesterdayLinks = (props: any) => {
  const itemsPerPage = 3;

  const {
    user_id,
    username,
    numberOfDays,
    title,
    isLinks,
    handleShop,
    postIds,
    brand_color,
    removedPostIds
  } = props;
  const { isLoading: yesterdayLinksLoading, data: yesterdayLinksData }: any =
    useLoadYesterdayLinks(username, user_id, numberOfDays, postIds,removedPostIds);
  // const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<any>(null);
  const isButtonClicked = useRef(false);

  // const handleScroll = () => {
  //   if (!isButtonClicked.current) {
  //     if (containerRef.current) {
  //       const scrollLeft = containerRef.current.scrollLeft;
  //       const itemWidth =
  //         containerRef.current.scrollWidth / yesterdayLinksData.length;
  //       const newCurrentIndex = Math.round(scrollLeft / itemWidth);
  //       setCurrentIndex(newCurrentIndex);
  //     }
  //   }
  // };
  const handleScroll = useCallback(
    debounce(() => {
      if (!isButtonClicked.current) {
        if (containerRef.current) {
          const scrollLeft = containerRef.current.scrollLeft;
          const itemWidth = containerRef.current.scrollWidth / yesterdayLinksData.length;
          const newCurrentIndex = Math.round(scrollLeft / itemWidth);
          setCurrentIndex(newCurrentIndex);
        }
      }
    }, 100),
    [yesterdayLinksData]
  );
  useEffect(() => {
    if (containerRef.current) {
      const itemWidth =
        containerRef.current.scrollWidth / yesterdayLinksData.length;
      const scrollPosition = itemWidth * currentIndex;
      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex, yesterdayLinksData]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [yesterdayLinksData,handleScroll]);

  const handlePrevPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNextPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, yesterdayLinksData.length - itemsPerPage)
    );
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };
 return (
    <>
      {yesterdayLinksLoading ? (
        <DataSkeleton />
      ) : (
        <>
          {yesterdayLinksData && yesterdayLinksData.length ? (
            <div>
              <div
                className="mainDiv"
              >
        <p
          className="component-name"
        >
          {title}
        </p>
                <CustomPagination
                  totalItems={yesterdayLinksData?.length}
                  currentIndex={currentIndex}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
                />
              </div>
              <div
                className="outer-container-reels"
                ref={containerRef}
              >
                <div
                  className="links-grid-container-reels"
                >
                  {yesterdayLinksData &&
                    yesterdayLinksData.map((link: any, index: number) => (
                      <div
                        key={link.id}
                        className="link-url"
                      >
                        {link.media_url ? (
                          link.media_url.split(",").length > 1 &&
                          link.media_url
                            .split(",")
                            .every((url: any) => url.trim() !== "") ? (
                            <>
                              <ImagePostCarouselTag
                                post={link}
                                handleRedirect={handleShop}
                                brand_color={brand_color}
                                isLinks={true}
                                isDashboard={false}
                              />
                            </>
                          ) : (
                            <>
                              {/* {link.media_url.includes("video") ? (
                                <>
                                  <VideoPostTag
                                    post={link}
                                    handleRedirect={handleShop}
                                    brand_color={brand_color}
                                    isLinks={true}
                                    isDashboard={false}
                                  />
                                </>
                              ) : (
                                <>
                                  <ImagePostTag
                                    data={link}
                                    handleRedirect={handleShop}
                                    brand_color={brand_color}
                                    isLinks={true}
                                    isDashboard={false}
                                  />
                                </>
                              )} */}
                               {link.product_image &&
                                link.product_image !== "" ? (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={handleShop}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={true}
                                      isDashboard={false}
                                    />
                                  </>
                                ) : (
                                  <>
                                    {link.media_url.includes("video") ? (
                                      <>
                                        <VideoPostTag
                                          post={link}
                                          handleRedirect={handleShop}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={true}
                                          isDashboard={false}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <ImagePostTag
                                          data={link}
                                          handleRedirect={handleShop}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={true}
                                          isDashboard={false}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                            </>
                          )
                        ) : (
                          <>
                            <ImagePostTag
                              data={link}
                              handleRedirect={handleShop}
                              brand_color={brand_color}
                              isLinks={true}
                              isDashboard={false}
                            />
                          </>
                        )}{" "}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default YesterdayLinks;
