import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomPagination from "../../CustomPagination";
import LinksTemplate from "../../LinksTemplate";
import Spinner from "react-bootstrap/esm/Spinner";
import PuffLoader from "react-spinners/PuffLoader";
import "./CustomContentData.css";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import { useLoadCustomContent } from "../../../../Utils/customHooks/UpToDateContent/useLoadCustomContent";
import { Card } from "react-bootstrap";
import { BsHourglassSplit } from "react-icons/bs";
import DataSkeleton from "../DataSkeleton";
import ImagePostTag from "../../../GeneralPostTag/ImagePostTag";
import ImagePostCarouselTag from "../../../GeneralPostTag/ImagePostCarouselTag";
import VideoPostTag from "../../../GeneralPostTag/VideoPostTag";
import { debounce } from "lodash";
import { isMobile } from "react-device-detect";
const CustomContentData = (props: any) => {
  const itemsPerPage = isMobile ? 1.5 : 3;

  const { user_id, username, postIds, title, brand_color } = props;
  const { isLoading: CustomContentDataLoading, data: CustomContentData }: any =
    useLoadCustomContent(username, user_id, postIds, title);
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<any>(null);
  const isButtonClicked = useRef(false);

  const handleScroll = useCallback(
    debounce(() => {
      if (!isButtonClicked.current) {
        if (containerRef.current) {
          const scrollLeft = containerRef.current.scrollLeft;
          const itemWidth =
            containerRef.current.scrollWidth / CustomContentData.length;
          const newCurrentIndex = Math.round(scrollLeft / itemWidth);
          setCurrentIndex(newCurrentIndex);
        }
      }
    }, 100),
    [CustomContentData]
  );

  useEffect(() => {
    if (containerRef.current) {
      const itemWidth =
        containerRef.current.scrollWidth / CustomContentData.length;
      const scrollPosition = itemWidth * currentIndex;
      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex, CustomContentData]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [CustomContentData, handleScroll]);

  const handlePrevPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNextPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, CustomContentData.length - itemsPerPage)
    );
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  return (
    <div>
      {CustomContentDataLoading ? (
        <DataSkeleton />
      ) : (
        <>
          {CustomContentData && CustomContentData.length ? (
            <div>
              <div className="mainDiv">
                <p className="component-name">{title}</p>
                <CustomPagination
                  totalItems={CustomContentData?.length}
                  currentIndex={currentIndex}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
                  
                />
              </div>
              <div className="outer-container-reels" ref={containerRef}>
                <div className="links-grid-container-reels">
                  {CustomContentData &&
                    CustomContentData.map((link: any, index: number) => (
                      <div key={link.id} className="link-url">
                        <>
                          {link.media_url ? (
                            link.media_url.split(",").length > 1 &&
                            link.media_url
                              .split(",")
                              .every((url: any) => url.trim() !== "") ? (
                              <>
                                <ImagePostCarouselTag
                                  post={link}
                                  handleRedirect={props.handleRedirect}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={false}
                                  isDashboard={false}
                                />
                              </>
                            ) : (
                              <>
                                {link.product_image &&
                                link.product_image !== "" ? (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={props.handleRedirect}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={false}
                                      isDashboard={false}
                                    />
                                  </>
                                ) : (
                                  <>
                                    {link.media_url.includes("video") ? (
                                      <>
                                        <VideoPostTag
                                          post={link}
                                          handleRedirect={props.handleRedirect}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={false}
                                          isDashboard={false}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <ImagePostTag
                                          data={link}
                                          handleRedirect={props.handleRedirect}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={false}
                                          isDashboard={false}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                                {/* {link.media_url.includes("video") ? (
                                  <>
                                    <VideoPostTag
                                      post={link}
                                      handleRedirect={props.handleRedirect}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={false}
                                      isDashboard={false}

                                    />
                                  </>
                                ) : (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={props.handleRedirect}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={false}
                                      isDashboard={false}

                                    />
                                  </>
                                )} */}
                              </>
                            )
                          ) : (
                            <>
                              <ImagePostTag
                                data={link}
                                handleRedirect={props.handleRedirect}
                                title={title}
                                brand_color={brand_color}
                                isLinks={false}
                                isDashboard={false}
                              />
                            </>
                          )}
                        </>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}{" "}
        </>
      )}
    </div>
  );
};

export default CustomContentData;
