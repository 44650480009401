import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import Header from "../../components/Header/header";
import CreatorProfile from "../../components/CreatorProfile/creatorProfile";
import ImageSidebar from "../../components/Header/ImageSidebar";
import { TabTitle } from "../../Utils/GeneralFunctions";
import CopyToClipboard from "../../components/CopyToClipboard/CopyToClipboard";
import track, { TRACKING_TYPES } from "../../Utils/SupabaseServices/Track";
import NoUser from "../../ErrorPages/NoUser";
import { useAuth } from "../../hooks/useAuth";
import FeedControls from "../../components/FeedPage/FeedControls/FeedControls";
import ProfileSkeleton from "../../components/skeleton/Views/ProfileSkeleton";
import { useLoadCompletePage } from "../../Utils/customHooks/useLoadCompletePage";

const CreatorView = () => {
  TabTitle("Creator View • Bubble");
  const page = "creator";
  const location = useLocation();
  const { user } = useAuth();
  const user_id = user?.id || "";
  //TODOOOO -> what this code is doing?
  const [feed, setFeed]: any | undefined = useState([]);
  const { username } = useParams();

  const [refetchCount, setRefetchCount] = useState(0);
  const [refetchingPendingContent, setRefetchingPendingContent] =
    useState(false);
  const [isOnboardingFinished, setIsOnboardingFinished] = useState(true);
  const queryClient = useQueryClient();

  useEffect(() => {
    let pendingContent = feed.some((item: any) => !Boolean(item.storage_url));

    if (
      pendingContent &&
      refetchCount <= 6 &&
      !refetchingPendingContent &&
      !isOnboardingFinished
    ) {
      setRefetchingPendingContent(true);
      setTimeout(async () => {
       
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setRefetchCount(refetchCount + 1);

        setRefetchingPendingContent(false);
      }, 10000);
    }
  }, [feed]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    track(location.pathname, TRACKING_TYPES.PAGE, {
      pathname: location.pathname,
      title: "Creator",
      user_id: user_id,
    });
  }, []);
  const { isLoading, data, error }: any = useLoadCompletePage(username, page);

  return (
    <>
      {isLoading ? (
        <ProfileSkeleton isCreator={true} />
      ) : data?.combinedRes?.userData?.name && data?.combinedRes?.userData?.user_id && username ? (
        <>
          <div id="app-wrapper">
            <Header
              extraComponent={
                <div
                  style={{
                    display: "flex",
                    gap: "25px",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "black",
                  }}
                >
                  <CopyToClipboard
                    textToCopy={`https://www.searchbubble.com/${username}`}
                  />
                  <ImageSidebar
                    username={username as string}
                    isCreator={true}
                    pictureUrl={data?.combinedRes?.userData?.picture}
                  />
                </div>
              }
            />
          </div>
          <FeedControls
            userDetails={data?.combinedRes?.userData}
            username={username}
            page="creator"
            name={data?.combinedRes?.userData?.name}
            userId={data?.combinedRes?.userData?.user_id}
            data={data?.combinedRes}
            hashtagFilters={data?.hashtagFilterArray}

            avgWords={data?.combinedRes?.userData?.avg_words}
            liveView={false}
          />
        </>
      ) : (
        <NoUser />
      )}
    </>
  );
};

export default CreatorView;
